import React from "react";

interface NoDataProps {
  len: number;
  msg?: string;
}

const NoData = ({ len, msg }: NoDataProps) => {
  return (
    <tr data-testid="no-data">
      <td colSpan={len}>{msg || "No data available in table"}</td>
    </tr>
  );
};

export { NoData };
