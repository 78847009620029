import { createSlice } from "@reduxjs/toolkit";
import { userAuth, userPermissions } from "apis/restApis";
import {
  getItemWithExpiry,
  secondsToMidnight,
  setItemWithExpiry,
  Toast,
} from "utils";
import { STATUSES } from "utils/constants";
import { AuthPermissions, IGetOrder, LoggedInUser } from "utils/types";
import {
  ResetWholeCart,
  resetCart,
  resetCreditNoteState,
  resetHoldBillState,
  resetOrderState,
  resetPOSPaymentModeState,
  resetPOSPaymentTypeState,
  setAdvance,
  setCartDiscount,
  setCreditNote,
  setReturn,
  setReturnOrder,
  setRoundOff,
} from "./POS";
import { resetBankState } from "./ManageBanks/bankSlice";
import {
  clearSelectedStore,
  resetBranchState,
} from "./ManageBranches/branchSlice";
import { resetBrandState } from "./ManageProducts/productBrandSlice";
import { resetCategoryState } from "./ManageProducts/productCategorySlice";
import { resetCustomerState } from "./ManageCustomers/customerSlice";
import { resetDiscountState } from "./ManageProducts/ManageDiscounts/discountSlice";
import { resetEmployeeState } from "./ManageEmployee/employeeSlice";
import { resetHSNState } from "./hsnCodesSlice";
import { resetIngredientState } from "./ManageProducts/productIngredients";
import { resetPaymentModeState } from "./ManagePayments/paymentModeSlice";
import { resetPaymentTermState } from "./ManagePayments/paymentTermSlice";
import { resetProductGroupState } from "./ManageProducts/productGroupSlice";
import { resetProductState } from "./ManageProducts/productSlice";
import { resetRoleState } from "./ManageRoles/roleSlice";
import { resetSubCategoryState } from "./ManageProducts/productSubCategorySlice";
import { resetTaxState } from "./ManageProducts/productTaxSlice";
import { resetUOMState } from "./uomSlice";

const initialState = {
  isLoggedIn: localStorage.getItem("accessToken") ? true : false,
  logged_in_user: {} as LoggedInUser,
  permissions: [] as AuthPermissions[],
  status: STATUSES.IDLE as string,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    setLoggedInUser: (state, action) => {
      state.logged_in_user = action.payload;
    },

    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    deletePermissions: (state) => {
      state.permissions = [];
    },

    setStatus(state, action) {
      state.status = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  setLoggedIn,
  setPermissions,
  deletePermissions,

  setLoggedInUser,

  setStatus,
  setError,
} = authSlice.actions;

export default authSlice.reducer;

export function login(authParams) {
  return async function loginThunk(dispatch, getState) {
    dispatch(setStatus(STATUSES.LOADING));
    userAuth(authParams)
      .then((response: any) => {
        dispatch(setStatus(STATUSES.IDLE));
        dispatch(setLoggedIn(true));
        dispatch(setLoggedInUser(response.result.user_details));

        getItemWithExpiry("cashierLogin");
        if (localStorage.getItem("cashierLogin") === null) {
          setItemWithExpiry(
            "cashierLogin",
            true,
            secondsToMidnight(new Date()) * 1000
          );
        }

        localStorage.setItem("accessToken", response.result.access_token);
        localStorage.setItem("refreshToken", response.result.refresh_token);

        const filteredPermissions =
          response.result &&
          response.result.permissions &&
          response.result.permissions.length > 0 &&
          response.result.permissions.map((permission) => ({
            isSubModule: permission.module === null,
            module_name:
              permission.module !== null
                ? permission.module.module_name
                : permission.sub_module.sub_module_name,
            perm_view: permission.perm_view && permission.perm_view === 1,
            perm_add: permission.perm_add && permission.perm_add === 1,
            perm_edit: permission.perm_edit && permission.perm_edit === 1,
            perm_delete: permission.perm_delete && permission.perm_delete === 1,
            perm_authorize:
              permission.perm_authorize && permission.perm_authorize === 1,
          }));

        dispatch(setPermissions(filteredPermissions));
      })
      .catch((error) => {
        dispatch(setStatus(STATUSES.ERROR));
        dispatch(setError(error));
      });
  };
}

export function logout() {
  return async function logoutThunk(dispatch, getState) {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      // localStorage.removeItem("accessToken");
      // localStorage.removeItem("refreshToken");
      const cashierLogin = localStorage.getItem("cashierLogin");
      localStorage.clear();
      cashierLogin && localStorage.setItem("cashierLogin", cashierLogin);
      dispatch(deletePermissions());
      dispatch(setLoggedIn(false));
    } catch (error) {
      dispatch(setStatus(STATUSES.ERROR));
      dispatch(setError(error));
    } finally {
      dispatch(setStatus(STATUSES.IDLE));
      dispatch(clearEntireState());
      // window.location.reload();
    }
  };
}

export function clearEntireState() {
  return async function clearEntireStateThunk(dispatch, getState) {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      const resetActions = [
        resetBankState(),
        resetBranchState(),
        resetBrandState(),
        resetCategoryState(),
        resetCreditNoteState(),
        resetCustomerState(),
        resetDiscountState(),
        resetEmployeeState(),
        resetHSNState(),
        resetHoldBillState(),
        resetIngredientState(),
        resetOrderState(),
        resetPOSPaymentModeState(),
        resetPOSPaymentTypeState(),
        resetPaymentModeState(),
        resetPaymentTermState(),
        resetProductGroupState(),
        resetProductState(),
        resetRoleState(),
        resetSubCategoryState(),
        resetTaxState(),
        resetUOMState(),
        resetCart(),
        setCreditNote({
          creditNote: [{ refund_amount: 0, value: 0, label: "" }],
          applyCredit: 0,
        }),
        setRoundOff(0),
        setCartDiscount({
          discount_applicable: "bill",
          discount_type: "flat",
          discount_value: 0,
          discount_code: "OnSpot Discount",
        }),
        setReturnOrder({} as IGetOrder),
        setReturn(false),
        setAdvance(false),
        clearSelectedStore(),
      ];
      await Promise.all(resetActions.map((action) => dispatch(action)));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setStatus(STATUSES.IDLE));
    }
  };
}

export function getUserPermissions(permission?: AuthPermissions[]) {
  return async function getUserPermissionsThunk(dispatch, getState) {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      const response: any = await userPermissions();
      dispatch(setStatus(STATUSES.IDLE));
      dispatch(setLoggedInUser(response.result));

      const permissionsFromDB =
        permission?.length > 0
          ? permission
          : response &&
            response.result &&
            response.result.permissions &&
            response.result.permissions;

      const filteredPermissions: AuthPermissions[] = permissionsFromDB?.map(
        (permission) => ({
          id:
            permission.module === null
              ? permission.sub_module.id
              : permission.module.id,
          isSubModule: permission.module === null,
          module_name:
            permission.module !== null
              ? permission.module.module_name
              : permission.sub_module.sub_module_name,
          perm_view: permission.perm_view && permission.perm_view === 1,
          perm_add: permission.perm_add && permission.perm_add === 1,
          perm_edit: permission.perm_edit && permission.perm_edit === 1,
          perm_delete: permission.perm_delete && permission.perm_delete === 1,
          perm_authorize:
            permission.perm_authorize && permission.perm_authorize === 1,
        })
      );
      dispatch(setPermissions(filteredPermissions));
    } catch (error) {
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
}
