import { createSlice } from "@reduxjs/toolkit";
import {
  cancelOrder,
  addOrderPayment,
  filterOrders,
  getOrder,
  getSpecificOrder,
  lastOrderDetails,
  printLastBill,
  sendPayLaterReminder,
  setOrder,
  updateOrder,
  delOrderPayment,
  getOrderNotificationLogs,
} from "apis/restApis";
import {
  resetMasterPasswordVerifed,
  setCommonStatus,
  setControllerState,
  updatePageNo,
  updateValues,
} from "store/commonSlice";
import { AppDispatch, RootState } from "store/store";
import {
  GENERAL_CONSTANTS,
  IAddress,
  ICompletedOrder,
  IDiscount,
  IGetOrder,
  IOrder,
  IWhatsAppLogs,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  STATUSES,
  TOAST_CONSTANTS,
  Toast,
  calculateAllValues,
  printBill,
  to2Decimal,
  transformCustomer,
} from "utils";
import { ResetWholeCart } from "./cartSlice";
import { NavigateFunction } from "react-router-dom";
import moment from "moment";

const initialState = {
  orders: [] as IOrder[],
  orderLogs: [] as IWhatsAppLogs[],
  currentOrder: {} as IGetOrder,
  lastOrder: {} as IGetOrder,
  printOptions: [] as {
    type: "bill" | "credit";
    content: any;
  }[],
  status: STATUSES.IDLE as string,

  error: null,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    getAllOrders: (state, action) => {
      state.orders = action.payload;
    },
    addOrder: (state, action) => {
      state.orders.push(action.payload);
    },
    appendOrders: (state, action) => {
      state.orders = [...state.orders, ...action.payload];
    },
    removeOrder: (state, action) => {
      state.orders.splice(action.payload, 1);
    },
    editOrder: (state, action) => {
      state.orders[action.payload.index] = action.payload.order;
    },
    clearOrders: (state) => {
      state.orders = [] as IOrder[];
    },

    setCurrentOrder: (state, action) => {
      state.currentOrder = action.payload;
    },

    setLastOrder: (state, action) => {
      state.lastOrder = action.payload;
    },

    setPrintOptions: (state, action) => {
      state.printOptions = action.payload;
    },

    setOrderLogs: (state, action) => {
      state.orderLogs = action.payload;
    },

    setOrderStatus: (state, action) => {
      state.status = action.payload;
    },
    setOrderError: (state, action) => {
      state.error = action.payload;
    },
    resetOrderState: () => initialState,
  },
});

export const {
  getAllOrders,
  addOrder,
  appendOrders,
  removeOrder,
  editOrder,
  clearOrders,

  setCurrentOrder,
  setLastOrder,

  setPrintOptions,

  setOrderLogs,

  setOrderStatus,
  setOrderError,
  resetOrderState,
} = orderSlice.actions;

export default orderSlice.reducer;

export function FetchAllOrders(pageNo = 1, query = "") {
  return async function FetchAllOrdersThunk(dispatch: AppDispatch) {
    dispatch(setOrderStatus(STATUSES.LOADING));
    getOrder(pageNo, query)
      .then((response: any) => {
        dispatch(updateValues(response));
        dispatch(getAllOrders(response.result.results));
      })
      .catch((error: any) => {
        dispatch(setOrderError(error.message));
        dispatch(setOrderStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setOrderStatus(STATUSES.IDLE));
      });
  };
}

export function FetchOrdersOnScroll(
  pageNo: number,
  query = "",
  setPage: React.Dispatch<React.SetStateAction<number>>
) {
  return async function FetchOrdersOnScrollThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(setOrderStatus(STATUSES.LOADING));
    getOrder(pageNo, query)
      .then((response: any) => {
        dispatch(updateValues(response));
        dispatch(appendOrders(response.result.results));
      })
      .catch((error: any) => {
        dispatch(setOrderError(error.message));
        dispatch(setOrderStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setOrderStatus(STATUSES.IDLE));
      });
  };
}

interface IAddOrderProps {
  payLater?: {
    delivery_type: string;
    delivery_time: string;
    delivery_date: string;
    address: IAddress;
    payment_type: number;
    guaranteer_person: number;
    remarks: string;
  };
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
  advanceOrder?: {
    advance_amount: number;
    delivery_type: string;
    delivery_time: string;
    delivery_date: string;
    address: IAddress;
    payment_type: number;
    remarks: string;
  };
  focusRef?: React.MutableRefObject<any>;
  setPrintOptionsModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setDiscount?: React.Dispatch<React.SetStateAction<IDiscount>>;
  whatsApp?: boolean;
}

export function AddNewOrder({
  focusRef,
  payLater,
  setModal,
  advanceOrder,
  setPrintOptionsModal,
  whatsApp,
}: IAddOrderProps) {
  return async function AddNewOrderThunk(dispatch: AppDispatch, getState: any) {
    dispatch(setOrderStatus(STATUSES.LOADING));
    dispatch(
      setCommonStatus({
        state: STATUSES.LOADING,
        type: whatsApp
          ? POS_CART_CONSTANTS.WHATSAPP_BILL_LOADING_STATE
          : payLater
          ? POS_CART_CONSTANTS.PAY_LATER_LOADING_STATE
          : POS_CART_CONSTANTS.PRINT_BILL_LOADING_STATE,
      })
    );

    const {
      root: {
        branch: { selectedStore },
        cart: { cart, advance, cancel_order_id },
        common: { master_password_verified },
        customer: { customerToEdit: customer_details },
      },
    }: RootState = getState();

    const {
      total_tax,
      final_amount_to_pay,
      final_discount,
      bill_discount,
      total_payable,
      bill_total,
      discount_to_send,
    } = calculateAllValues(cart);

    const products = (cartItems) => {
      const nonZeroProducts = [];
      const nonZeroTotalQuantity = to2Decimal(
        cartItems.reduce((acc, item) => {
          if (item.productGroup.products_quantity > 0) {
            return acc + item.count / item.productGroup.products_quantity;
          } else {
            return acc + item.count;
          }
        }, 0)
      );
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].quantity !== 0) {
          nonZeroProducts.push({
            product_group_id:
              cartItems[i].productGroup && cartItems[i].productGroup.id > 0
                ? cartItems[i].productGroup.id
                : null,
            product_id: cartItems[i].id,
            batch_id: cartItems[i].batch_id,
            total_amount: to2Decimal(cartItems[i].total_amount),
            quantity: cartItems[i].quantity,
            payable_amount: to2Decimal(cartItems[i].payable_amount),
            discount_value: cartItems[i].discount.discount_value
              ? cartItems[i].discount.discount_value
              : 0,
            discount_code: cartItems[i].discount.discount_code
              ? cartItems[i].discount.discount_code
              : "",
            discount_type: cartItems[i].discount.discount_type
              ? cartItems[i].discount.discount_type
              : "",
            cess: cartItems[i].cess,
            cgst: cartItems[i].cgst,
            igst: cartItems[i].igst,
            sgst: cartItems[i].sgst,
            remarks: "",
            unit_price: to2Decimal(cartItems[i].mrp),
            total_discount: to2Decimal(
              cartItems[i].discount_applied * cartItems[i].quantity
            ),
            count: cartItems[i].count,
            total_tax: to2Decimal(cartItems[i].tax_applied),
          });
        }
      }

      return { nonZeroProducts, nonZeroTotalQuantity };
    };

    const { nonZeroProducts, nonZeroTotalQuantity } = products(cart.cartItems);

    const dataTosend = {
      store: Number(selectedStore),
      customer: customer_details.id,
      payments: [],
      bill_amount: to2Decimal(bill_total),
      payable_amount: to2Decimal(final_amount_to_pay + cart.round_off),
      discount_value: cart.discount.discount_value
        ? cart.discount.discount_value
        : 0,
      discount_code: cart.discount.discount_code
        ? cart.discount.discount_code
        : "",
      discount_type: cart.discount.discount_type
        ? cart.discount.discount_type
        : "",
      products: nonZeroProducts,
      roundoff: cart.round_off,
      total_quantity: to2Decimal(nonZeroTotalQuantity),
      // employee: Number(logged_in_user.id),
      status: "pending",
      advance_amount: advance ? advanceOrder.advance_amount : 0,
      delivery_type: payLater
        ? payLater.delivery_type
        : advance
        ? advanceOrder.delivery_type
        : "pickup",
      delivery_date: payLater
        ? payLater.delivery_date
        : advance
        ? advanceOrder.delivery_date
        : // : formatDate(),
          null,
      delivery_time: payLater
        ? payLater.delivery_time
        : advance
        ? advanceOrder.delivery_time
        : // : formatTime(new Date()),
          null,
      payment_type: payLater
        ? payLater.payment_type
        : advance
        ? advanceOrder.payment_type
        : 4, // 4 - Pay Now || 5 - Pay Later || 6- Advance Order
      guaranteer_person: payLater ? payLater.guaranteer_person : "",
      remarks: payLater
        ? payLater.remarks
        : advance
        ? advanceOrder.remarks
        : cart.remarks,
      addresses:
        payLater && payLater.address
          ? [payLater.address]
          : advance && advanceOrder.address
          ? [advanceOrder.address]
          : customer_details.address
          ? [transformCustomer(customer_details).customer_address]
          : [],
      total_discount: to2Decimal(discount_to_send),
      total_tax: to2Decimal(total_tax),
    };

    if (cart.credit_note.applyCredit > 0) {
      dataTosend.payments.push({
        payment_mode: POS_CONSTANTS.CREDIT_NOTE_ID,
        credit_note: cart.credit_note.creditNote[0].value,
        amount: Number(cart.credit_note.applyCredit),
        remarks: "Credit Note",
      });
      dataTosend.payable_amount = to2Decimal(
        final_amount_to_pay + cart.round_off - cart.credit_note.applyCredit
      );
      dataTosend.delivery_type = cart?.credit_note?.[0]?.delivery_type;
      dataTosend.delivery_date = cart?.credit_note?.[0]?.delivery_date;
    }

    setOrder(dataTosend)
      .then((response: any) => {
        setModal && setModal(false);
        focusRef && focusRef.current.focus();
        cancel_order_id !== 0 &&
          dispatch(
            CancelExistingOrder(cancel_order_id, master_password_verified)
          );
        dispatch(ResetWholeCart());
        dispatch(
          setCommonStatus({
            state: STATUSES.IDLE,
            type: whatsApp
              ? POS_CART_CONSTANTS.WHATSAPP_BILL_LOADING_STATE
              : payLater
              ? POS_CART_CONSTANTS.PAY_LATER_LOADING_STATE
              : POS_CART_CONSTANTS.PRINT_BILL_LOADING_STATE,
          })
        );
        Toast(`${response.message}`, "success");
        if (response.result.advance) {
          const printOptions = printBill(response, setPrintOptionsModal);
          if (printOptions.length > 0) {
            dispatch(setPrintOptions(printOptions));
          }
        } else if (!whatsApp) {
          printBill(response, setPrintOptionsModal);
        }
        dispatch(LastOrderDetails(selectedStore));
      })
      .catch((error: any) => {
        dispatch(setOrderStatus(STATUSES.ERROR));
        dispatch(setOrderError(error.message));
      })
      .finally(() => {
        dispatch(
          setCommonStatus({
            state: STATUSES.IDLE,
            type: GENERAL_CONSTANTS.EMPTY_STRING,
          })
        );
        dispatch(setOrderStatus(STATUSES.IDLE));
      });
  };
}

export function UpdateExistingOrder(order: any, navigate: NavigateFunction) {
  return async function UpdateExistingOrderThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(setOrderStatus(STATUSES.LOADING));

    updateOrder(order)
      .then((response: any) => {
        Toast(`${response.message}`, "success");
        navigate("/admin/sales/cashier");
        dispatch(setCurrentOrder({} as IGetOrder));
      })
      .catch((error: any) => {
        dispatch(setOrderStatus(STATUSES.ERROR));
        dispatch(setOrderStatus(STATUSES.IDLE));
        dispatch(setOrderError(error.message));
      })
      .finally(() => {
        dispatch(setOrderStatus(STATUSES.IDLE));
      });
  };
}

export function CancelExistingOrder(orderId: number, extra: boolean = false) {
  return async function CancelExistingOrderThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    const {
      order: { currentOrder },
    }: RootState["root"] = getState().root;

    dispatch(setOrderStatus(STATUSES.LOADING));
    cancelOrder(orderId, extra)
      .then((response: any) => {
        Toast(`${response.message}`, "success");
        // dispatch(LastOrderDetails(Number(selectedStore)));
        dispatch(setCurrentOrder({ ...currentOrder, status: "cancelled" }));
      })
      .catch((error: any) => {
        dispatch(setOrderStatus(STATUSES.ERROR));
        dispatch(setOrderError(error.message));
      })
      .finally(() => {
        dispatch(resetMasterPasswordVerifed());
        dispatch(setOrderStatus(STATUSES.IDLE));
      });
  };
}

interface PrintLastBillProps {
  bill_id?: number;
  setPrintOptionsModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function PrintLastBill({
  bill_id,
  setPrintOptionsModal,
}: PrintLastBillProps) {
  return async function PrintLastBillThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "printLastBill" })
    );
    const {
      branch: { selectedStore },
    } = getState().root;
    let bill;
    if (bill_id) {
      bill = printLastBill({
        bill: bill_id,
      });
    } else {
      bill = printLastBill({
        store: selectedStore,
      });
    }
    bill
      .then((response: any) => {
        if (bill_id > 0) {
          printBill(response);
        } else {
          const printOptions = printBill(response, setPrintOptionsModal);

          if (printOptions.length > 0) {
            dispatch(setPrintOptions(printOptions));
          }
        }

        Toast(`${response.message}`, "success");
      })
      .catch((error: any) => {
        dispatch(setOrderStatus(STATUSES.ERROR));
        dispatch(setOrderError(error.message));
        // dispatch(setOrderStatus(STATUSES.IDLE));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "" }));
      });
  };
}

export function LastOrderDetails(store: number) {
  return async function LastOrderDetailsThunk(dispatch: AppDispatch) {
    dispatch(setCommonStatus({ state: STATUSES.LOADING, type: "lastBill" }));
    lastOrderDetails(store)
      .then((response: any) => {
        if (response.result.length === 0) {
          dispatch(setLastOrder({} as IGetOrder));
        } else {
          dispatch(setLastOrder(response.result));
        }
      })
      .catch((error: any) => {
        dispatch(setOrderStatus(STATUSES.ERROR));
        dispatch(setOrderError(error.message));
      })
      .finally(() => {
        dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "lastBill" }));
      });
  };
}

interface AddOrderPaymentModeProps {
  order: ICompletedOrder;
  navigate?: NavigateFunction;
  navigateTo?: any;
  cashier_search?: boolean;
  edit?: boolean;
  fromEdit?: boolean;
}

export function AddOrderPaymentMode({
  order,
  navigate,
  navigateTo,
  cashier_search = false,
  edit = false,
  fromEdit = false,
}: AddOrderPaymentModeProps) {
  return async function AddOrderPaymentModeThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    const {
      root: {
        order: { orders },
        common: { navigate: commonNavigate },
        branch: { selectedStore },
      },
    }: RootState = getState();
    dispatch(setOrderStatus(STATUSES.LOADING));
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "completeOrder" })
    );
    addOrderPayment(order, edit)
      .then((response: any) => {
        dispatch(updatePageNo(1));
        !cashier_search && navigate && navigate(-1);
        Toast(`${response.message}`, "success");
        dispatch(setCurrentOrder({} as IGetOrder));
        if (fromEdit) {
          dispatch(FetchCurrentOrderDetails(Number(order.order_id)));
        } else if (cashier_search && orders.length !== 1) {
          const index = orders.findIndex((item) => item.id === order.order_id);
          dispatch(removeOrder(index));
        } else {
          const pending_pay_later_filters = JSON.parse(
            localStorage.getItem("pending_pay_later_filters")
          );

          dispatch(
            FilterOrder({
              pageNo: 1,
              query: "",
              status: "pending",
              type: cashier_search ? 5 : 4, //Pay Later if order completed from cashier search else Pay Now
              start_date:
                cashier_search && pending_pay_later_filters?.start_date
                  ? pending_pay_later_filters?.start_date
                  : moment().subtract(1, "days").format("YYYY-MM-DD"),
              end_date:
                cashier_search && pending_pay_later_filters?.end_date
                  ? pending_pay_later_filters?.end_date
                  : moment().format("YYYY-MM-DD"),
              store: selectedStore,
            })
          );
        }
      })
      .catch((error: any) => {
        dispatch(setOrderStatus(STATUSES.ERROR));
        dispatch(setOrderError(error.message));
        Toast(`${error.message}`, "error");
      })
      .finally(() => {
        !cashier_search && dispatch(setOrderStatus(STATUSES.IDLE));
        dispatch(
          setCommonStatus({ state: STATUSES.IDLE, type: "completeOrder" })
        );
      });
  };
}

export function DeleteOrderPaymentMode(id: number) {
  return async function DeleteOrderPaymentModeThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(setOrderStatus(STATUSES.LOADING));
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "deleteOrderPayment" })
    );
    delOrderPayment(id)
      .then((response: any) => {
        Toast(`${response.message}`, "success");
      })
      .catch((error: any) => {
        dispatch(setOrderStatus(STATUSES.ERROR));
        dispatch(setOrderError(error.message));
        Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setOrderStatus(STATUSES.IDLE));
        dispatch(
          setCommonStatus({ state: STATUSES.IDLE, type: "deleteOrderPayment" })
        );
      });
  };
}

export function FetchCurrentOrderDetails(
  orderId: number,
  selectedStore?: number,
  setCurrentCustomer?: React.Dispatch<React.SetStateAction<any>>
  // setOrderDetails?: React.Dispatch<React.SetStateAction<IGetOrder>>
) {
  return async function FetchCurrentOrderDetailsThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    // const {
    //   branch: { selectedStore },
    // }: RootState["root"] = getState().root;
    dispatch(setOrderStatus(STATUSES.LOADING));
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "orderDetails" })
    );
    getSpecificOrder(orderId, selectedStore)
      .then((response: any) => {
        dispatch(setCurrentOrder(response.result));
        setCurrentCustomer && setCurrentCustomer(response.result?.customer?.id);
        // Toast(`${response.message}`, "success");
        // setOrderDetails && setOrderDetails(response.result);
      })
      .catch((error: any) => {
        dispatch(setOrderStatus(STATUSES.ERROR));
        dispatch(setOrderError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(
          setCommonStatus({ state: STATUSES.IDLE, type: "orderDetails" })
        );
        dispatch(setOrderStatus(STATUSES.IDLE));
      });
  };
}

interface IFilterOrder {
  pageNo: number;
  query: string;
  store: number;
  code?: string;
  status?: string;
  order?: number;
  type?: number;
  setQuery?: React.Dispatch<
    React.SetStateAction<{
      query: string;
      type: "product" | "customer" | "order" | "advance";
    }>
  >;
  navigate?: NavigateFunction;
  cashier?: boolean;
  delivery_type?: string;
  start_date?: string;
  end_date?: string;
}

export function FilterOrder({
  pageNo = 1,
  query = "",
  store,
  code,
  status,
  order,
  type,
  setQuery,
  navigate,
  cashier = false,
  delivery_type,
  start_date,
  end_date,
}: IFilterOrder) {
  return async function FilterOrderThunk(dispatch: AppDispatch, getState: any) {
    const {
      common: { controller: abortController },
    }: RootState["root"] = getState().root;

    if (abortController) {
      abortController.abort();
    }

    const controller = new AbortController();
    dispatch(setControllerState(controller));

    dispatch(setOrderStatus(STATUSES.LOADING));
    dispatch(setCommonStatus({ state: STATUSES.LOADING, type: "fetchOrders" }));
    filterOrders(
      pageNo,
      query,
      store,
      code,
      status,
      order,
      type,
      delivery_type,
      start_date,
      end_date,
      controller
    )
      .then((response: any) => {
        dispatch(updateValues(response));
        if (Object.keys(response.result).length === 0) {
          Toast("No Orders Found", "error");
          setQuery && setQuery({ query: "", type: "order" });
          dispatch(setOrderStatus(STATUSES.IDLE));
          dispatch(
            setCommonStatus({ state: STATUSES.IDLE, type: "fetchOrders" })
          );
          return;
        }
        if (cashier) {
          if (response.result.status === "completed") {
            Toast("Order is already paid in full", "info");
          } else {
            dispatch(setCurrentOrder(response.result));
            navigate &&
              navigate(
                "/admin/sales/cashier/pending-orders/orders/" +
                  response.result.id +
                  "/"
              );
          }
        } else {
          if (response.result.results) {
            dispatch(updateValues(response));
            dispatch(getAllOrders(response.result.results));
          } else {
            dispatch(setCurrentOrder(response.result));
            navigate && navigate(`/admin/sales/orders/${response.result.id}`);
          }
        }
        dispatch(setOrderStatus(STATUSES.IDLE));
        dispatch(
          setCommonStatus({ state: STATUSES.IDLE, type: "fetchOrders" })
        );
      })
      .catch((error: any) => {
        if (error.message === "canceled") {
          dispatch(setOrderStatus(STATUSES.LOADING));
          return;
        }
        dispatch(setOrderError(error.message));
        dispatch(setOrderStatus(STATUSES.ERROR));
        // Toast(`${error.message}`, "error");
      });
    // .finally(() => {
    //   dispatch(setOrderStatus(STATUSES.IDLE));
    //   dispatch(
    //     setCommonStatus({ state: STATUSES.IDLE, type: "fetchOrders" })
    //   );
    // });
  };
}

export function fetchWhatsappLogs({
  id,
  type,
  onSuccess,
}: {
  id: number;
  type?:
    | "order_placed"
    | "order_cancelled"
    | "order_pay_later_reminder"
    | "advance_credit_placed"
    | "order_cash_return_placed";
  onSuccess?: () => void;
}) {
  return async function fetchWhatsappLogsThunk(dispatch: AppDispatch) {
    dispatch(
      setCommonStatus({
        state: STATUSES.LOADING,
        type: "whatsappLogs",
      })
    );
    getOrderNotificationLogs(id)
      .then((response: any) => {
        onSuccess && onSuccess();
        dispatch(
          setOrderLogs(
            type
              ? response.result.filter(
                  (item: IWhatsAppLogs) => item.action === type
                )
              : response.result
          )
        );
      })
      .catch((error) => {
        dispatch(setOrderError(error.message));
        // Toast(`${error.message}`, TOAST_CONSTANTS.ERROR);
      })
      .finally(() => {
        dispatch(
          setCommonStatus({
            state: STATUSES.IDLE,
            type: "whatsappLogs",
          })
        );
      });
  };
}

export function SendPayLaterWhatsAppReminder(order_id: number) {
  return async function SendPayLaterWhatsAppReminderThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    const {
      order: { orderLogs, orders },
    }: RootState["root"] = getState().root;

    dispatch(setOrderStatus(STATUSES.LOADING));
    dispatch(
      setCommonStatus({
        state: STATUSES.LOADING,
        type: "whatsappReminder",
      })
    );
    sendPayLaterReminder(order_id)
      .then((response: any) => {
        const updatedLogs = [...orderLogs, response.result];

        const updatedOrders = [...orders];

        const index = updatedOrders.findIndex(
          (item) => item.id === response.result.order
        );

        updatedOrders[index] = {
          ...updatedOrders[index],
          updated_at: response.result.updated_at,
        };

        dispatch(getAllOrders(updatedOrders));

        dispatch(setOrderLogs(updatedLogs));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setOrderError(error.message));
        dispatch(setOrderStatus(STATUSES.ERROR));
        Toast(`${error.message}`, TOAST_CONSTANTS.ERROR);
      })
      .finally(() => {
        dispatch(setOrderStatus(STATUSES.IDLE));
        dispatch(
          setCommonStatus({
            state: STATUSES.IDLE,
            type: "whatsappReminder",
          })
        );
      });
  };
}
