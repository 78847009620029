import React, { useMemo, useRef, useState, useEffect } from "react";
import { Label } from "../core-components";
import JoditEditor from "jodit-react";
import {
  capitalizeFirstLetter,
  isValidImageURL,
  isValidVideoURL,
  STATUSES,
  Toast,
  TOAST_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { deleteImage, uploadImage } from "store/commonSlice";

import "jodit/es2021.en/jodit.min.css";

interface SummernoteProps {
  name: string;
  label?: string;
  value?: string;
  onChange: (newContent: string) => void;
  onBlur?: (newContent: string) => void;
  summerNoteHeight?: number | string;
  onInit?: () => void;
  onImageLinkInsert?: (url: string) => void;
  onImageUpload?: (files: FileList) => void;
  ImageFolderName?: string;
  isRequired?: boolean;
}

const validImagesExtensions = ["jpg", "png", "jpeg", "gif"];

const validVideosExtensions = ["mp4", "webm", "ogg"];

const Summernote = ({
  name,
  label,
  value = "",
  onChange,
  onBlur,
  onInit,
  summerNoteHeight = 350,
  ImageFolderName = "default",
  isRequired = false,
  ...props
}: SummernoteProps) => {
  const {
    common: { image_setup, status: commonStatus },
  } = useAppSelector((state) => state.root);
  const dispatch = useAppDispatch();
  const editor = useRef(null);

  const [content, setContent] = useState(value);
  const [fileName, setFileName] = useState("");
  const [url, setUrl] = useState(image_setup?.url || "#");

  const editorOptions = [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "eraser",
    "|",
    "ul",
    "ol",
    "indent",
    "outdent",
    "|",
    "table",
    "link",
    "attachFile",
    // "image",
    // "video",
    "|",
    "undo",
    "redo",
    "source",
    "print",
    "preview",
  ];

  useEffect(() => {
    setContent(value);
  }, [value.length === 0]);

  useEffect(() => {
    if (image_setup?.url) {
      setUrl(image_setup.url);
    }
  }, [image_setup?.url]);

  const prepareUploaderConfig = async (file: any, editor: any) => {
    if (!file) {
      Toast("No files selected", TOAST_CONSTANTS.WARNING);
      return;
    }

    setFileName(file.name);

    const fileType = file.type;

    try {
      if (fileType.includes("image") || fileType.includes("video")) {
        dispatch(
          uploadImage({
            params: {
              folder_name: ImageFolderName,
              file_name: file.name,
              file,
            },
            updatePropsValues(value) {
              if (isValidImageURL(value)) {
                const regex = new RegExp(
                  `\\.(${validImagesExtensions.join("|")})$`,
                  "i"
                );
                const tagName = "img";
                const elm = editor?.createInside?.element(tagName);

                elm.setAttribute("src", value);
                elm.setAttribute(
                  "alt",
                  fileName.replace(regex, "").toLowerCase()
                );
                elm.setAttribute("title", capitalizeFirstLetter(fileName));

                editor?.s.insertImage(elm as HTMLImageElement, null);
              } else if (isValidVideoURL(value)) {
                editor?.s.insertHTML(
                  `<video playsinline autoplay muted loop> <source src=${value} type="video/mp4"> </video>`
                );
              }
            },
          })
        );
      } else {
        Toast(
          "Invalid file type , Please upload a valid image or video file",
          TOAST_CONSTANTS.ERROR
        );
      }

      return true;
    } catch (error) {
      Toast("Error uploading image", TOAST_CONSTANTS.ERROR);
      return false;
    }
  };

  async function updateFiles(files: any, editor: any) {
    if (files.length === 0) {
      console.error("No files selected");
      return;
    }

    const isSuccess = await prepareUploaderConfig(files[0], editor);

    if (!isSuccess) return;
  }

  const config = useMemo(() => {
    if (!url) {
      console.error("Uploader URL is not defined");
      return {};
    }

    return {
      readonly: false,
      placeholder: "",
      defaultLineHeight: 1.5,
      enter: "div" as "div" | "br" | "p",
      enableDragAndDropFileToEditor: true,
      imageDefaultWidth: 1920,
      buttons: editorOptions,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      uploader: {
        uploadRemoteImage: true,
        insertImageAsBase64URI: false,
        imagesExtensions: validImagesExtensions,
        withCredentials: false,
        format: "json",
        method: "POST",
        url: url,
        filesVariableName: function (t: number) {
          return `files[${t}]`;
        },
        prepareData: function (formData) {
          return formData;
        },
        buildData: async function (formData) {
          const resultData: any = await prepareUploaderConfig(formData, "");
          return resultData;
        },
        isSuccess: function (resp: any) {
          return true;
        },
        getMessage: function (e: any) {
          return e?.data?.messages?.join("") || "";
        },
        process: function (resp: any) {
          return {
            files: [`${url}${ImageFolderName}/${fileName}`],
            messages: ["Uploaded successfully"],
            isImages: [true],
            baseurl: url,
          };
        },
        error: function (e: any) {
          const _this = this.jodit ?? this;
          _this?.j?.e?.fire("errorMessage", e?.message || "", "error", 4000);
        },
        defaultHandlerSuccess: function (resp: any) {
          const _this = this.jodit ?? this;
          const regex = new RegExp(
            `\\.(${validImagesExtensions.join("|")})$`,
            "i"
          );

          const tagName = "img";
          const elm = _this?.createInside?.element(tagName);

          elm.setAttribute("src", `${url}${ImageFolderName}/${fileName}`);
          elm.setAttribute("alt", fileName.replace(regex, "").toLowerCase());
          elm.setAttribute("title", capitalizeFirstLetter(fileName));

          _this?.s?.insertImage(elm as HTMLImageElement, null);
        },
        defaultHandlerError: function (e: any) {
          const _this = this.jodit ?? this;
          _this.j.e.fire("errorMessage", e.message || "");
        },
        contentType: function (e: any) {
          const _this = this.jodit ?? this;
          return (
            (void 0 === _this?.jodit?.ownerWindow?.FormData ||
              "string" == typeof e) &&
            "application/x-www-form-urlencoded; charset=UTF-8"
          );
        },
      },
      events: {
        afterRemoveNode: (e) => {
          const removedNode = e;
          if (removedNode && removedNode.tagName === "IMG") {
            const src = removedNode.src;
            dispatch(deleteImage(src));
          } else if (
            removedNode.tagName === "VIDEO" ||
            removedNode.tagName === "JODIT-MEDIA"
          ) {
            const videoElement = e.querySelector("video");
            if (videoElement) {
              const sourceElement = videoElement.querySelector("source");
              if (sourceElement) {
                const videoSrc = sourceElement.getAttribute("src");

                dispatch(deleteImage(videoSrc));
              }
            }
          }
        },
      },
      controls: {
        attachFile: {
          exec: function (editor) {
            let input = document.createElement("input");
            input.type = "file";
            input.onblur = () => console.log("onblur");
            input.onchange = ({ target }: { target: any }) =>
              updateFiles(
                Object.keys(target.files).map((key) => target.files[key]),
                editor
              );
            input.click();
          },
          icon: "upload",
          tooltip: "Attach Files",
        },
      },
      toolbarAdaptive: false,
    };
  }, []);

  return (
    <div className="form-group my-2">
      {label && (
        <>
          <Label label={label} labelClassNames="form-label" />
          {isRequired ? <span className="text-danger">*</span> : ""}
        </>
      )}
      {commonStatus.state === STATUSES.LOADING &&
      commonStatus.type === "upload-image" ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : null}
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        onBlur={(newContent) => {
          setContent(newContent);
          if (onBlur) onBlur(newContent);
        }}
        onChange={onChange}
      />
    </div>
  );
};

export { Summernote };
