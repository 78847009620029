import { Button, CustomInput, FileUpload, OffCanvas } from "components";
import {
  ErrorMessage,
  FieldArrayRenderProps,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import React, { useMemo } from "react";
import { uploadImage } from "store/commonSlice";
import {
  BUTTON_CONSTANTS,
  capitalizeFirstLetter,
  FORM_CONSTANTS,
  S3_FOLDER_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";

const MediaOffCanvas = ({
  props,
  arrayHelpers,
  index,
  show,
  setShow,
}: {
  props: FormikProps<any>;
  arrayHelpers: FieldArrayRenderProps;
  index: number;
  show: {
    state: boolean;
    type: string;
  };
  setShow: React.Dispatch<
    React.SetStateAction<{
      state: boolean;
      type: string;
    }>
  >;
}) => {
  const dispatch = useAppDispatch();

  const {
    common: { status: commonStatus },
  } = useAppSelector((state) => state.root);

  const media_files_by_type = useMemo(
    () =>
      props.values.media.filter(
        (item) =>
          item.media_type === (show.type === "big" ? "full" : "thumbnail")
      ),
    [show.type, index]
  );

  const initialValues = {
    media_type: show.type === "big" ? "full" : "thumbnail",
    media_file: index !== -1 ? media_files_by_type[index]?.media_file : "",
    alt: index !== -1 ? media_files_by_type[index]?.alt : "",
    priority:
      index !== -1
        ? media_files_by_type?.[index]?.priority
        : media_files_by_type?.length,
  };

  return (
    <OffCanvas
      title={`${index !== -1 ? "Edit" : "Add"} ${capitalizeFirstLetter(
        show.type
      )} Media`}
      show={show.state}
      onClickHandler={() => {
        setShow({ state: false, type: "" });
      }}
    >
      {show.state ? (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, action) => {
            if (index == -1) {
              arrayHelpers.push(values);
            } else {
              arrayHelpers.replace(index, values);
            }
            setShow({ state: false, type: "" });
            action.resetForm();
          }}
        >
          {(subProps) => {
            return (
              <div className="row">
                <div className="col-12 my-2">
                  <FileUpload
                    value={null}
                    isDisabled={index !== -1}
                    name={`media_file`}
                    label={`${capitalizeFirstLetter(show.type)} Image`}
                    preview={
                      subProps?.values.media_file.preview ||
                      subProps?.values.media_file ||
                      ""
                    }
                    multipleUpload={false}
                    onChange={(e) => {
                      e &&
                        e.target.files.length > 0 &&
                        dispatch(
                          uploadImage({
                            params: {
                              folder_name: S3_FOLDER_CONSTANTS.PRODUCT_WIDGET,
                              file_name: e.target.files[0].name,
                              file: e.target.files[0],
                            },
                            updatePropsValues: (value) => {
                              subProps.setFieldValue(
                                `media_file`,
                                Object.assign(e.target.files[0], {
                                  preview: value,
                                })
                              );
                            },
                          })
                        );
                    }}
                  />
                  <ErrorMessage
                    name={`media_file`}
                    component={FORM_CONSTANTS.ERROR_PARENT}
                    className={FORM_CONSTANTS.ERROR}
                  />
                </div>
                <div className="col-12 my-2">
                  <CustomInput
                    value={subProps.values.alt}
                    label={`${capitalizeFirstLetter(show.type)} Alt Text`}
                    name={`alt`}
                    type={FORM_CONSTANTS.TEXT}
                    placeholder={`Enter ${capitalizeFirstLetter(
                      show.type
                    )} Alt Text`}
                  />
                  <ErrorMessage
                    name={`alt`}
                    component={FORM_CONSTANTS.ERROR_PARENT}
                    className={FORM_CONSTANTS.ERROR}
                  />
                </div>
                <div className="col-12">
                  <Button
                    isDisabled={
                      commonStatus.state === STATUSES.LOADING &&
                      commonStatus.type === "upload-image"
                    }
                    type={BUTTON_CONSTANTS.BUTTON}
                    text={FORM_CONSTANTS.SUBMIT}
                    btnClassNames={"btn btn-primary align-self-center w-25"}
                    onClickHandler={() => {
                      subProps.submitForm();
                    }}
                  />
                </div>
              </div>
            );
          }}
        </Formik>
      ) : null}
    </OffCanvas>
  );
};

export { MediaOffCanvas };
