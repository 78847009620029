import {
  faCartShopping,
  faMaximize,
  faMinimize,
  faMoneyBills,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Card, FontIcon, IconButton } from "components/core-components";
import React, { useState } from "react";
import { HoldBillModal } from "./HoldBillModal";
import {
  BUTTON_CONSTANTS,
  POS_CART_CONSTANTS,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { LastOrderDetails } from "store/POS/orderSlice";
import { ResetWholeCart } from "store/POS";

import { OrdersModal } from "./OrdersModal";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { setSelectedStore } from "store/ManageBranches/branchSlice";
import { ConfirmModal } from "components/ConfirmModal";
import { PrintOptionsModal } from "./PrintOptionsModal";
import { SelectedStoreDetails } from "./SelectedStoreDetails";
import CustomerDetails from "./CustomerDetails";
import LastBillDetails from "./LastBillDetails";
import { CreditNoteModal } from "./CreditNoteModal";

interface IProps {
  full: boolean;
  setFull: React.Dispatch<React.SetStateAction<boolean>>;
  printOptionsModal: boolean;
  setPrintOptionsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * POSSidebar component.
 * @param {Object} props - The component props.
 * @param {boolean} props.full - Indicates whether the sidebar is in full screen mode.
 * @param {Function} props.setFull - Callback function to set the full screen mode.
 * @param {boolean} props.printOptionsModal - Indicates whether the print options modal is open.
 * @param {Function} props.setPrintOptionsModal - Callback function to set the print options modal.
 */

const POSSidebar = ({
  full,
  setFull,
  printOptionsModal,
  setPrintOptionsModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  const {
    auth: { permissions: permissionData },
    cart: { cart },
  } = useAppSelector((state) => state.root);

  const [ordersModal, setOrdersModal] = useState<boolean>(false);

  const [holdBillModal, setHoldBillModal] = useState<boolean>(false);

  const [creditNoteModal, setCreditNoteModal] = useState<boolean>(false);

  const [store, setStore] = useState<number>(0);

  const [confirmReset, setConfirmReset] = useState<boolean>(false);

  const [confirmChangeStore, setConfirmChangeStore] = useState<boolean>(false);

  const returnPermissions = permissionData.find(
    (p) => p.module_name === "Manage Returns/Refunds"
  )?.perm_view;

  // Fucntion to toggle full screen
  const toggleFullSceen = (): void => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFull(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setFull(false);
      }
    }
  };

  return (
    <>
      <Card cardClassNames={`row ${full ? "py-2" : "py-1"} my-2 `}>
        <div className="row">
          <div className="d-flex flex-row justify-content-end p-0 m-0 ml-2">
            <Button
              type={BUTTON_CONSTANTS.BUTTON}
              text={<FontIcon icon={!full ? faMaximize : faMinimize} />}
              btnClassNames="col-2 btn btn-dark btn-xs mx-1 p-1"
              onClickHandler={toggleFullSceen}
            />
            <Button
              isDisabled={!(cart.cartItems.length > 0)}
              type={BUTTON_CONSTANTS.BUTTON}
              text={<FontIcon icon={faTrash} />}
              btnClassNames="col-2 btn btn-danger btn-xs mx-1 p-1"
              onClickHandler={() => {
                setConfirmReset(true);
              }}
            />
          </div>
          <div className="d-flex flex-column">
            {/* Button for Orders modal */}
            <div className="row">
              <IconButton
                icon={faCartShopping}
                btnClassNames="btn btn-dark btn-sm m-1"
                onClickHandler={() => {
                  setOrdersModal(true);
                }}
                btnText={POS_CART_CONSTANTS.ORDERS}
                btnTextSize="text-sm"
              />
              {ordersModal && (
                <OrdersModal
                  modal={ordersModal}
                  setModal={setOrdersModal}
                  fullScreenSize="xxl"
                />
              )}
            </div>
            {/* Button for Hold Bill modal */}
            <div className="row">
              <IconButton
                align="left"
                icon={faPause}
                btnClassNames="btn btn-dark btn-sm m-1"
                onClickHandler={() => {
                  setHoldBillModal(true);
                }}
                btnText={POS_CART_CONSTANTS.HOLD_BILL}
                btnTextSize="text-sm"
              />
              {holdBillModal && (
                <HoldBillModal
                  modal={holdBillModal}
                  setModal={setHoldBillModal}
                />
              )}
            </div>
            {returnPermissions ? (
              <div className="row">
                <IconButton
                  align="left"
                  icon={faMoneyBills}
                  btnClassNames="btn btn-dark btn-sm m-1"
                  onClickHandler={() => {
                    setCreditNoteModal(true);
                  }}
                  btnText={POS_CART_CONSTANTS.CREDIT_NOTE_LABEL}
                  btnTextSize="text-sm"
                />
                {creditNoteModal && (
                  <CreditNoteModal
                    modal={creditNoteModal}
                    setModal={setCreditNoteModal}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </Card>
      {/* Selected store details and the option to change store */}
      <SelectedStoreDetails
        setStore={setStore}
        setConfirmChangeStore={setConfirmChangeStore}
        full={full}
      />
      <CustomerDetails full={full} />
      <LastBillDetails
        setPrintOptionsModal={setPrintOptionsModal}
        full={full}
      />
      <ConfirmModal
        modal={confirmReset}
        title=""
        setModal={setConfirmReset}
        message={POS_CART_CONSTANTS.RESET_MESSAGE}
        confirmClick={() => {
          dispatch(ResetWholeCart());
          setConfirmReset(false);
        }}
      />
      <ConfirmModal
        modal={confirmChangeStore}
        setModal={setConfirmChangeStore}
        title=""
        message={`${POS_CART_CONSTANTS.RESET_MESSAGE} ${POS_CART_CONSTANTS.CHANGE_STORE_MESSAGE}`}
        confirmClick={() => {
          dispatch(LastOrderDetails(store));
          dispatch(setSelectedStore(store));
          dispatch(ResetWholeCart());
          setConfirmChangeStore(false);
          setStore(0);
        }}
      />
      <PrintOptionsModal
        modal={printOptionsModal}
        setModal={setPrintOptionsModal}
      />
    </>
  );
};

export { POSSidebar };
