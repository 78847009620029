import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  FontIcon,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableHeader,
} from "components/core-components";
import { NoData } from "components/NoData";
import moment from "moment";
import React, { useMemo } from "react";
import { SendPayLaterWhatsAppReminder } from "store/POS";
import {
  CASHIER_CONSTANTS,
  checkForWhatsAppNotification,
  formatDateTime,
  GENERAL_CONSTANTS,
  IWhatsAppLogs,
  ORDER_CONSTANTS,
  PayLaterOrderDetails,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";

interface IProps {
  modal: {
    state: boolean;
    order: PayLaterOrderDetails;
  };
  setModal: React.Dispatch<
    React.SetStateAction<{
      state: boolean;
      order: PayLaterOrderDetails;
    }>
  >;
}

const WhatsAppNotificationTable = ({
  orderLogs,
  isDetailPage = false,
}: {
  orderLogs: IWhatsAppLogs[];
  isDetailPage?: boolean;
}) => {
  const WHATSAPP_LOGS_PAY_LATER_HEADER = [
    "#",
    "Sent By",
    isDetailPage ? "Type" : null,
    "Sent At",
  ];
  return (
    <Table>
      <TableHeader cols={WHATSAPP_LOGS_PAY_LATER_HEADER} />
      <TableBody>
        {orderLogs && orderLogs.length > 0 ? (
          orderLogs.map((log, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{`${log.user.first_name} ${log.user.last_name}`}</td>
              {isDetailPage && (
                <td>
                  {
                    ORDER_CONSTANTS.LOGS_OPTIONS?.find(
                      (item) => item.value === log.action
                    ).label
                  }
                </td>
              )}
              <td>{formatDateTime(log.created_at)}</td>
            </tr>
          ))
        ) : (
          <NoData
            len={CASHIER_CONSTANTS.WHATSAPP_LOGS_PAY_LATER_HEADER.length}
            msg="No logs available"
          />
        )}
      </TableBody>
    </Table>
  );
};

const PendingPayLaterWhatsappList = ({ modal, setModal }: IProps) => {
  const dispatch = useAppDispatch();

  const { status: commonStatus } = useAppSelector((state) => state.root.common);
  const { orderLogs } = useAppSelector((state) => state.root.order);

  const cannotSendAlert = useMemo(() => {
    return (
      checkForWhatsAppNotification(modal.order?.updated_at) ||
      checkForWhatsAppNotification(orderLogs[0]?.updated_at)
    );
  }, [orderLogs.length]);

  return (
    <Modal
      title={`WhatsApp messages for ${modal.order.invoice_code}`}
      showModal={modal.state}
      onClickHandler={() => {
        setModal({ state: false, order: null });
      }}
      modalClassNames="w-100"
      modalSize={GENERAL_CONSTANTS.MEDIUM}
    >
      <WhatsAppNotificationTable orderLogs={orderLogs} />
      <IconButton
        icon={faWhatsapp}
        loading={
          commonStatus.state === STATUSES.LOADING &&
          commonStatus.type === "whatsappReminder"
        }
        isDisabled={cannotSendAlert}
        btnClassNames="btn btn-dark w-100"
        onClickHandler={() =>
          dispatch(SendPayLaterWhatsAppReminder(Number(modal.order?.id)))
        }
        btnText={
          moment(modal.order.created_at).isSame(moment(), "day")
            ? "Order was placed today"
            : cannotSendAlert
            ? "Alert has already been sent today"
            : "Send WhatsApp alert"
        }
      />
    </Modal>
  );
};

export { WhatsAppNotificationTable, PendingPayLaterWhatsappList };
