import { CustomInput } from "components/group-components/CustomInput";
import {
  Button,
  Card,
  CardBody,
  Modal,
  MultiSelect,
} from "components/core-components";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  PayLaterSchema,
  calculateAllValues,
  formatDate,
  formatTime,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";
import { getEmployees } from "store/ManageEmployee/employeeSlice";
import { AddNewOrder } from "store/POS";
import { AddressAccordion } from "components/group-components/AddressAccordion";
import { ConfirmModal } from "components/ConfirmModal";

interface IProps {
  focusRef: React.MutableRefObject<any>;
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PayLaterModal = ({ focusRef, modal, setModal }: IProps) => {
  const dispatch = useAppDispatch();
  const {
    branch: { selectedStore },
    cart: { cart },
    common: { status: commonStatus },
    customer: { customerToEdit: customer_details },
    common: { current_page, total_items, total_pages, page_size },
    employee: { employees: employeeData },
  } = useAppSelector((state) => state.root);

  const [query, setQuery] = useState<string>(GENERAL_CONSTANTS.EMPTY_STRING);

  const { final_amount_to_pay } = calculateAllValues(cart);

  const PropsRef = React.useRef<FormikProps<any>>();

  const update_page = (pageNo: number) => {
    if (query !== GENERAL_CONSTANTS.EMPTY_STRING && query.length > 3) {
      dispatch(
        getEmployees({
          pageNo: 1,
          query,
          store: selectedStore,
          role: POS_CONSTANTS.STORE_MANAGER_ID,
        })
      );
    } else {
      dispatch(
        getEmployees({
          pageNo,
          query,
          store: selectedStore,
          role: POS_CONSTANTS.STORE_MANAGER_ID,
        })
      );
    }
  };

  const [addressFilled, setAddressFilled] = useState(false);

  const { resetState, nextClickHandler } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  // Fetch employees with role of store manager
  const getEmployeeOptions = useMemo(() => {
    return employeeData.map((employee) => {
      return {
        label: employee.first_name + " " + employee.last_name,
        value: employee.id,
      };
    });
  }, [employeeData]);

  // Place Pay Later Order
  const placePayLaterOrder = () => {
    const payLater = {
      delivery_type: PropsRef?.current?.values.delivery_type?.[0]?.value,
      delivery_time: PropsRef?.current?.values?.delivery_time,
      delivery_date:
        PropsRef?.current?.values?.delivery_type?.[0]?.value ===
        POS_CART_CONSTANTS.DELIVERY
          ? PropsRef?.current?.values?.delivery_date
          : formatDate(),
      address:
        PropsRef?.current?.values.delivery_type?.[0]?.value ===
        POS_CART_CONSTANTS.DELIVERY
          ? {
              address_line_1: PropsRef?.current?.values.address.address_line_1,
              address_line_2: PropsRef?.current?.values.address.address_line_2,
              city: PropsRef?.current?.values.address.city?.[0].value,
              state: PropsRef?.current?.values.address.state?.[0].value,
              country: PropsRef?.current?.values.address.country?.[0].value,
              pincode: PropsRef?.current?.values.address.pincode,
            }
          : null,
      payment_type: 5, //Pay Later
      guaranteer_person: PropsRef?.current?.values.guaranteer_person?.[0].value,
      remarks: PropsRef?.current?.values.remarks,
    };
    dispatch(
      AddNewOrder({
        focusRef: focusRef,
        payLater: payLater,
        setModal: setModal,
      })
    );
  };

  const handleSubmit = (values, actions) => {
    // Check if delivery type is delivery and address is not filled
    if (values.delivery_type?.[0].value === POS_CART_CONSTANTS.DELIVERY) {
      // Check if address is filled
      if (
        values.address.address_line_1 !== GENERAL_CONSTANTS.EMPTY_STRING ||
        values.address.address_line_2 !== GENERAL_CONSTANTS.EMPTY_STRING
      ) {
        // Check if city, state, country and pincode are filled
        placePayLaterOrder();
      } else {
        // Show confirm modal
        setAddressConfirmed(true);
      }
    } else {
      placePayLaterOrder();
    }
  };

  const [addressConfirmed, setAddressConfirmed] = useState(false);

  useEffect(() => {
    // Check if address is filled
    setAddressFilled(
      customer_details?.address?.address_line_1 !==
        GENERAL_CONSTANTS.EMPTY_STRING &&
        customer_details?.address?.address_line_1 !== undefined &&
        customer_details?.address?.address_line_2 !==
          GENERAL_CONSTANTS.EMPTY_STRING &&
        customer_details?.address?.address_line_2 !== undefined
    );
    return () => {
      setAddressFilled(false);
      resetState();
    };
  }, []);

  return (
    <Modal
      title={`Invoice Amount ₹${Math.round(final_amount_to_pay)}`}
      showModal={modal}
      onClickHandler={() => {
        setModal(false);
        PropsRef.current?.resetForm();
      }}
      modalClassNames="w-100"
      modalSize={GENERAL_CONSTANTS.LARGE}
    >
      <div className="row">
        <Formik
          enableReinitialize={true}
          initialValues={{
            guaranteer_person: GENERAL_CONSTANTS.EMPTY_STRING,
            delivery_date: formatDate(new Date()),
            delivery_time: formatTime(new Date()),
            delivery_type: [
              {
                label: POS_CART_CONSTANTS.PICKUP_LABEL,
                value: POS_CART_CONSTANTS.PICKUP,
              },
            ],
            address: {
              address_line_1: customer_details.address
                ? customer_details.address?.address_line_1
                : GENERAL_CONSTANTS.EMPTY_STRING,
              address_line_2: customer_details.address
                ? customer_details.address?.address_line_2
                : GENERAL_CONSTANTS.EMPTY_STRING,
              city: customer_details.address
                ? [
                    {
                      label: customer_details.address?.city.city,
                      value: customer_details.address?.city.id,
                    },
                  ]
                : FORM_CONSTANTS.DEFAULT_CITY,
              state: customer_details.address
                ? [
                    {
                      label: customer_details.address?.state.state,
                      value: customer_details.address?.state.id,
                    },
                  ]
                : FORM_CONSTANTS.DEFAULT_STATE,
              country: customer_details.address
                ? [
                    {
                      label: customer_details.address?.country.country,
                      value: customer_details.address?.country.id,
                    },
                  ]
                : FORM_CONSTANTS.DEFAULT_COUNTRY,
              pincode: customer_details.address
                ? customer_details.address?.pincode
                : FORM_CONSTANTS.DEFAULT_PINCODE,
            },
            remarks: GENERAL_CONSTANTS.EMPTY_STRING,
          }}
          validationSchema={PayLaterSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            PropsRef.current = props;
            return (
              <Form>
                <div className="col-12">
                  <Card>
                    <CardBody>
                      <div className="row">
                        <div className="col-md-6">
                          <MultiSelect
                            label={POS_CART_CONSTANTS.GUARANTEE_PERSON_LABEL}
                            name={POS_CART_CONSTANTS.GUARANTEE_PERSON}
                            options={getEmployeeOptions}
                            onEndReached={() => {
                              if (total_pages > current_page) {
                                nextClickHandler();
                              }
                            }}
                          />
                          <ErrorMessage
                            name={POS_CART_CONSTANTS.GUARANTEE_PERSON}
                            component={FORM_CONSTANTS.ERROR_PARENT}
                            className={FORM_CONSTANTS.ERROR}
                          />
                        </div>
                        <div className="col-md-6">
                          <MultiSelect
                            select={false}
                            label={POS_CART_CONSTANTS.DELIVERY_TYPE_LABEL}
                            name={POS_CART_CONSTANTS.DELIVERY_TYPE}
                            options={POS_CART_CONSTANTS.DELIVERY_TYPE_OPTIONS}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <CustomInput
                            label={POS_CART_CONSTANTS.DELIVERY_DATE_LABEL}
                            name={POS_CART_CONSTANTS.DELIVERY_DATE}
                            type={FORM_CONSTANTS.DATE}
                            min={new Date().toISOString().split("T")[0]}
                            placeholder={
                              POS_CART_CONSTANTS.DELIVERY_DATE_PLACEHOLDER
                            }
                          />
                          <ErrorMessage
                            name={POS_CART_CONSTANTS.DELIVERY_DATE}
                            component={FORM_CONSTANTS.ERROR_PARENT}
                            className={FORM_CONSTANTS.ERROR}
                          />
                        </div>
                        <div className="col-md-6">
                          <CustomInput
                            label={POS_CART_CONSTANTS.DELIVERY_TIME_LABEL}
                            name={POS_CART_CONSTANTS.DELIVERY_TIME}
                            type={FORM_CONSTANTS.TIME}
                            placeholder={
                              POS_CART_CONSTANTS.DELIVERY_TIME_PLACEHOLDER
                            }
                          />
                          <ErrorMessage
                            name={POS_CART_CONSTANTS.DELIVERY_TIME}
                            component={FORM_CONSTANTS.ERROR_PARENT}
                            className={FORM_CONSTANTS.ERROR}
                          />
                        </div>
                        {props.values?.delivery_type[0]?.value ===
                          POS_CART_CONSTANTS.DELIVERY && (
                          <div className="col-12 mb-3">
                            <AddressAccordion
                              props={props}
                              title={POS_CART_CONSTANTS.DELIVERY_ADDRESS_TITLE}
                              addressFilled={addressFilled}
                              setAddressFilled={setAddressFilled}
                            />
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <CustomInput
                            maxLength={120}
                            label={POS_CART_CONSTANTS.REMARKS_LABEL}
                            value={props.values.remarks}
                            name={POS_CART_CONSTANTS.REMARKS}
                            type={FORM_CONSTANTS.TEXTAREA}
                            placeholder={POS_CART_CONSTANTS.REMARKS_PLACEHOLDER}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-12">
                  <Button
                    type={BUTTON_CONSTANTS.BUTTON}
                    onClickHandler={() => {
                      props.submitForm();
                    }}
                    loading={
                      commonStatus.type ===
                      POS_CART_CONSTANTS.PAY_LATER_LOADING_STATE
                    }
                    btnClassNames="btn btn-primary"
                    text={POS_CART_CONSTANTS.PAY_LATER}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <ConfirmModal
        modal={addressConfirmed}
        setModal={setAddressConfirmed}
        message={POS_CART_CONSTANTS.WITHOUT_ADDRESS_MESSAGE}
        title={GENERAL_CONSTANTS.EMPTY_STRING}
        confirmClick={() => {
          placePayLaterOrder();
          setAddressConfirmed(false);
        }}
        rejectClick={() => {
          setAddressConfirmed(false);
        }}
      />
    </Modal>
  );
};

export { PayLaterModal };
