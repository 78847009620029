import { Button, RefundTypeModal } from "components";
import React, { useMemo, useState } from "react";
import {
  BUTTON_CONSTANTS,
  GENERAL_CONSTANTS,
  IGetOrder,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  STATUSES,
  displayValue,
  filterNullElements,
  to2Decimal,
  useAppSelector,
} from "utils";

interface IProps {
  orderDetails: IGetOrder;
  refundItems: {
    product_group: {
      id: number;
      print_hamper_name: string;
      hamper_code: string;
    };
    batch: number;
    product: number;
    price: number;
    price_with_discount: number;
    quantity: number;
    remarks: string;
    igst: number;
    cgst: number;
    sgst: number;
    cess: number;
    discount_type: string;
    discount_value: number;
    total_tax: number;
    total_amount: number;
    total_discount: number;
  }[];
  setRefundItems: React.Dispatch<
    React.SetStateAction<
      {
        product_group: {
          id: number;
          print_hamper_name: string;
          hamper_code: string;
        };
        batch: number;
        product: number;
        price: number;
        price_with_discount: number;
        quantity: number;
        remarks: string;
        igst: number;
        cgst: number;
        sgst: number;
        cess: number;
        discount_type: string;
        discount_value: number;
        total_tax: number;
        total_amount: number;
        total_discount: number;
      }[]
    >
  >;
}

const OrderSummary = ({
  orderDetails,
  refundItems,
  setRefundItems,
}: IProps) => {
  const {
    cart: { return: returnRefunds, returnOrder: returnOrderDetails },
    customer: { customerToEdit: customer_details },
    creditNote: { status: creditNoteStatus },
  } = useAppSelector((state) => state.root);

  const [remarks, setRemarks] = useState<string>("");

  const [returnOptions, setReturnOptions] = useState<boolean>(false);

  const {
    returnData,
    payable_amount,
    total_amount,
    total_quantity,
  } = useMemo(() => {
    const total_amount = to2Decimal(
      refundItems.reduce((acc, curr) => {
        return acc + curr.total_amount;
      }, 0)
    );

    const payable_amount = to2Decimal(
      refundItems.reduce((acc, curr) => {
        return acc + to2Decimal(curr.total_amount);
      }, 0)
    );

    const total_discount = to2Decimal(
      refundItems.reduce((acc, curr) => {
        return acc + to2Decimal(curr.total_discount);
      }, 0)
    );

    const total_quantity = to2Decimal(
      refundItems.reduce(
        (acc, item) =>
          Number(item.quantity) % 1 !== 0 ? acc + 1 : acc + item.quantity,
        0
      )
    );

    const returnData = {
      addresses: GENERAL_CONSTANTS.EMPTY_ARRAY,
      customer: customer_details.id,
      total_quantity: total_quantity,
      order: returnOrderDetails?.id,
      credit_note_amount: total_amount,
      roundoff: Number(
        (Math.round(payable_amount) - payable_amount).toFixed(2)
      ),
      discount_value: returnOrderDetails?.discount_value,
      discount_type: returnOrderDetails?.discount_type,
      total_discount: total_discount,
      refund_amount:
        payable_amount +
        Number((Math.round(payable_amount) - payable_amount).toFixed(2)),
      total_tax: refundItems.reduce((acc, item) => acc + item.total_tax, 0),
      credit_type: POS_CART_CONSTANTS.RETURN,
      payments: GENERAL_CONSTANTS.EMPTY_ARRAY,
      products: filterNullElements(
        refundItems.map((item) => {
          if (item.quantity > 0)
            return {
              product_group_id: item.product_group
                ? item.product_group.id
                : null,
              batch_id: item.batch,
              product_id: item.product,
              price: item.price,
              quantity: item.quantity,
              remarks: item.remarks,
              igst: item.igst,
              cgst: item.cgst,
              sgst: item.sgst,
              cess: item.cess,
              discount_type: item.discount_type,
              discount_value: item.discount_value,
              total_tax: item.total_tax,
              total_amount: to2Decimal(item.total_amount),
              total_discount: to2Decimal(item.total_discount),
            };
        })
      ),
      remarks: remarks,
      store: returnOrderDetails?.store,
      validity: 365,
    };

    return {
      total_amount,
      total_discount,
      total_quantity,
      payable_amount,
      returnData,
    };
  }, [refundItems]);

  return !returnRefunds ? (
    orderDetails?.total_quantity > 0 && (
      <>
        {orderDetails?.remarks?.length > 0 && (
          <div className="d-flex flex-row align-items-center mailbox-read-message">
            <h6 className="text-wrap">
              <span className="text-bold">Bill remarks: </span>
              {orderDetails?.remarks}
            </h6>
          </div>
        )}
        <div className="d-flex flex-column flex-md-row align-items-center mb-4">
          <div className="col-md-6 d-flex flex-row">
            <div className="col-4 text-sm text-center mt-4">
              <h6>{displayValue(to2Decimal(orderDetails?.total_quantity))}</h6>
              <h6 className="text-muted text-sm ">
                {POS_CART_CONSTANTS.TOTAL_ITEMS_LABEL}
              </h6>
            </div>
            <div className="col-4 text-sm text-center mt-4">
              <h6>
                {POS_CONSTANTS.RUPEE_SIGN}
                {displayValue(to2Decimal(orderDetails?.bill_amount))}
              </h6>
              <h6 className="text-muted text-sm">
                {POS_CART_CONSTANTS.TOTAL_MRP_LABEL}
              </h6>
            </div>
            {orderDetails.discount_value > 0 ? (
              <div className="col-4 text-sm text-center mt-4">
                <h6>
                  {orderDetails.discount_type === GENERAL_CONSTANTS.PERCENTAGE
                    ? `${displayValue(
                        to2Decimal(orderDetails?.discount_value)
                      )}${GENERAL_CONSTANTS.PERCENTAGE_SYMBOL}`
                    : `${GENERAL_CONSTANTS.FLAT_SYMBOL}${displayValue(
                        to2Decimal(orderDetails.discount_value)
                      )}`}
                </h6>
                <h6 className="text-muted text-sm ">
                  {POS_CART_CONSTANTS.DISCOUNT_LABEL}
                </h6>
              </div>
            ) : (
              <div className="col-4 text-sm text-center mt-4">
                <h6>{POS_CART_CONSTANTS.NO_DISCOUNT_LABEL}</h6>
                <h6 className="text-muted text-sm ">
                  {POS_CART_CONSTANTS.DISCOUNT_LABEL}
                </h6>
              </div>
            )}
          </div>
          <div className="col-md-6 d-flex flex-row">
            <div className="col-3 text-sm text-center mt-4">
              <h6>
                {POS_CONSTANTS.RUPEE_SIGN}
                {displayValue(to2Decimal(orderDetails?.total_discount))}
              </h6>
              <h6 className="text-muted text-sm">
                {POS_CART_CONSTANTS.TOTAL_DISCOUNT_LABEL}
              </h6>
            </div>
            <div className="col-3 text-sm text-center mt-4">
              <h6>
                {POS_CONSTANTS.RUPEE_SIGN}
                {displayValue(to2Decimal(orderDetails?.total_tax))}
              </h6>
              <h6 className="text-muted text-sm ">
                {POS_CART_CONSTANTS.TAX_AMOUNT_LABEL}
              </h6>
            </div>
            <div className="col-3 text-sm text-center mt-4">
              <h6>
                {POS_CONSTANTS.RUPEE_SIGN}
                {displayValue(to2Decimal(orderDetails?.roundoff))}
              </h6>
              <h6 className="text-muted text-sm ">
                {POS_CART_CONSTANTS.ROUND_OFF_LABEL}
              </h6>
            </div>
            <div className="col-3 text-sm text-center mt-4">
              <h6>
                {POS_CONSTANTS.RUPEE_SIGN}
                {displayValue(to2Decimal(orderDetails?.payable_amount))}
              </h6>
              <h6 className="text-muted text-sm ">
                {POS_CART_CONSTANTS.NET_AMOUNT_LABEL}
              </h6>
            </div>
          </div>
        </div>
      </>
    )
  ) : (
    <div className="d-flex flex-column mb-4">
      <div className="d-flex flex-row mt-4">
        <div className="col-12">
          <input
            maxLength={120}
            type="text"
            value={remarks}
            onChange={(e) => {
              setRemarks(e.target.value);
            }}
            className="form-control m-2"
            placeholder="Enter Remark"
          />
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row mt-4">
        <div className="col-md-8 d-flex flex-row">
          <div className="col-3 text-sm text-center">
            <h6>{total_quantity}</h6>
            <h6 className="text-muted text-sm ">
              {POS_CART_CONSTANTS.TOTAL_REFUND_ITEMS}
            </h6>
          </div>
          <div className="col-3 text-sm text-center">
            <h6>
              {POS_CONSTANTS.RUPEE_SIGN}
              {total_amount}
            </h6>
            <h6 className="text-muted text-sm ">
              {POS_CART_CONSTANTS.TOTAL_REFUND_AMOUNT}
            </h6>
          </div>
          <div className="col-3 text-sm text-center">
            <h6>
              {POS_CONSTANTS.RUPEE_SIGN}
              {Number((Math.round(payable_amount) - payable_amount).toFixed(2))}
            </h6>
            <h6 className="text-muted text-sm">
              {POS_CART_CONSTANTS.ROUND_OFF_LABEL}
            </h6>
          </div>
          <div className="col-3 text-sm text-center">
            <h6>
              {POS_CONSTANTS.RUPEE_SIGN}
              {payable_amount +
                Number(
                  (Math.round(payable_amount) - payable_amount).toFixed(2)
                )}
            </h6>
            <h6 className="text-muted text-sm ">
              {POS_CART_CONSTANTS.PAYABLE_REFUND_AMOUNT}
            </h6>
          </div>
        </div>
        <div className="col-md-4 text-sm text-center">
          <Button
            text="Return"
            isDisabled={
              refundItems.length === 0 ||
              refundItems.every((item) => item.quantity === 0)
            }
            loading={creditNoteStatus === STATUSES.LOADING}
            btnClassNames="btn btn-dark btn-sm w-100 m-2"
            type={BUTTON_CONSTANTS.BUTTON}
            onClickHandler={() => {
              setReturnOptions(true);
            }}
          />
        </div>
      </div>
      {returnOptions && (
        <RefundTypeModal
          modal={returnOptions}
          setModal={setReturnOptions}
          data={returnData}
          orderDetails={orderDetails}
          setRefundItems={setRefundItems}
        />
      )}
    </div>
  );
};

export { OrderSummary };
