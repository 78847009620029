import React, { memo, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Formik, Form, ErrorMessage, FieldArray } from "formik";

import {
  FetchFormOptionsData,
  addNewVendor,
  getIndividualVendor,
  updateExistingVendor,
} from "store/ManageVendors/vendorSlice";

import { ContentHeader, Button, Container, Loader } from "components";

import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  STATUSES,
  VENDORS_CONSTANTS,
  VendorSchema,
  useAppDispatch,
  useAppSelector,
} from "utils";

import { getCity, getCountry, getState } from "store/locationSlice";
import { getPaymentModes } from "store/ManagePayments/paymentModeSlice";
import { getPaymentTerms } from "store/ManagePayments/paymentTermSlice";
import { getProducts } from "store/ManageProducts/productSlice";
import { getTaxes } from "store/ManageProducts/productTaxSlice";
import { getBanks } from "store/ManageBanks/bankSlice";
import { getBranches } from "store/ManageBranches/branchSlice";
import { getAccountTypes } from "store/ManageBanks/bankAccountTypeSlice";
import { getSupplierTypes } from "store/ManageVendors/supplierTypeSlice";
import { getDeliveryPolicies } from "store/ManageVendors/deliveryPolicySlice";
import { getAddressTypes } from "store/addressTypeSlice";
import { getUOMs } from "store/uomSlice";

import VendorDetailsForm from "./VendorDetailsForm";
import VendorAddressDetailsForm from "./VendorAddressDetailsForm";
import VendorContactDetailsForm from "./VendorContactDetailsForm";
import VendorBankDetailsForm from "./VendorBankDetailsForm";

import VendorProductDetailsForm from "./VendorProductDetailsForm";
import VendorAdditionalPhoneNumber from "./VendorAdditionalPhoneNumber";
import StoreModal from "components/group-components/StoreModal";

const VendorForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    branch: { branch: branchData },
    product: { products: productData },
    productTax: { productTax: productTaxData },
    uom: { uom: uomData },
    vendor: { vendorToEdit, status: vendorStatus },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();
  const currentID = useMemo(() => Number(id), [id]);

  const [initialData, setInitialData] = useState({
    phone_numbers: [],
    address: [],
    preferred_ordering_days: [],
    preferred_delivery_days: [],
  });

  const [DeletedValues, setDeletedValues] = useState({
    phone_numbers: [],
    address: [],
    preferred_ordering_days: [],
    preferred_delivery_days: [],
  });

  const MultipleValuestoSend = {
    phone_numbers: [],
    address: [],
    preferred_ordering_days: [],
    preferred_delivery_days: [],
  };

  const [storeModal, setStoreModal] = useState(false);
  const [currentStore, setCurrentStore] = useState(null);
  const [currentFilledStore, setCurrentFilledStore] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const initialValues = {
    name: "",
    company_name: "",
    email: "",

    phone_numbers: [
      {
        phone_number: "",
        new: true,
      },
    ],

    whatsapp_number: "",
    supplier_type: "",
    remarks: "",
    apply_tds: "",
    payment_mode: "",
    payment_terms: "",
    credit_limit: "",

    bank_details: {
      account_type: "",
      bank_name: "",
      account_number: "",
      ifsc_code: "",
      branch_name: "",
    },

    address: [
      {
        address_type: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        country: "",
        pincode: 0,
        new: true,
      },
    ],

    gstn: "",
    pan: "",
    contact_person_name: "",
    contact_person_email: "",
    contact_person_number: "",
    commission: "",
    delivery_policy: "",

    stores: [
      {
        store: [],
        products: [
          {
            product: "",
            purchase_price: "",
            landing_cost: "",
            purchase_tax_included: [{ value: 1, label: "Yes" }],
            gst_included: "",
            uom: "",
            moq: 1,
            moq_pieces: "",
            preferred_ordering_days: "",
            preferred_delivery_days: "",
            alias: [
              {
                product_alias_name: "",
                new: true,
              },
            ],
            // turn_around_time: {
            //   min_days: "",
            //   max_days: "",
            // },
            new: true,
          },
        ],
        new: true,
      },
    ],
    is_active: [{ value: 1, label: "Active" }],
  };

  // const setEditvalues = (vendorToEdit) => {
  //   return {
  //     name: vendorToEdit.name,
  //     company_name: vendorToEdit.company_name,
  //     email: vendorToEdit.email,

  //     phone_numbers:
  //       vendorToEdit.phone_numbers &&
  //       vendorToEdit.phone_numbers.length > 0 &&
  //       vendorToEdit.phone_numbers.map((item) => ({
  //         ...item,
  //         phone_number: item.contact_number,
  //       })),

  //     whatsapp_number: vendorToEdit.whatsapp_number,
  //     remarks: vendorToEdit.remarks,

  //     supplier_type:
  //       vendorToEdit.supplier_type &&
  //       supplierTypeData
  //         .filter((item) => item.id === vendorToEdit.supplier_type)
  //         .map((res) => ({ value: res.id, label: res.name })),

  //     apply_tds: [
  //       {
  //         value: vendorToEdit.apply_tds,
  //         label: vendorToEdit.apply_tds === 1 ? "Yes" : "No",
  //       },
  //     ],

  //     payment_mode:
  //       vendorToEdit.payment_mode &&
  //       paymentModeData
  //         .filter((item) => item.id === vendorToEdit.payment_mode)
  //         .map((res) => ({ value: res.id, label: res.payment_mode })),

  //     credit_limit: vendorToEdit?.credit_limit,

  //     payment_terms:
  //       vendorToEdit.payment_terms &&
  //       paymentTermData
  //         .filter((item) => item.id === vendorToEdit.payment_terms)
  //         .map((res) => ({ value: res.id, label: res.term_name })),

  //     bank_details: vendorToEdit &&
  //       vendorToEdit.bank_details && {
  //         ...vendorToEdit.bank_details[0],
  //         account_type:
  //           vendorToEdit.bank_details &&
  //           vendorToEdit.bank_details.length > 0 &&
  //           vendorToEdit.bank_details[0] &&
  //           vendorToEdit.bank_details[0].account_type &&
  //           bankAccountTypeData
  //             .filter(
  //               (item) => item.id === vendorToEdit.bank_details[0].account_type
  //             )
  //             .map((res) => ({ value: res.id, label: res.type })),

  //         bank_name:
  //           vendorToEdit.bank_details &&
  //           vendorToEdit.bank_details.length > 0 &&
  //           vendorToEdit.bank_details[0] &&
  //           bankData
  //             .filter(
  //               (item) =>
  //                 item.bank_name === vendorToEdit.bank_details[0].bank_name
  //             )
  //             .map((res) => ({ value: res.id, label: res.bank_name })),

  //         account_number:
  //           vendorToEdit.bank_details &&
  //           vendorToEdit.bank_details.length > 0 &&
  //           vendorToEdit.bank_details[0].account_number,

  //         ifsc_code:
  //           vendorToEdit.bank_details &&
  //           vendorToEdit.bank_details.length > 0 &&
  //           vendorToEdit.bank_details[0].ifsc_code,

  //         branch_name:
  //           vendorToEdit.bank_details &&
  //           vendorToEdit.bank_details.length > 0 &&
  //           vendorToEdit.bank_details[0].branch_name,
  //       },

  //     address:
  //       vendorToEdit.address &&
  //       vendorToEdit.address.map((item, index) => ({
  //         ...vendorToEdit.address[index],
  //         address_type:
  //           vendorToEdit.address[index].address_type &&
  //           addressTypeData
  //             .filter(
  //               (item) => item.id === vendorToEdit.address[index].address_type
  //             )
  //             .map((res) => ({ value: res.id, label: res.type })),

  //         address_line_1: item.address_line_1,
  //         address_line_2: item.address_line_2,

  //         country:
  //           item.country &&
  //           countryData
  //             .filter((res) => res.id === item.country)
  //             .map((res) => ({ value: res.id, label: res.country })),

  //         state:
  //           item.country &&
  //           item.state &&
  //           stateData
  //             .filter((res) => res.id === item.state)
  //             .map((res) => ({ value: res.id, label: res.state })),

  //         city:
  //           item.state &&
  //           item.city &&
  //           cityData
  //             .filter((res) => res.id === item.city)
  //             .map((res) => ({ value: res.id, label: res.city })),

  //         pincode: item.pincode,
  //       })),

  //     gstn: vendorToEdit.gstn,
  //     pan: vendorToEdit.pan,
  //     contact_person_name: vendorToEdit.contact_person_name,
  //     contact_person_email: vendorToEdit.contact_person_email,
  //     contact_person_number: vendorToEdit.contact_person_number,
  //     commission: vendorToEdit.commission,

  //     delivery_policy:
  //       vendorToEdit.delivery_policy &&
  //       deliveryPolicyData
  //         .filter((item) => item.id === vendorToEdit.delivery_policy)
  //         .map((res) => ({ value: res.id, label: res.policy })),

  //     stores:
  //       vendorToEdit.stores &&
  //       vendorToEdit.stores.length > 0 &&
  //       vendorToEdit.stores.map((item) => ({
  //         ...item,
  //         store:
  //           item.store &&
  //           branchData
  //             .filter((res) => res.id === item.store)
  //             .map((res) => ({ value: res.id, label: res.store_name })),

  //         products:
  //           item.products &&
  //           item.products.length > 0 &&
  //           item.products.map((productItem) => ({
  //             ...productItem,
  //             product: productItem.product
  //               ? [
  //                   {
  //                     value: productItem.product.id,
  //                     label: productItem.product.print_name,
  //                   },
  //                 ]
  //               : [],

  //             purchase_price: productItem?.purchase_price,
  //             landing_cost: productItem?.landing_cost,
  //             purchase_tax_included: item.purchase_tax_included
  //               ? [
  //                   {
  //                     value: productItem.purchase_tax_included,
  //                     label:
  //                       productItem.purchase_tax_included === 1 ? "Yes" : "No",
  //                   },
  //                 ]
  //               : [],

  //             // turn_around_time: {
  //             //   min_days: productItem?.min_days,
  //             //   max_days: productItem?.max_days,
  //             // },

  //             gst_included: productItem.gst_included
  //               ? [
  //                   {
  //                     value: productItem.gst_included,
  //                     label: productItem.gst_included === 1 ? "Yes" : "No",
  //                   },
  //                 ]
  //               : [],

  //             uom:
  //               productItem.uom &&
  //               uomData
  //                 .filter((res) => res.id === productItem.uom)
  //                 .map((res) => ({ value: res.id, label: res.uom_name })),

  //             moq: productItem.moq,
  //             moq_pieces: productItem.moq_pieces,

  //             preferred_ordering_days:
  //               productItem.preferred_ordering_days &&
  //               productItem.preferred_ordering_days.length > 0
  //                 ? productItem.preferred_ordering_days.map((item) => ({
  //                     value: item.id,
  //                     label: item.days,
  //                   }))
  //                 : [],

  //             preferred_delivery_days:
  //               productItem.preferred_delivery_days &&
  //               productItem.preferred_delivery_days.length > 0
  //                 ? productItem.preferred_delivery_days.map((item) => ({
  //                     value: item.id,
  //                     label: item.days,
  //                   }))
  //                 : [],

  //             alias:
  //               productItem.alias && productItem.alias.length > 0
  //                 ? productItem.alias.map((alias) => ({
  //                     ...alias,
  //                     product_alias_name: alias.product_alias_name,
  //                   }))
  //                 : [],
  //           })),
  //       })),

  //     is_active: vendorToEdit.is_active
  //       ? [
  //           {
  //             value: vendorToEdit.is_active,
  //             label:
  //               vendorToEdit.is_active === 1
  //                 ? GENERAL_CONSTANTS.ACTIVE
  //                 : GENERAL_CONSTANTS.INACTIVE,
  //           },
  //         ]
  //       : [],
  //   };
  // };

  const handleDelete = (values) => {
    const updatedValues = values;
    updatedValues.phone_numbers.map(
      (phoneNumber) => phoneNumber.new && delete phoneNumber.new
    );

    updatedValues.address.map((address) => address.new && delete address.new);

    updatedValues.stores.map((store) => store.new && delete store.new);

    updatedValues.stores.map((store) =>
      store.products.map((product) => product.new && delete product.new)
    );

    updatedValues.stores.map((store) =>
      store.products.map((product) =>
        product.alias.map((alias) => alias.new && delete alias.new)
      )
    );
    return updatedValues;
  };

  const handleSubmit = (values, actions) => {
    if (id) {
      initialData.phone_numbers.length === 0 &&
        initialData.phone_numbers.push(vendorToEdit.phone_numbers);
      initialData.address.length === 0 &&
        initialData.address.push(vendorToEdit.address);

      initialData.preferred_ordering_days.length === 0 &&
        initialData.preferred_ordering_days.push(
          vendorToEdit.stores.map((store) =>
            store.products.map((product) => product.preferred_ordering_days)
          )
        );

      initialData.preferred_delivery_days.length === 0 &&
        initialData.preferred_delivery_days.push(
          vendorToEdit.stores.map((store) =>
            store.products.map((product) => product.preferred_delivery_days)
          )
        );

      let modifiedData = {
        ...values,
        id: currentID,
        phone_numbers: values.phone_numbers.map((item) => ({
          id: item.id,
          vendor: currentID,
          phone_number: item.phone_number.toString(),
        })),
        supplier_type: values.supplier_type[0]?.value,
        payment_mode: values.payment_mode[0]?.value,
        payment_terms: values.payment_terms[0]?.value,
        delivery_policy: values.delivery_policy[0]?.value,
        apply_tds: values.apply_tds[0]?.label === "Yes" ? 1 : 0,
        is_active: values.is_active[0]?.value,
        bank_details: {
          ...values.bank_details,
          vendor: currentID,
          account_type: values.bank_details.account_type[0]?.value,
          bank_name: values.bank_details.bank_name[0]?.label,
        },
        address: values.address.map((item) => ({
          ...item,
          vendor: currentID,
          address_type: item.address_type[0]?.value,
          city: item.city[0]?.value,
          state: item.state[0]?.value,
          country: item.country[0]?.value,
        })),
        stores: values.stores.map((item) => ({
          ...item,
          vendor: currentID,
          store: item.store[0]?.value,
          products: item.products.map((product) => ({
            ...product,
            product: product.product[0]?.value,
            purchase_tax_included: product.purchase_tax_included[0]?.value,
            gst_included: product.gst_included[0]?.value,
            uom: product.uom[0]?.value,
            // min_days: product.turn_around_time.min_days,
            // max_days: product.turn_around_time.max_days,
            preferred_ordering_days: product.preferred_ordering_days.map(
              (item) => item.value
            ),
            preferred_delivery_days: product.preferred_delivery_days.map(
              (item) => item.value
            ),
          })),
        })),
      };

      values.stores.map((store, storeIndex) =>
        store.products.map((product, productIndex) =>
          product.preferred_ordering_days.forEach((day, dayIndex) => {
            if (
              !initialData.preferred_ordering_days.flat()[storeIndex][
                productIndex
              ][dayIndex].label === day.label
            ) {
              MultipleValuestoSend.preferred_ordering_days.push(day.value);
            }
          })
        )
      );

      values.stores.map((store, storeIndex) =>
        store.products.map((product, productIndex) =>
          product.preferred_delivery_days.forEach((day, dayIndex) => {
            if (
              !initialData.preferred_delivery_days.flat()[storeIndex][
                productIndex
              ][dayIndex].label === day.label
            ) {
              MultipleValuestoSend.preferred_delivery_days.push(day.value);
            }
          })
        )
      );

      // values.phone_numbers.forEach((data) => {
      //   if (
      //     !initialData.phone_numbers
      //       .flat()
      //       .some((item) => data && item && item.id === data.id)
      //   ) {
      //     MultipleValuestoSend.phone_numbers.push(data);
      //   }
      // });

      // values.address.forEach((data) => {
      //   if (
      //     !initialData.address
      //       .flat()
      //       .some((item) => data && item && item.id === data.id)
      //   ) {
      //     MultipleValuestoSend.address.push(data);
      //   }
      // });

      modifiedData = {
        ...modifiedData,
        // phone_numbers: MultipleValuestoSend.phone_numbers,
        // address: MultipleValuestoSend.address,
        stores: modifiedData.stores.map((store, storeIndex) => ({
          ...store,
          products: store.products.map((product, productIndex) => ({
            ...product,
            preferred_ordering_days: MultipleValuestoSend.preferred_ordering_days.filter(
              (item) => item !== undefined
            ),
            preferred_delivery_days: MultipleValuestoSend.preferred_delivery_days.filter(
              (item) => item !== undefined
            ),
          })),
        })),
      };

      const dataToSend = handleDelete(modifiedData);

      // dispatch(updateExistingVendor(Number(id), dataToSend));
    } else {
      const modifiedData = {
        ...values,
        phone_numbers: values.phone_numbers.map((item) =>
          item.phone_number.toString()
        ),
        supplier_type: values.supplier_type[0]?.value,
        payment_mode: values.payment_mode[0]?.value,
        payment_terms: values.payment_terms[0]?.value,
        delivery_policy: values.delivery_policy[0]?.value,
        apply_tds: values.apply_tds[0]?.label === "Yes" ? 1 : 0,
        is_active: values.is_active[0]?.value,
        bank_details: {
          ...values.bank_details,
          account_type: values.bank_details.account_type[0]?.value,
          bank_name: values.bank_details.bank_name[0]?.label,
        },
        address: values.address.map((item) => ({
          ...item,
          address_type: item.address_type[0]?.value,
          city: item.city[0]?.value,
          state: item.state[0]?.value,
          country: item.country[0]?.value,
        })),
        stores: values.stores.map((item) => ({
          ...item,
          store: item.store[0]?.value,
          products: item.products.map((product) => ({
            ...product,
            product: product.product[0]?.value,
            purchase_tax_included: product.purchase_tax_included[0]?.value,
            gst_included: product.gst_included[0]?.value,
            uom: product.uom[0]?.value,
            // min_days: product.turn_around_time.min_days,
            // max_days: product.turn_around_time.max_days,
            preferred_ordering_days: product.preferred_ordering_days.map(
              (item) => item.value
            ),
            preferred_delivery_days: product.preferred_delivery_days.map(
              (item) => item.value
            ),
          })),
        })),
      };
      const dataToSend = handleDelete(modifiedData);
      dispatch(addNewVendor(dataToSend));
    }
    // actions.resetForm();
    // actions.setSubmitting(false);
    // navigate(VENDORS_CONSTANTS.VENDOR_NAVIGATE);
  };

  useEffect(() => {
    dispatch(FetchFormOptionsData());
  }, []);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader
          editValue={vendorToEdit?.name}
          pageHeader={
            id ? VENDORS_CONSTANTS.EDIT_HEADER : VENDORS_CONSTANTS.ADD_HEADER
          }
        />
        {vendorStatus === STATUSES.LOADING ? (
          <div className="d-flex justify-content-center ">
            <Loader />
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <Formik
                enableReinitialize={true}
                initialValues={currentID ? vendorToEdit : initialValues}
                validationSchema={VendorSchema}
                onSubmit={handleSubmit}
              >
                {(props) => (
                  <Form>
                    {/* Vendor Details */}
                    <VendorDetailsForm props={props} />
                    {/* Additional Phone Number */}
                    <VendorAdditionalPhoneNumber
                      props={props}
                      DeletedValues={DeletedValues.phone_numbers}
                    />
                    {/* Vendor Contact Person Details */}
                    <VendorContactDetailsForm />
                    {/* Vendor Bank Details */}
                    <VendorBankDetailsForm />
                    {/* Vendor Address Details */}
                    <VendorAddressDetailsForm
                      props={props}
                      DeletedValues={DeletedValues.address}
                    />
                    {/* Product Details */}
                    <VendorProductDetailsForm
                      props={props}
                      setSelectedProduct={setSelectedProduct}
                      setStoreModal={setStoreModal}
                      setCurrentStore={setCurrentStore}
                      setCurrentFilledStore={setCurrentFilledStore}
                      DeletedOrderValues={DeletedValues.preferred_ordering_days}
                      DeletedDeliveryValues={
                        DeletedValues.preferred_delivery_days
                      }
                    />
                    {/* Store To Copy Choice */}
                    <StoreModal
                      modalState={storeModal}
                      setModalState={setStoreModal}
                      currentStore={currentStore}
                      filledStores={currentFilledStore}
                      allStores={branchData.map((item) => ({
                        value: item.id,
                        label: item.store_name,
                      }))}
                      product={selectedProduct}
                      setFinalValue={props.setFieldValue}
                    />
                    {/* submit */}
                    <div className="col-md-4">
                      <Button
                        type={BUTTON_CONSTANTS.SUBMIT}
                        text={FORM_CONSTANTS.SUBMIT}
                        btnClassNames={"btn btn-primary"}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export { VendorForm };
