import React from "react";
import { FontIcon } from "./FontIcon";

interface IProps {
  tabs: any[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  className?: string;
  navClassName?: string;
}

const Tabs = ({
  tabs,
  activeTab,
  setActiveTab,
  className,
  navClassName,
}: IProps) => {
  return (
    <ul className={`nav nav-underline ${navClassName}`}>
      {tabs.map((tab, index) => {
        const tabLabel = tab?.label || tab;
        return (
          tab && (
            <div
              className={`d-flex flex-row align-items-center ${className}`}
              key={index}
            >
              {/* {tab?.icon && (
                <FontIcon icon={tab?.icon} iconClassNames={`nav-icon`} />
              )} */}
              <li className="nav-item">
                <a
                  className={`nav-link text-dark ${
                    activeTab === tabLabel ? "active text-bold" : ""
                  }`}
                  onClick={() => setActiveTab(tabLabel)}
                >
                  {tabLabel}
                </a>
              </li>
            </div>
          )
        );
      })}
    </ul>
  );
};

export { Tabs };
