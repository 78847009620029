import { Card, CardBody, CardHeader, Loader, MultiSelect } from "components";
import { ErrorMessage } from "formik";
import React, { useMemo } from "react";
import {
  FORM_CONSTANTS,
  SITE_MANAGEMENT_CONSTANTS,
  STATUSES,
  useAppSelector,
} from "utils";

const MenuDetails = ({ props }) => {
  const {
    ecommerce: {
      menu: { menus: menuData },
      siteManagement: { status },
    },
  } = useAppSelector((state) => state);

  const getMenuOptions = useMemo(() => {
    return menuData.map((menu) => ({
      label: menu.menu_title,
      value: menu.id,
    }));
  }, [menuData.length]);

  return (
    <div className="col-12">
      <Card>
        <CardHeader>
          <h3 className="card-title">
            {SITE_MANAGEMENT_CONSTANTS.MENU_HEADER}
          </h3>
        </CardHeader>
        <CardBody>
          {status === STATUSES.LOADING && !props.isSubmitting ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : (
            <div className="row">
              <div className="col-md-6">
                <MultiSelect
                  name={`${SITE_MANAGEMENT_CONSTANTS.MENU_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.HEADER_MENU}`}
                  label={SITE_MANAGEMENT_CONSTANTS.HEADER_MENU_LABEL}
                  options={getMenuOptions}
                  placeholder={
                    SITE_MANAGEMENT_CONSTANTS.HEADER_MENU_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={`${SITE_MANAGEMENT_CONSTANTS.MENU_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.HEADER_MENU}`}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-md-6">
                <MultiSelect
                  name={`${SITE_MANAGEMENT_CONSTANTS.MENU_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.FOOTER_MENU}`}
                  label={SITE_MANAGEMENT_CONSTANTS.FOOTER_MENU_LABEL}
                  options={getMenuOptions}
                  placeholder={
                    SITE_MANAGEMENT_CONSTANTS.FOOTER_MENU_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={`${SITE_MANAGEMENT_CONSTANTS.MENU_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.FOOTER_MENU}`}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              {/* <div className="col-md-4">
                <MultiSelect
                  name={`${SITE_MANAGEMENT_CONSTANTS.MENU_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.MEGA_MENU}`}
                  label={SITE_MANAGEMENT_CONSTANTS.MEGA_MENU_LABEL}
                  options={getMenuOptions}
                  placeholder={SITE_MANAGEMENT_CONSTANTS.MEGA_MENU_PLACEHOLDER}
                />
                <ErrorMessage
                  name={`${SITE_MANAGEMENT_CONSTANTS.MENU_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.MEGA_MENU}`}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div> */}
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export { MenuDetails };
