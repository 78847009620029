import { Card, CardBody, Modal } from "components/core-components";
import React, { useEffect, useMemo, useState } from "react";
import { AddNewCreditNote } from "store/POS";
import {
  flattenArray,
  formatModules,
  IGetOrder,
  useAppDispatch,
  useAppSelector,
} from "utils";
import { MasterPasswordModal } from "./MasterPasswordModal";
import { getModules } from "store/ManageRoles/moduleSlice";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
  orderDetails: IGetOrder;
  setRefundItems: React.Dispatch<React.SetStateAction<any>>;
}

const RefundTypeModal = ({
  modal,
  setModal,
  data,
  orderDetails,
  setRefundItems,
}: IProps) => {
  const dispatch = useAppDispatch();

  const {
    auth: { permissions },
    common: { master_password_verified },
    module: { modules },
  } = useAppSelector((state) => state.root);

  const [masterPasswordModal, setMasterPasswordModal] = useState(false);

  const canCashReturn = permissions.find(
    (item) => item.module_name === "Cash Return"
  )?.perm_authorize;

  const findSubModuleId = useMemo(() => {
    const moduleData = flattenArray(formatModules(modules));

    return moduleData.find((item) => item.module_name === "Cash Return")
      ?.module;
  }, [modules?.length]);

  useEffect(() => {
    dispatch(getModules());
  }, []);

  return (
    <Modal
      modalClassNames="w-100"
      modalSize="lg"
      title={"Print"}
      showModal={modal}
      onClickHandler={() => {
        setModal(false);
      }}
    >
      <Card>
        <CardBody>
          <div className="d-flex flex-row">
            <div className="col-12">
              <div className="row">
                <div className="col-6 text-sm text-center p-1">
                  <button
                    className="btn btn-dark btn-primary text-sm w-100"
                    onClick={() => {
                      dispatch(
                        AddNewCreditNote({
                          creditNote: {
                            ...data,
                            credit_type: "return",
                          },
                          setRefundItems: setRefundItems,
                        })
                      );
                      setModal(false);
                    }}
                  >
                    Credit Return
                  </button>
                </div>
                <div className="col-6 text-sm text-center p-1">
                  <button
                    className="btn btn-dark btn-primary text-sm w-100"
                    onClick={() => {
                      if (canCashReturn || master_password_verified) {
                        dispatch(
                          AddNewCreditNote({
                            creditNote: {
                              ...data,
                              credit_type: "cash",
                            },
                            setRefundItems: setRefundItems,
                          })
                        );
                        setModal(false);
                      } else {
                        setMasterPasswordModal(true);
                      }
                    }}
                  >
                    Cash Return
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {masterPasswordModal && (
        <MasterPasswordModal
          modal={masterPasswordModal}
          setModal={setMasterPasswordModal}
          module={"Cash Return"}
          store_id={orderDetails.store}
          extra_id={orderDetails.id}
          module_name="Manage Returns/Refunds"
          sub_module_id={findSubModuleId}
          permission={"authorize"}
          onSuccess={() => {
            dispatch(
              AddNewCreditNote({
                creditNote: {
                  ...data,
                  credit_type: "cash",
                },
                setRefundItems: setRefundItems,
                extra: true,
              })
            );
            setModal(false);
          }}
        />
      )}
    </Modal>
  );
};

export { RefundTypeModal };
