import React, { useCallback, useRef } from "react";
import { Button, FontIcon } from "../core-components";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { BUTTON_CONSTANTS, debounce, SearchProps, useAppDispatch } from "utils";
import { updatePageNo, updatePages } from "store/commonSlice";
import { useSearchParams } from "react-router-dom";

/**
 * A search component that allows users to input a search query and perform a search.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.placeholder - The placeholder text for the search input.
 * @param {string} props.query - The current search query.
 * @param {Function} props.setQuery - A function to update the search query.
 * @param {string} props.prepend - The text to prepend to the search input.
 * @param {string} props.append - The text to append to the search input.
 * @param {Function} props.searchByQuery - A function to perform a search using the query.
 * @param {string} props.className - Additional CSS class names for the component.
 * @returns {JSX.Element} The search component.
 */
const Search = ({
  placeholder,
  type = "search",
  query,
  setQuery,
  prepend,
  append,
  searchByQuery,
  className,
}: SearchProps) => {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const setInputValue = () => {
    dispatch(updatePages(1));
    setSearchParams({ page: "1" });
    if (searchByQuery) {
      searchByQuery(inputRef.current?.value);
    } else {
      setQuery(inputRef.current?.value);
    }
  };

  const optimizeSearch = useCallback(debounce(setInputValue), [
    inputRef.current?.value,
  ]);

  return (
    <div className={`input-group`}>
      {prepend && (
        <div className="input-group-prepend">
          <div className="input-group-text">{prepend}</div>
        </div>
      )}
      <input
        type={type}
        className="form-control"
        placeholder={placeholder}
        ref={inputRef}
        onChange={() => {
          optimizeSearch();
        }}
      />
      {append ? (
        <div className="input-group-append">
          <div className="input-group-text">{append}</div>
        </div>
      ) : (
        <div className="input-group-append z-0">
          <Button
            text={<FontIcon icon={faSearch} />}
            type={BUTTON_CONSTANTS.BUTTON}
            btnClassNames="btn btn-default"
            onClickHandler={(e: any) => {
              optimizeSearch();
            }}
          />
        </div>
      )}
    </div>
  );
};

export { Search };
