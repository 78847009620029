import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  ContentHeader,
  FontIcon,
  IconButton,
  Loader,
  Table,
  TableBody,
  TableHeader,
  Tabs,
} from "components";
import React, { useEffect, useState } from "react";
import {
  BUTTON_CONSTANTS,
  capitalizeFirstLetter,
  displayValue,
  formatDateTime,
  GENERAL_CONSTANTS,
  IOrderAddress,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  renderTheme,
  STATUSES,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
} from "utils";
import {
  faBasketShopping,
  faCartShopping,
  faClockRotateLeft,
  faFileInvoiceDollar,
  faLocationDot,
  faMoneyBill,
  faPenToSquare,
  faSheetPlastic,
  faTruckFast,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import {
  fetchEcommerceOrders,
  printEcommerceOrder,
  printShippingLabel,
  setOrderToEdit,
} from "store/Ecommerce";
import { UpdateOrderOffCanvas } from "./UpdateOrderOffCanvas";
import { PaymentDetailsOffCanvas } from "./PaymentDetailsOffCanvas";

const OrderDetails = () => {
  const dispatch = useAppDispatch();

  const {
    common: { status: commonStatus, portrait },
  } = useAppSelector((state) => state.root);

  const {
    order: { status: orderStatus, orderToEdit },
  } = useAppSelector((state) => state.ecommerce);

  const { id } = useParams();

  const tabs = [
    { label: "Order Items", icon: faBasketShopping },
    { label: "Order History", icon: faClockRotateLeft },
    { label: "Payment History", icon: faMoneyBill },
  ];

  const [updateOrder, setUpdateOrder] = useState(false);

  const [paymentDetails, setPaymentDetails] = useState({
    state: false,
    id: "",
  });

  const shippingAddress = orderToEdit?.addresses?.find(
    (address: IOrderAddress) => address.address_type === "shipping"
  );

  const billingAddress = orderToEdit?.addresses?.find(
    (address: IOrderAddress) => address.address_type === "billing"
  );

  const [activeTab, setActiveTab] = useState<string>(tabs[0].label);

  const orderItemsCols = [
    "#",
    "Item",
    "Quantity",
    "Tax",
    "Unit Price",
    "Price",
    // "Status",
    // "Action",
  ];

  const paymentHistoryCols = [
    "#",
    "RZP Order Id",
    "RZP Payment Id",
    "Payment Mode",
    "Status",
    "Date",
    "Amount Paid",
    "Actions",
  ];

  const orderHistoryCols = [
    "#",
    "Date",
    "Status",
    "Notes",
    "Shipping Carrier",
    "Shipping Date",
  ];

  const renderStatus = (status) => {
    return (
      <Badge
        badgeSize="md mt-1"
        badgeType={renderTheme(status)}
        badgeText={status?.toLocaleUpperCase()}
      />
    );
  };

  const displayOrderItems = () => {
    return orderToEdit?.ordered_products?.length > 0
      ? orderToEdit?.ordered_products?.map((orderItem, index) => {
          return (
            <tr key={index}>
              <td width={"5%"}>{index + 1}</td>
              <td width={"35%"}>
                <div className="d-flex flex-column flex-md-row">
                  <div className="col-md-2">
                    <img
                      src={orderItem?.product?.thumbnails?.[0]?.img}
                      alt={orderItem.product.title}
                      className="img-responsive img-thumbnail img-fluid h-100 w-100"
                      width={250}
                      height={250}
                    />
                  </div>
                  <div className="col-md-8 d-flex flex-column">
                    <div className="d-flex flex-row gap-2">
                      <h6 className="text-wrap">{orderItem?.product?.title}</h6>
                    </div>
                    <div className="d-flex flex-row gap-2 text-sm">
                      <span className="text-muted">Code</span>
                      <span>{orderItem?.product.product_code}</span>
                    </div>
                    <div className="d-flex flex-row gap-2 text-sm">
                      <span className="text-muted">Weight</span>
                      <span>{`${orderItem?.product?.selected_weight} ${orderItem?.product?.uom}`}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td width={"15%"}>{orderItem?.quantity}</td>
              <td width={"15%"}>
                <h6>
                  {`${POS_CONSTANTS.RUPEE_SIGN}${displayValue(
                    to2Decimal(orderItem?.total_tax)
                  )}`}
                </h6>
              </td>
              <td width={"15%"}>
                <h6>
                  {`${POS_CONSTANTS.RUPEE_SIGN}${displayValue(
                    to2Decimal(orderItem?.unit_price)
                  )}`}
                </h6>
              </td>
              <td width={"15%"}>
                <h6>
                  {`${POS_CONSTANTS.RUPEE_SIGN}${displayValue(
                    to2Decimal(orderItem?.payable_amount)
                  )}`}
                </h6>
              </td>
              {/* <td width={"10%"}>{renderStatus("pending")}</td>
              <td width={"5%"}>
                <Button
                  text={<FontIcon icon={faPenToSquare} />}
                  // isDisabled={findReturnProduct(orderItem)?.quantity === 0}
                  btnClassNames={"btn btn-info btn-sm"}
                  type="button"
                  onClickHandler={() => {}}
                />
              </td> */}
            </tr>
          );
        })
      : null;
  };

  const displayOrderSummary = () => {
    return (
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center mb-4">
          <div className="col text-sm text-center mt-4">
            <h6>
              {`${displayValue(
                to2Decimal(orderToEdit?.shipping_log?.actual_weight || 0)
              )} Kg`}
            </h6>
            <h6 className="text-muted text-sm ">
              {POS_CART_CONSTANTS.TOTAL_WEIGHT}
            </h6>
          </div>
          <div className="col text-sm text-center mt-4">
            <h6>
              {`${displayValue(
                to2Decimal(orderToEdit?.shipping_log?.volumetric_weight || 0)
              )} Kg`}
            </h6>
            <h6 className="text-muted text-sm ">
              {POS_CART_CONSTANTS.TOTAL_VOLUMETRIC_WEIGHT}
            </h6>
          </div>
          <div className="col text-sm text-center mt-4">
            <h6>
              {POS_CONSTANTS.RUPEE_SIGN}
              {displayValue(to2Decimal(orderToEdit?.total_shipping))}
            </h6>
            <h6 className="text-muted text-sm ">
              {POS_CART_CONSTANTS.TOTAL_SHIPPING_LABEL}
            </h6>
          </div>
        </div>
        <div className="d-flex align-items-center mb-4">
          <div className="col text-sm text-center mt-4">
            <h6>{displayValue(to2Decimal(orderToEdit?.total_quantity))}</h6>
            <h6 className="text-muted text-sm ">
              {POS_CART_CONSTANTS.TOTAL_ITEMS_LABEL}
            </h6>
          </div>
          <div className="col text-sm text-center mt-4">
            <h6>
              {POS_CONSTANTS.RUPEE_SIGN}
              {displayValue(to2Decimal(orderToEdit?.bill_amount))}
            </h6>
            <h6 className="text-muted text-sm">
              {POS_CART_CONSTANTS.TOTAL_MRP_LABEL}
            </h6>
          </div>
          {orderToEdit?.total_discount > 0 ? (
            <div className="col text-sm text-center mt-4">
              <h6>
                {`${GENERAL_CONSTANTS.FLAT_SYMBOL}${displayValue(
                  to2Decimal(orderToEdit?.total_discount)
                )}`}
              </h6>
              <h6 className="text-muted text-sm ">
                {POS_CART_CONSTANTS.TOTAL_DISCOUNT_LABEL}
              </h6>
            </div>
          ) : (
            <div className="col text-sm text-center mt-4">
              <h6>{POS_CART_CONSTANTS.NO_DISCOUNT_LABEL}</h6>
              <h6 className="text-muted text-sm ">
                {POS_CART_CONSTANTS.TOTAL_DISCOUNT_LABEL}
              </h6>
            </div>
          )}
          <div className="col text-sm text-center mt-4">
            <h6>
              {POS_CONSTANTS.RUPEE_SIGN}
              {displayValue(to2Decimal(orderToEdit?.total_tax))}
            </h6>
            <h6 className="text-muted text-sm ">
              {POS_CART_CONSTANTS.TAX_AMOUNT_LABEL}
            </h6>
          </div>
          {orderToEdit?.roundoff !== 0 && (
            <div className="col text-sm text-center mt-4">
              <h6>
                {orderToEdit?.roundoff
                  ? `${POS_CONSTANTS.RUPEE_SIGN}
          ${displayValue(to2Decimal(orderToEdit?.roundoff))}`
                  : "-"}
              </h6>
              <h6 className="text-muted text-sm ">
                {POS_CART_CONSTANTS.ROUND_OFF_LABEL}
              </h6>
            </div>
          )}
          <div className="col text-sm text-center mt-4">
            <h6>
              {POS_CONSTANTS.RUPEE_SIGN}
              {displayValue(to2Decimal(orderToEdit?.payable_amount))}
            </h6>
            <h6 className="text-muted text-sm ">
              {POS_CART_CONSTANTS.NET_AMOUNT_LABEL}
            </h6>
          </div>
        </div>
      </div>
    );
  };

  const displayPaymentItems = () => {
    return orderToEdit?.payment_data?.length > 0
      ? orderToEdit?.payment_data?.map((paymentItem, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{paymentItem?.payment_gateway_order}</td>
              <td>{paymentItem?.payment_gateway_id || "N/A"}</td>
              <td>
                {capitalizeFirstLetter(
                  paymentItem?.payment_mode?.payment_mode || "N/A"
                )}
              </td>
              <td>
                <Badge
                  badgeSize="md mt-1"
                  badgeType={renderTheme(paymentItem.payment_status)}
                  badgeText={(
                    paymentItem?.payment_status || "Failed"
                  )?.toLocaleUpperCase()}
                />
              </td>
              <td>{formatDateTime(paymentItem?.updated_at)}</td>
              <td>
                {`${POS_CONSTANTS.RUPEE_SIGN} ${displayValue(
                  to2Decimal(paymentItem?.payment_amount || 0)
                )}`}
              </td>
              <td>
                {paymentItem?.payment_gateway_id ? (
                  <Button
                    text={"Show Details"}
                    type={BUTTON_CONSTANTS.BUTTON}
                    btnClassNames="btn btn-dark btn-sm"
                    onClickHandler={() => {
                      setPaymentDetails({
                        state: true,
                        id: paymentItem?.payment_gateway_id?.toString(),
                      });
                    }}
                  />
                ) : (
                  "N/A"
                )}
              </td>
            </tr>
          );
        })
      : null;
  };

  const displayOrderHistoryItems = () => {
    return orderToEdit?.order_history?.length > 0
      ? orderToEdit?.order_history?.map((historyItem, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{formatDateTime(historyItem?.created_at)}</td>
              <td>{renderStatus(historyItem?.order_status)}</td>
              <td>
                {capitalizeFirstLetter(historyItem?.order_notes || "N/A")}
              </td>
              <td>{historyItem?.shipping_carrier || "N/A"}</td>
              <td>{historyItem?.shipping_date || "N/A"}</td>
            </tr>
          );
        })
      : null;
  };

  const renderTabs = () => {
    switch (activeTab) {
      case tabs[0]?.label:
        return (
          orderToEdit &&
          orderToEdit?.ordered_products?.length > 0 && (
            <>
              <TableHeader
                cols={orderItemsCols}
                tableHeaderClassNames="table-light"
              />
              <TableBody>{displayOrderItems()}</TableBody>
            </>
          )
        );

      case tabs[1]?.label:
        return (
          orderToEdit &&
          orderToEdit?.order_history?.length > 0 && (
            <>
              <TableHeader
                cols={orderHistoryCols}
                tableHeaderClassNames="table-light"
              />
              <TableBody>{displayOrderHistoryItems()}</TableBody>
            </>
          )
        );

      case tabs[2]?.label:
        return (
          <>
            <TableHeader
              cols={paymentHistoryCols}
              tableHeaderClassNames="table-light"
            />
            <TableBody>
              {orderToEdit &&
                orderToEdit?.payment_data?.length > 0 &&
                displayPaymentItems()}
            </TableBody>
          </>
        );
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(
        fetchEcommerceOrders({
          id: Number(id),
        })
      );
    }

    return () => {
      dispatch(setOrderToEdit(null));
    };
  }, [dispatch]);

  return (
    <div className="content-wrapper text-sm text-">
      <Container>
        <ContentHeader
          pageHeader={
            <div className="d-flex flex-column px-0">
              <div className="d-flex flex-row gap-2 align-items-start">
                {orderToEdit?.invoice_code && (
                  <h4 className="text-dark">#{orderToEdit?.invoice_code}</h4>
                )}
                <span className="ml-2">
                  {renderStatus(orderToEdit?.order_status)}
                </span>
              </div>
              <div className="d-flex flex-row gap-2 text-sm">
                <h6 className="text-dark">
                  {formatDateTime(orderToEdit?.created_at?.toString())}
                </h6>
              </div>
            </div>
          }
          editValue={orderToEdit?.invoice_code}
        />
        {orderStatus === STATUSES.LOADING && (
          <div className="d-flex justify-content-center">
            <Loader />
          </div>
        )}
        <div className="col-12 d-flex flex-column">
          <div
            className={`${
              portrait ? "order-2" : "order-0"
            } d-flex flex-column flex-md-row container-fluid gap-2`}
          >
            <Card cardClassNames="col">
              <CardHeader>
                <h4 className="card-title d-flex flex-row align-items-center">
                  <FontIcon icon={faUser} />
                  <span className="ml-2">Customer Details</span>
                </h4>
              </CardHeader>
              {Object.keys(orderToEdit || {})?.length > 0 && (
                <CardBody bodyClassNames="py-0">
                  <Table tableClassNames="table-borderless">
                    <tr>
                      <th>Name:</th>
                      <td>{`${orderToEdit?.customer?.name}`}</td>
                    </tr>
                    <tr>
                      <th>Contact Number:</th>
                      <td>{orderToEdit?.customer?.contact_number}</td>
                    </tr>
                    <tr>
                      <th>Email:</th>
                      <td>{orderToEdit?.customer?.email}</td>
                    </tr>
                  </Table>
                </CardBody>
              )}
            </Card>
            <Card cardClassNames="col">
              <CardHeader>
                <h4 className="card-title d-flex flex-row align-items-center">
                  <FontIcon icon={faLocationDot} />
                  <span className="ml-2">Shipping Details</span>
                </h4>
              </CardHeader>
              {Object.keys(shippingAddress || {})?.length > 0 && (
                <CardBody bodyClassNames="py-0">
                  <Table tableClassNames="table-borderless">
                    <tr>
                      <th>Name:</th>
                      <td>{`${shippingAddress?.name}`}</td>
                    </tr>
                    <tr>
                      <th>Contact Number:</th>
                      <td>{shippingAddress?.phone_number}</td>
                    </tr>
                    <tr>
                      <th>Address:</th>
                      <td className="text-wrap">{`${shippingAddress?.address_line_1}, ${shippingAddress?.address_line_2}, ${shippingAddress?.city?.city}, ${shippingAddress?.state?.state}, ${shippingAddress?.country?.country}, ${shippingAddress?.postal_code}`}</td>
                    </tr>
                  </Table>
                </CardBody>
              )}
            </Card>
            <Card cardClassNames="col">
              <CardHeader>
                <h4 className="card-title d-flex flex-row align-items-center">
                  <FontIcon icon={faLocationDot} />
                  <span className="ml-2">Billing Details</span>
                </h4>
              </CardHeader>
              {Object.keys(billingAddress || {})?.length > 0 && (
                <CardBody bodyClassNames="py-0">
                  <Table tableClassNames="table-borderless">
                    <tr>
                      <th>Name:</th>
                      <td>{billingAddress?.name}</td>
                    </tr>
                    <tr>
                      <th>Contact Number:</th>
                      <td>{billingAddress?.phone_number}</td>
                    </tr>
                    <tr>
                      <th>Address:</th>
                      <td className="text-wrap">{`${billingAddress?.address_line_1}, ${billingAddress?.address_line_2}, ${billingAddress?.city?.city}, ${billingAddress?.state?.state}, ${billingAddress?.country?.country}, ${billingAddress?.postal_code}`}</td>
                    </tr>
                  </Table>
                </CardBody>
              )}
            </Card>
          </div>
          <div
            className={`${
              portrait ? "order-0" : "order-1"
            } d-flex flex-row container-fluid`}
          >
            <Card cardClassNames="col-12">
              <CardHeader>
                <h4 className="card-title d-flex flex-row align-items-center">
                  <FontIcon icon={faCartShopping} />
                  <span className="ml-2">Order Actions</span>
                </h4>
              </CardHeader>
              <CardBody>
                <div className="d-flex flex-column flex-md-row gap-2">
                  <IconButton
                    icon={faFileInvoiceDollar}
                    btnClassNames="btn btn-dark m-1 col"
                    loading={
                      commonStatus.state === STATUSES.LOADING &&
                      commonStatus.type === "print-bill"
                    }
                    isDisabled={
                      !orderToEdit?.invoice_code ||
                      (commonStatus.state === STATUSES.LOADING &&
                        commonStatus.type === "print-bill")
                    }
                    onClickHandler={() =>
                      dispatch(
                        printEcommerceOrder({ bill_id: orderToEdit?.id })
                      )
                    }
                    btnText={"Invoice"}
                  />
                  <IconButton
                    icon={faTruckFast}
                    btnClassNames="btn btn-dark m-1 col"
                    loading={
                      commonStatus.state === STATUSES.LOADING &&
                      commonStatus.type === "print-label"
                    }
                    isDisabled={
                      commonStatus.state === STATUSES.LOADING &&
                      commonStatus.type === "print-label"
                    }
                    onClickHandler={() =>
                      dispatch(printShippingLabel({ bill_id: orderToEdit?.id }))
                    }
                    btnText={"Shipping Label"}
                  />
                  {/* <IconButton
                    icon={faSheetPlastic}
                    btnClassNames="btn btn-dark m-1 col"
                    onClickHandler={() => {}}
                    btnText={"Job Sheet"}
                  /> */}

                  <IconButton
                    icon={faPenToSquare}
                    btnClassNames="btn btn-dark m-1 col"
                    onClickHandler={() => setUpdateOrder(true)}
                    btnText={"Update Order"}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
          <div
            className={`${
              portrait ? "order-1" : "order-2"
            } d-flex flex-row container-fluid`}
          >
            <Card cardClassNames="col-12">
              <CardHeader>
                <Tabs
                  tabs={tabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  className="text-md"
                />
              </CardHeader>
              <CardBody bodyClassNames="py-0">
                <Table tableClassNames="table-light">{renderTabs()}</Table>
              </CardBody>
              {Object.keys(orderToEdit || {})?.length > 0 &&
                activeTab === tabs[0]?.label && (
                  <CardFooter>{displayOrderSummary()}</CardFooter>
                )}
            </Card>
          </div>
          {Object.keys(orderToEdit || {})?.length > 0 && (
            <UpdateOrderOffCanvas show={updateOrder} setShow={setUpdateOrder} />
          )}
          {Object.keys(orderToEdit || {})?.length > 0 && (
            <PaymentDetailsOffCanvas
              show={paymentDetails}
              setShow={setPaymentDetails}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export { OrderDetails as Ecommerce_OrderDetails };
