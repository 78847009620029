import { AddressAccordion } from "components/group-components/AddressAccordion";
import { ConfirmModal } from "components/ConfirmModal";
import { CustomInput } from "components/group-components/CustomInput";
import {
  Modal,
  CardBody,
  Card,
  MultiSelect,
  Button,
} from "components/core-components";
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { AddNewCreditNote } from "store/POS";
import {
  ADVANCE_ORDERS_CONSTANTS,
  AdvanceOrderSchema,
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  POS_CART_CONSTANTS,
  POS_MODAL_CONSTANTS,
  STATUSES,
  TOAST_CONSTANTS,
  Toast,
  calculateAllValues,
  formatDate,
  formatTime,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
} from "utils";

interface IProps {
  focusRef: React.MutableRefObject<any>;
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdvanceOrderModal = ({ focusRef, modal, setModal }: IProps) => {
  const dispatch = useAppDispatch();

  const {
    branch: { selectedStore },
    cart: { cart },
    creditNote: { status: creditNoteStatus },
    customer: { customerToEdit: customer_details },
  } = useAppSelector((state) => state.root);

  const { total_quantity, final_amount_to_pay } = calculateAllValues(cart);

  const [addressFilled, setAddressFilled] = useState(false);

  const [addressConfirmed, setAddressConfirmed] = useState(false);

  const PropsRef = React.useRef<FormikProps<any>>();

  // Place Advance Order
  const placeAdvanceOrder = () => {
    const advanceOrder = {
      total_quantity: total_quantity,
      refund_amount: Number(PropsRef?.current?.values.advance_amount),
      credit_type: ADVANCE_ORDERS_CONSTANTS.CREDIT_NOTE_TYPE,
      products: cart.cartItems.map((item) => ({
        product_group_id:
          item.productGroup && item.productGroup.id > 0
            ? item.productGroup.id
            : null,
        product_id: item.id,
        batch_id: item.batch_id,
        total_amount: to2Decimal(item.total_amount),
        quantity: item.quantity,
      })),
      delivery_type: PropsRef?.current?.values.delivery_type[0].value,
      delivery_time: PropsRef?.current?.values.delivery_time,
      delivery_date: PropsRef?.current?.values.delivery_date,
      addresses:
        PropsRef?.current?.values.delivery_type[0].value ===
        POS_CART_CONSTANTS.DELIVERY
          ? [
              {
                address_line_1:
                  PropsRef?.current?.values.address.address_line_1,
                address_line_2:
                  PropsRef?.current?.values.address.address_line_2,
                city: PropsRef?.current?.values.address.city[0].value,
                state: PropsRef?.current?.values.address.state[0].value,
                country: PropsRef?.current?.values.address.country[0].value,
                pincode: PropsRef?.current?.values.address.pincode,
              },
            ]
          : GENERAL_CONSTANTS.EMPTY_ARRAY,
      store: selectedStore,
      validity: 365,
      customer: customer_details.id,
      remarks: PropsRef?.current?.values.remarks,
      payments: GENERAL_CONSTANTS.EMPTY_ARRAY,
    };
    dispatch(
      AddNewCreditNote({
        focusRef: focusRef,
        creditNote: advanceOrder,
        actions: PropsRef.current,
        setModal: setModal,
      })
    );
  };

  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    // Check if delivery type is delivery
    if (values.delivery_type[0].value === POS_CART_CONSTANTS.DELIVERY) {
      // Check if address is filled
      if (
        values.address.address_line_1 !== GENERAL_CONSTANTS.EMPTY_STRING ||
        values.address.address_line_2 !== GENERAL_CONSTANTS.EMPTY_STRING
      ) {
        // If delivery type is delivery and address is filled then place order
        placeAdvanceOrder();
      } else {
        // If delivery type is delivery and address is not filled then show confirm modal to ask if user wants to proceed without address
        setAddressConfirmed(true);
      }
    } else {
      placeAdvanceOrder();
    }
  };

  useEffect(() => {
    // Check if address is filled
    setAddressFilled(
      customer_details?.address?.address_line_1 !==
        GENERAL_CONSTANTS.EMPTY_STRING &&
        customer_details?.address?.address_line_1 !== undefined &&
        customer_details?.address?.address_line_2 !==
          GENERAL_CONSTANTS.EMPTY_STRING &&
        customer_details?.address?.address_line_2 !== undefined
    );
    return () => {
      setAddressFilled(false);
    };
  }, []);

  return (
    <Modal
      modalClassNames="w-100"
      modalSize="lg"
      title={POS_MODAL_CONSTANTS.ADVANCE_ORDER}
      showModal={modal}
      onClickHandler={() => {
        setModal(GENERAL_CONSTANTS.FALSE);
        PropsRef.current?.resetForm();
      }}
    >
      <div className="row">
        <Formik
          initialValues={{
            advance_amount: GENERAL_CONSTANTS.EMPTY_STRING,
            delivery_date: formatDate(),
            delivery_time: formatTime(new Date()),
            delivery_type: [
              {
                label: POS_CART_CONSTANTS.PICKUP_LABEL,
                value: POS_CART_CONSTANTS.PICKUP,
              },
            ],
            address: {
              address_line_1: customer_details.address
                ? customer_details.address?.address_line_1
                : GENERAL_CONSTANTS.EMPTY_STRING,
              address_line_2: customer_details.address
                ? customer_details.address?.address_line_2
                : GENERAL_CONSTANTS.EMPTY_STRING,
              city: customer_details.address
                ? [
                    {
                      label: customer_details.address?.city.city,
                      value: customer_details.address?.city.id,
                    },
                  ]
                : FORM_CONSTANTS.DEFAULT_CITY,
              state: customer_details.address
                ? [
                    {
                      label: customer_details.address?.state.state,
                      value: customer_details.address?.state.id,
                    },
                  ]
                : FORM_CONSTANTS.DEFAULT_STATE,
              country: customer_details.address
                ? [
                    {
                      label: customer_details.address?.country.country,
                      value: customer_details.address?.country.id,
                    },
                  ]
                : FORM_CONSTANTS.DEFAULT_COUNTRY,
              pincode: customer_details.address
                ? customer_details.address?.pincode
                : FORM_CONSTANTS.DEFAULT_PINCODE,
            },
            remarks: GENERAL_CONSTANTS.EMPTY_STRING,
          }}
          onSubmit={handleSubmit}
          validationSchema={AdvanceOrderSchema}
        >
          {(props) => {
            PropsRef.current = props;
            return (
              <Form>
                <div className="col-12">
                  <Card>
                    <CardBody>
                      <div className="row">
                        <div className="col-md-6">
                          <CustomInput
                            isRequired={true}
                            label={POS_CART_CONSTANTS.ADVANCE_AMOUNT_LABEL}
                            name={POS_CART_CONSTANTS.ADVANCE_AMOUNT}
                            type={FORM_CONSTANTS.NUMBER}
                            placeholder={
                              POS_CART_CONSTANTS.ADVANCE_AMOUNT_PLACEHOLDER
                            }
                            onChangeHandler={(e) => {
                              // Check if advance amount is greater than final amount to pay
                              if (
                                Number(e.target.value) >
                                final_amount_to_pay + cart.round_off
                              ) {
                                Toast(
                                  POS_CART_CONSTANTS.INCORRECT_ADVANCE_AMOUNT,
                                  TOAST_CONSTANTS.WARNING
                                );
                                props.setFieldValue(
                                  POS_CART_CONSTANTS.ADVANCE_AMOUNT,
                                  final_amount_to_pay + cart.round_off
                                );
                              } else {
                                props.setFieldValue(
                                  POS_CART_CONSTANTS.ADVANCE_AMOUNT,
                                  e.target.value
                                );
                              }
                            }}
                          />
                          <ErrorMessage
                            name={POS_CART_CONSTANTS.ADVANCE_AMOUNT}
                            component={FORM_CONSTANTS.ERROR_PARENT}
                            className={FORM_CONSTANTS.ERROR}
                          />
                        </div>
                        <div className="col-md-6">
                          <MultiSelect
                            select={GENERAL_CONSTANTS.FALSE}
                            isRequired={true}
                            label={POS_CART_CONSTANTS.DELIVERY_TYPE_LABEL}
                            name={POS_CART_CONSTANTS.DELIVERY_TYPE}
                            options={POS_CART_CONSTANTS.DELIVERY_TYPE_OPTIONS}
                            // defaultValue={deliveryOptions[0]}
                          />
                          <ErrorMessage
                            name={POS_CART_CONSTANTS.DELIVERY_TYPE}
                            component={FORM_CONSTANTS.ERROR_PARENT}
                            className={FORM_CONSTANTS.ERROR}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <CustomInput
                            label={POS_CART_CONSTANTS.DELIVERY_DATE_LABEL}
                            name={POS_CART_CONSTANTS.DELIVERY_DATE}
                            type={FORM_CONSTANTS.DATE}
                            isRequired={true}
                            min={new Date().toISOString().split("T")[0]}
                            placeholder={
                              POS_CART_CONSTANTS.DELIVERY_DATE_PLACEHOLDER
                            }
                          />
                          <ErrorMessage
                            name={POS_CART_CONSTANTS.DELIVERY_DATE}
                            component={FORM_CONSTANTS.ERROR_PARENT}
                            className={FORM_CONSTANTS.ERROR}
                          />
                        </div>
                        <div className="col-md-6">
                          <CustomInput
                            label={POS_CART_CONSTANTS.DELIVERY_TIME_LABEL}
                            name={POS_CART_CONSTANTS.DELIVERY_TIME}
                            type={FORM_CONSTANTS.TIME}
                            placeholder={
                              POS_CART_CONSTANTS.DELIVERY_TIME_PLACEHOLDER
                            }
                          />
                        </div>
                        {props.values?.delivery_type[0]?.value ===
                          POS_CART_CONSTANTS.DELIVERY && (
                          <div className="col-12 mb-3">
                            <AddressAccordion
                              props={props}
                              title={POS_CART_CONSTANTS.DELIVERY_ADDRESS_TITLE}
                              addressFilled={addressFilled}
                              setAddressFilled={setAddressFilled}
                            />
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <CustomInput
                            maxLength={120}
                            label={POS_CART_CONSTANTS.REMARKS_LABEL}
                            name={POS_CART_CONSTANTS.REMARKS}
                            type={FORM_CONSTANTS.TEXTAREA}
                            placeholder={POS_CART_CONSTANTS.REMARKS_PLACEHOLDER}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-12">
                  <Button
                    type={BUTTON_CONSTANTS.BUTTON}
                    text={GENERAL_CONSTANTS.PROCEED}
                    loading={creditNoteStatus === STATUSES.LOADING}
                    btnClassNames="btn btn-primary"
                    onClickHandler={() => props.submitForm()}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <ConfirmModal
        modal={addressConfirmed}
        setModal={setAddressConfirmed}
        message={POS_CART_CONSTANTS.WITHOUT_ADDRESS_MESSAGE}
        title={GENERAL_CONSTANTS.EMPTY_STRING}
        confirmClick={() => {
          placeAdvanceOrder();
          setAddressConfirmed(GENERAL_CONSTANTS.FALSE);
        }}
        rejectClick={() => {
          setAddressConfirmed(GENERAL_CONSTANTS.FALSE);
        }}
      />
    </Modal>
  );
};

export { AdvanceOrderModal };
