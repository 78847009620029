import React, { useMemo } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  MultiSelect,
  TextArea,
} from "components";

import {
  ACCESS_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  PRODUCTS_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";

import { ErrorMessage } from "formik";

import { getCategories } from "../../store/ManageProducts/productCategorySlice";
import { getSubCategories } from "store/ManageProducts/productSubCategorySlice";
import { getHSN } from "store/hsnCodesSlice";
import { getUOMs } from "store/uomSlice";
import { getBrands } from "store/ManageProducts/productBrandSlice";
import { getTaxes } from "store/ManageProducts/productTaxSlice";
import { useParams } from "react-router-dom";
import { getBarcodeTypes } from "store/ManageProducts/barcodeSlice";
import { fetchTastes } from "store/ManageProducts/tasteSlice";

const ProductDetailsForm = ({
  props,
  actionsRef,
  setDeleteField,
  setConfirmModal,
}: any) => {
  const dispatch = useAppDispatch();

  const {
    barcode: { barcodeTypes: barCodeTypeData, status: barcodeStatus },
    productCategory: { productCategory, status: categoryStatus },
    productSubCategory: { productSubCategory, status: subCategoryStatus },
    hsnCode: { hsnCodes, status: hsnCodeStatus },
    uom: { uom, status: uomStatus },
    productBrand: { productBrand, status: brandStatus },
    taste: { tastes, status: tasteStatus },
    // productIngredient: { productIngredients, status: ingredientStatus },
    productTax: { productTax, status: taxStatus },
  } = useAppSelector((state) => state.root);

  const { id } = useParams();

  const { isAvaryaBarcode } = useMemo(() => {
    const isAvaryaBarcode =
      props.values?.barcode_type?.[0]?.value &&
      props.values?.barcode_type?.[0]?.value !==
        PRODUCTS_CONSTANTS.UPC_BARCODE_ID;

    return { isAvaryaBarcode };
  }, [props.values?.barcode_type?.[0]?.value]);

  return (
    <div className="col-12">
      <Card>
        <CardHeader>
          <h5 className="card-title">{PRODUCTS_CONSTANTS.CARD_TITLE_1}</h5>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                label={PRODUCTS_CONSTANTS.PRODUCT_NAME_LABEL}
                isRequired={true}
                name={PRODUCTS_CONSTANTS.PRODUCT_NAME}
                type={FORM_CONSTANTS.TEXT}
                placeholder={PRODUCTS_CONSTANTS.PRODUCT_NAME_PLACEHOLDER}
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.PRODUCT_NAME}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label={PRODUCTS_CONSTANTS.PRODUCT_EXPIRATION_DAYS_LABEL}
                isRequired={
                  props.values.brand?.[0]?.value ===
                  ACCESS_CONSTANTS.AVARYA_BRAND_ID
                }
                name={PRODUCTS_CONSTANTS.PRODUCT_EXPIRATION_DAYS}
                type={FORM_CONSTANTS.NUMBER}
                placeholder={
                  PRODUCTS_CONSTANTS.PRODUCT_EXPIRATION_DAYS_PLACEHOLDER
                }
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.PRODUCT_EXPIRATION_DAYS}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <MultiSelect
                label={PRODUCTS_CONSTANTS.BARCODE_TYPE_LABEL}
                select={false}
                isRequired={true}
                disabled={id !== undefined}
                name={PRODUCTS_CONSTANTS.BARCODE_TYPE}
                onFocusHandler={() => {
                  barCodeTypeData.length === 0 &&
                    dispatch(getBarcodeTypes(true));
                }}
                options={
                  barCodeTypeData && barCodeTypeData?.length > 0
                    ? barCodeTypeData?.map((item) => ({
                        value: item.id,
                        label: item.barcode_type,
                      }))
                    : []
                }
                isLoading={barcodeStatus === STATUSES.LOADING}
                onChangeHandler={(e) => {
                  props.setFieldValue("product_code", "");
                  props.setFieldValue("barcode_type", [e]);
                }}
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.BARCODE_TYPE}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <MultiSelect
                select={false}
                label={PRODUCTS_CONSTANTS.PRODUCT_BRAND_LABEL}
                isRequired={true}
                name={PRODUCTS_CONSTANTS.PRODUCT_BRAND}
                onFocusHandler={() => {
                  productBrand.length === 0 && dispatch(getBrands(true));
                }}
                options={
                  productBrand && productBrand?.length > 0
                    ? productBrand?.map((item) => ({
                        value: item.id,
                        label: item.brand_name,
                      }))
                    : []
                }
                isLoading={brandStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.PRODUCT_BRAND}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className={`col-md-6${isAvaryaBarcode ? "" : ""}`}>
              <CustomInput
                label={`${PRODUCTS_CONSTANTS.PRODUCT_CODE_LABEL} 1`}
                isRequired={true}
                value={props.values?.new_product_code}
                isDisabled={isAvaryaBarcode}
                name={PRODUCTS_CONSTANTS.NEW_PRODUCT_CODE}
                type={FORM_CONSTANTS.TEXT}
                placeholder={
                  isAvaryaBarcode
                    ? PRODUCTS_CONSTANTS.PRODUCT_CODE_ALT_PLACEHOLDER
                    : PRODUCTS_CONSTANTS.PRODUCT_CODE_PLACEHOLDER
                }
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.NEW_PRODUCT_CODE}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label={`${PRODUCTS_CONSTANTS.NEW_PRODUCT_CODE_LABEL} 2`}
                value={props.values?.product_code}
                name={PRODUCTS_CONSTANTS.PRODUCT_CODE}
                type={FORM_CONSTANTS.TEXT}
                placeholder={PRODUCTS_CONSTANTS.NEW_PRODUCT_CODE_PLACEHOLDER}
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.PRODUCT_CODE}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                label={PRODUCTS_CONSTANTS.PRODUCT_NET_WEIGHT_LABEL}
                isRequired={true}
                step={0.001}
                name={PRODUCTS_CONSTANTS.PRODUCT_NET_WEIGHT}
                type={FORM_CONSTANTS.NUMBER}
                placeholder={PRODUCTS_CONSTANTS.PRODUCT_NET_WEIGHT_PLACEHOLDER}
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.PRODUCT_NET_WEIGHT}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <MultiSelect
                select={false}
                label={PRODUCTS_CONSTANTS.PRODUCT_UOM_LABEL}
                isRequired={true}
                name={PRODUCTS_CONSTANTS.PRODUCT_UOM}
                onFocusHandler={() => {
                  uom.length === 0 && dispatch(getUOMs(true));
                }}
                options={
                  uom && uom?.length > 0
                    ? uom?.map((item) => ({
                        value: item.id,
                        label: item.uom_name,
                      }))
                    : []
                }
                isLoading={uomStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.PRODUCT_UOM}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <MultiSelect
                select={false}
                name={"jain_friendly"}
                isRequired={true}
                label={"Jain Friendly"}
                placeholder="Select Jain Friendly"
                options={GENERAL_CONSTANTS.YES_NO_OPTIONS}
              />
              <ErrorMessage
                name="jain_friendly"
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <MultiSelect
                select={true}
                name="taste"
                isRequired={
                  props.values.brand?.[0]?.value ===
                  ACCESS_CONSTANTS.AVARYA_BRAND_ID
                }
                label="Taste"
                placeholder="Select Taste"
                options={tastes.map((taste) => ({
                  value: taste.id,
                  label: taste.taste_name,
                }))}
                onFocusHandler={() => {
                  dispatch(fetchTastes({ active: true }));
                }}
                isLoading={tasteStatus === STATUSES.LOADING}
                onChangeHandler={(e, actions) => {
                  if (
                    (id && actions.action == "remove-value") ||
                    actions.action == "pop-value" ||
                    actions.action == "clear"
                  ) {
                    actionsRef.current = actions;
                    setConfirmModal(true);
                    setDeleteField("taste");
                  } else {
                    const selectedOptions = Array.isArray(e) ? e : [e];
                    props.setFieldValue("taste", selectedOptions);
                  }
                }}
              />
              <ErrorMessage
                name="taste"
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <MultiSelect
                select={false}
                label={PRODUCTS_CONSTANTS.PRODUCT_CATEGORY_LABEL}
                isRequired={true}
                name={PRODUCTS_CONSTANTS.PRODUCT_CATEGORY}
                onFocusHandler={() => {
                  productCategory.length === 0 && dispatch(getCategories(true));
                }}
                options={
                  productCategory && productCategory?.length > 0
                    ? productCategory?.map((item) => ({
                        value: item.id,
                        label: item.category_name,
                      }))
                    : []
                }
                isLoading={categoryStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.PRODUCT_CATEGORY}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <MultiSelect
                select={false}
                label={PRODUCTS_CONSTANTS.PRODUCT_SUB_CATEGORY_LABEL}
                isRequired={true}
                name={PRODUCTS_CONSTANTS.PRODUCT_SUB_CATEGORY}
                onFocusHandler={() => {
                  productSubCategory.length === 0 &&
                    dispatch(getSubCategories(true));
                }}
                options={
                  productSubCategory && productSubCategory?.length > 0
                    ? productSubCategory?.map((item) => ({
                        value: item.id,
                        label: item.sub_category_name,
                      }))
                    : []
                }
                isLoading={subCategoryStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.PRODUCT_SUB_CATEGORY}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <MultiSelect
                select={false}
                label={PRODUCTS_CONSTANTS.PRODUCT_HSN_CODES_LABEL}
                isRequired={true}
                name={PRODUCTS_CONSTANTS.PRODUCT_HSN_CODES}
                onFocusHandler={() => {
                  hsnCodes.length === 0 && dispatch(getHSN(true));
                }}
                options={
                  hsnCodes && hsnCodes?.length > 0
                    ? hsnCodes?.map((item) => ({
                        value: item.id,
                        label: item.hsn_code,
                      }))
                    : []
                }
                isLoading={hsnCodeStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.PRODUCT_HSN_CODES}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <MultiSelect
                select={true}
                label={PRODUCTS_CONSTANTS.PRODUCT_TAX_LABEL}
                isRequired={true}
                name={PRODUCTS_CONSTANTS.PRODUCT_TAX}
                onFocusHandler={() => {
                  productTax.length === 0 && dispatch(getTaxes(true));
                }}
                options={
                  productTax && productTax?.length > 0
                    ? productTax?.map((item) => ({
                        value: item.id,
                        label: item.tax_name,
                      }))
                    : []
                }
                isLoading={taxStatus === STATUSES.LOADING}
                onChangeHandler={(e, actions) => {
                  if (
                    (id && actions.action == "remove-value") ||
                    actions.action == "pop-value" ||
                    actions.action == "clear"
                  ) {
                    actionsRef.current = actions;
                    setConfirmModal(true);
                    setDeleteField("tax");
                  } else {
                    const selectedOptions = Array.isArray(e) ? e : [e];
                    props.setFieldValue("tax", selectedOptions);
                  }
                }}
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.PRODUCT_TAX}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <MultiSelect
                select={false}
                label={PRODUCTS_CONSTANTS.PRODUCT_SELLING_UOM_LABEL}
                isRequired={true}
                name={PRODUCTS_CONSTANTS.PRODUCT_SELLING_UOM}
                onFocusHandler={() => {
                  uom.length === 0 && dispatch(getUOMs(true));
                }}
                options={
                  uom && uom?.length > 0
                    ? uom?.map((item) => ({
                        value: item.id,
                        label: item.uom_name,
                      }))
                    : []
                }
                isLoading={uomStatus === STATUSES.LOADING}
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.PRODUCT_SELLING_UOM}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            {id !== undefined && (
              <div className="col-md-6">
                <MultiSelect
                  select={false}
                  name={PRODUCTS_CONSTANTS.PRODUCT_STATUS}
                  label={PRODUCTS_CONSTANTS.PRODUCT_STATUS_LABEL}
                  options={PRODUCTS_CONSTANTS.PRODUCT_STATUS_OPTIONS}
                />
                <ErrorMessage
                  name={PRODUCTS_CONSTANTS.PRODUCT_STATUS}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-6">
              <TextArea
                id={PRODUCTS_CONSTANTS.PRODUCT_INGREDIENTS}
                rows={2}
                label={PRODUCTS_CONSTANTS.PRODUCT_INGREDIENTS_LABEL}
                // isRequired={true}
                name={PRODUCTS_CONSTANTS.PRODUCT_INGREDIENTS}
                placeholder={PRODUCTS_CONSTANTS.PRODUCT_INGREDIENTS_PLACEHOLDER}
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.PRODUCT_INGREDIENTS}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
            <div className="col-md-6">
              <TextArea
                id={PRODUCTS_CONSTANTS.PRODUCT_DESCRIPTION}
                label={PRODUCTS_CONSTANTS.PRODUCT_DESCRIPTION_LABEL}
                name={PRODUCTS_CONSTANTS.PRODUCT_DESCRIPTION}
                placeholder={PRODUCTS_CONSTANTS.PRODUCT_DESCRIPTION_PLACEHOLDER}
                rows={2}
              />
              <ErrorMessage
                name={PRODUCTS_CONSTANTS.PRODUCT_DESCRIPTION}
                component={FORM_CONSTANTS.ERROR_PARENT}
                className={FORM_CONSTANTS.ERROR}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export { ProductDetailsForm };
