import {
  faArrowRight,
  faCircleNotch,
  faEllipsisVertical,
  faEye,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  ContentHeader,
  CustomInput,
  Dropdown,
  FontIcon,
  Loader,
  MultiSelect,
  NoData,
  Pagination,
  Search,
  SelectStoreModal,
  Table,
  TableBody,
  TableHeader,
} from "components";
import { Form, Formik, FormikProps } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  clearSelectedStore,
  getBranches,
} from "store/ManageBranches/branchSlice";
import {
  FetchAllPaymentTypes,
  FetchCurrentOrderDetails,
  FilterOrder,
  PrintLastBill,
  resetOrderState,
  setCurrentOrder,
} from "store/POS";
import { updatePageNo } from "store/commonSlice";
import { POS_CONSTANTS } from "../../../utils/constants";
import {
  ROUTES,
  ORDER_CONSTANTS,
  BUTTON_CONSTANTS,
  POS_HEADER_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  POS_CART_CONSTANTS,
  TOAST_CONSTANTS,
  IGetOrder,
  STATUSES,
  ScrollToTop,
  Toast,
  formatDateTime,
  useAppDispatch,
  useAppSelector,
  usePagination,
  displayValue,
  to2Decimal,
} from "utils";
import moment from "moment";

interface IProps {
  modal: boolean;
}

const OrdersList = ({ modal }: IProps) => {
  const dispatch = useAppDispatch();

  const {
    auth: { permissions: permissionData, logged_in_user },
    branch: { branch: stores, selectedStore },
    order: { orders: orderData, status: orderStatus },
    posPaymentType: { payment_type },
    common: {
      current_page,
      total_items,
      total_pages,
      page_size,
      status: commonStatus,
    },
  } = useAppSelector((state) => state.root);

  const PropsRef = useRef<
    FormikProps<{
      status: {
        value: string;
        label: string;
      }[];
      payment_type: {
        value: number;
        label: string;
      }[];
      delivery_type: {
        value: string;
        label: string;
      }[];
      start_date: string;
      end_date: string;
    }>
  >();

  const [storeModal, setStoreModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const [query, setQuery] = useState("");

  const ordersPermission = permissionData.find(
    (p) => p.module_name === ORDER_CONSTANTS.PERMISSION_NAME
  );

  // store code for the selected store
  const store_code = selectedStore
    ? stores.find((store) => store.id === selectedStore).store_code
    : null;

  const update_page = useCallback(
    (
      pageNo: number,
      store = selectedStore,
      filterStatus: string = PropsRef.current?.values?.status?.[0]?.value,
      paymentType: any = PropsRef.current?.values?.payment_type?.[0]?.value,
      orderQuery = query,
      delivery_type: string = PropsRef.current?.values?.delivery_type?.[0]
        ?.value,
      start_Date: string = PropsRef.current?.values?.start_date,
      end_Date: string = PropsRef.current?.values?.end_date
    ) => {
      // default filter options
      const filterOptions = {
        pageNo,
        query: orderQuery,
        type: paymentType,
        store: selectedStore || store,
      };

      // if the start date and end date are not empty, add them to the filter options
      start_Date && (filterOptions[ORDER_CONSTANTS.START_DATE] = start_Date);
      end_Date && (filterOptions[ORDER_CONSTANTS.END_DATE] = end_Date);

      // if the payment type is 5 (pay later), add the delivery type to the filter options if it is not all
      paymentType === 5 &&
        delivery_type !== ORDER_CONSTANTS.ALL &&
        (filterOptions[ORDER_CONSTANTS.DELIVERY_TYPE] = delivery_type);

      // if the status is not all, add the status to the filter options
      filterStatus !== ORDER_CONSTANTS.ALL &&
        (filterOptions[ORDER_CONSTANTS.STATUS] = filterStatus);

      // if the payment type is not all, add the payment type to the filter options
      paymentType !== 0 &&
        (filterOptions[ORDER_CONSTANTS.PAYMENT_TYPE] = paymentType);

      if (orderQuery) {
        // if the order query is a store code, check if the store code is valid
        if (
          stores
            ?.map((item) => item?.store_code)
            ?.includes(orderQuery?.substring(0, 3))
        ) {
          // if the store code is valid, filter the orders by the store code
          if (orderQuery?.includes(store_code)) {
            dispatch(
              FilterOrder({
                ...filterOptions,
                query: "",
                code: orderQuery,
                navigate,
              })
            );
          } else {
            // if the store code is invalid, show an error toast
            Toast(
              POS_HEADER_CONSTANTS.INVALID_STORE_CODE_MESSAGE,
              TOAST_CONSTANTS.ERROR
            );
          }
        } else {
          // if the order query is not a store code, filter the orders by the query
          dispatch(
            FilterOrder({
              ...filterOptions,
              query: orderQuery,
            })
          );
        }
      } else if (selectedStore || store) {
        // if the store is selected, filter the orders by the store
        dispatch(FilterOrder(filterOptions));
      }
    },
    [PropsRef.current?.values, selectedStore]
  );

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query: query.trim(),
  });

  const getPaymentTypeOptions = useMemo(() => {
    return payment_type && payment_type.length > 0
      ? [
          { value: 0, label: ORDER_CONSTANTS.ALL_LABEL },
          ...payment_type.map((item) => ({
            value: item.id,
            label: item.payment_type,
          })),
        ]
      : [];
  }, [payment_type]);

  const handleDateChange = (value: string) => {
    const date = new Date();
    let startDate = "";
    let endDate = "";
    switch (value) {
      case "today":
        startDate = date.toISOString().split("T")[0];
        endDate = startDate;
        break;
      case "yesterday":
        date.setDate(date.getDate() - 1);
        startDate = date.toISOString().split("T")[0];
        endDate = startDate;
        break;
      case "current_week":
        date.setDate(date.getDate() - date.getDay());
        startDate = date.toISOString().split("T")[0];
        date.setDate(date.getDate() + 6);
        endDate = date.toISOString().split("T")[0];
        break;
      case "last_week":
        date.setDate(date.getDate() - date.getDay() - 7);
        startDate = date.toISOString().split("T")[0];
        date.setDate(date.getDate() + 6);
        endDate = date.toISOString().split("T")[0];
        break;
      case "current_month":
        startDate = new Date(date.getFullYear(), date.getMonth(), 1)
          .toISOString()
          .split("T")[0];
        endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
          .toISOString()
          .split("T")[0];
        break;
      case "last_month":
        startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1)
          .toISOString()
          .split("T")[0];
        endDate = new Date(date.getFullYear(), date.getMonth(), 0)
          .toISOString()
          .split("T")[0];
        break;
      default:
        break;
    }
    update_page(
      1,
      selectedStore,
      PropsRef.current?.values?.status?.[0]?.value,
      PropsRef.current?.values?.payment_type?.[0]?.value,
      query,
      PropsRef.current?.values?.delivery_type?.[0]?.value,
      startDate,
      endDate
    );
    PropsRef.current?.setFieldValue(ORDER_CONSTANTS.START_DATE, startDate);
    PropsRef.current?.setFieldValue(ORDER_CONSTANTS.END_DATE, endDate);
  };

  const [print, setPrint] = useState<number>();

  const location = useLocation();
  const pathName = location.pathname.split("/").splice(2);
  const isSales = pathName.includes("sales");

  const isMobile = window.mobileAndTabletCheck();

  const dropdownItems = [
    {
      path: `#`,
      icon: faArrowRight,
      label: POS_HEADER_CONSTANTS.CHANGE_STORE,
      dropdownClickHandler: () => {
        dispatch(clearSelectedStore());
        setStoreModal(true);
      },
      reverse: true,
    },
  ];

  useEffect(() => {
    if (orderData?.length === 0 && selectedStore) {
      update_page(
        1,
        selectedStore,
        PropsRef.current?.values?.status?.[0]?.value,
        PropsRef.current?.values?.payment_type?.[0]?.value,
        query,
        PropsRef.current?.values?.delivery_type?.[0]?.value,
        PropsRef.current?.values?.start_date,
        PropsRef.current?.values?.end_date
      );
    }
    ScrollToTop();
    dispatch(getBranches({ active: true, edit: { setStoreModal } }));
    dispatch(FetchAllPaymentTypes());
    return () => {
      dispatch(resetOrderState());
      resetState();
    };
  }, [dispatch, selectedStore]);

  return (
    <div className={`${!modal && isSales ? "content-wrapper " : ""}text-sm`}>
      {!selectedStore && (
        <SelectStoreModal
          modal={storeModal}
          setModal={setStoreModal}
          update={(value) => {
            // update the page with the selected store
            update_page(
              1,
              value,
              PropsRef.current?.values?.status?.[0]?.value,
              PropsRef.current?.values?.payment_type?.[0]?.value,
              query,
              PropsRef.current?.values?.delivery_type?.[0]?.value,
              PropsRef.current?.values?.start_date,
              PropsRef.current?.values?.end_date
            );
          }}
        />
      )}

      <div className="col-12 d-flex flex-column">
        <div className="row">
          <Container>
            {!modal && <ContentHeader pageHeader={"Orders"} />}
            <Card cardClassNames="p-0">
              <CardHeader>
                {/* Form for filters  */}
                <Formik
                  initialValues={
                    JSON.parse(
                      localStorage.getItem(ORDER_CONSTANTS.ORDER_FILTER)
                    ) !== null
                      ? JSON.parse(
                          localStorage.getItem(ORDER_CONSTANTS.ORDER_FILTER)
                        )
                      : {
                          status: [
                            {
                              value: ORDER_CONSTANTS.ALL,
                              label: ORDER_CONSTANTS.ALL_LABEL,
                            },
                          ],
                          payment_type: [
                            { value: 0, label: ORDER_CONSTANTS.ALL_LABEL },
                          ],
                          delivery_type: [
                            {
                              value: ORDER_CONSTANTS.ALL,
                              label: ORDER_CONSTANTS.ALL_LABEL,
                            },
                          ],
                          start_date: moment()
                            .subtract(1, "days")
                            .format("YYYY-MM-DD"),
                          end_date: moment().format("YYYY-MM-DD"),
                        }
                  }
                  onSubmit={() => {}}
                  enableReinitialize={true}
                >
                  {(props) => {
                    PropsRef.current = props;
                    return (
                      <Form>
                        <div className="d-flex flex-row px-0 container-fluid mb-2">
                          <div className={`col`}>
                            <CustomInput
                              label={ORDER_CONSTANTS.START_DATE_LABEL}
                              // isRequired={true}
                              name={ORDER_CONSTANTS.START_DATE}
                              type={FORM_CONSTANTS.DATE}
                              max={
                                props?.values?.end_date ||
                                new Date().toISOString().split("T")[0]
                              }
                              onChangeHandler={(e) => {
                                props.setFieldValue(
                                  ORDER_CONSTANTS.START_DATE,
                                  e.target.value
                                );
                                update_page(
                                  1,
                                  selectedStore,
                                  props.values.status[0].value,
                                  props.values.payment_type[0].value,
                                  query,
                                  props.values.delivery_type[0].value,
                                  e.target.value,
                                  props.values.end_date
                                );
                              }}
                            />
                          </div>
                          <div className={`col`}>
                            <CustomInput
                              label={ORDER_CONSTANTS.END_DATE_LABEL}
                              // isRequired={true}
                              name={ORDER_CONSTANTS.END_DATE}
                              type={FORM_CONSTANTS.DATE}
                              min={props?.values?.start_date}
                              max={new Date().toISOString().split("T")[0]}
                              onChangeHandler={(e) => {
                                props.setFieldValue(
                                  ORDER_CONSTANTS.END_DATE,
                                  e.target.value
                                );
                                update_page(
                                  1,
                                  selectedStore,
                                  props.values.status[0].value,
                                  props.values.payment_type[0].value,
                                  query,
                                  props.values.delivery_type[0].value,
                                  props.values.start_date,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          <div className={`col`}>
                            <MultiSelect
                              name={ORDER_CONSTANTS.STATUS}
                              label={ORDER_CONSTANTS.STATUS_LABEL}
                              options={
                                logged_in_user?.role?.id ===
                                  POS_CONSTANTS.STORE_MANAGER_ID ||
                                logged_in_user?.role?.id ===
                                  POS_CONSTANTS.SUPER_USER_ID
                                  ? ORDER_CONSTANTS.ORDER_STATUS_OPTIONS.concat(
                                      [
                                        {
                                          value: ORDER_CONSTANTS.CANCELLED,
                                          label:
                                            ORDER_CONSTANTS.CANCELLED_LABEL,
                                        },
                                      ]
                                    )
                                  : ORDER_CONSTANTS.ORDER_STATUS_OPTIONS
                              }
                              onChangeHandler={(e, actions) => {
                                if (
                                  actions.action === GENERAL_CONSTANTS.CLEAR
                                ) {
                                  props.setFieldValue(ORDER_CONSTANTS.STATUS, [
                                    {
                                      value: ORDER_CONSTANTS.ALL,
                                      label: ORDER_CONSTANTS.ALL_LABEL,
                                    },
                                  ]);
                                } else {
                                  props.setFieldValue(ORDER_CONSTANTS.STATUS, [
                                    e,
                                  ]);
                                  dispatch(updatePageNo(1));
                                  e &&
                                    e.value &&
                                    update_page(
                                      1,
                                      selectedStore,
                                      e.value,
                                      props.values.payment_type[0].value,
                                      query,
                                      props.values.delivery_type[0].value,
                                      props.values.start_date,
                                      props.values.end_date
                                    );
                                }
                              }}
                            />
                          </div>
                          <div className={`col`}>
                            <MultiSelect
                              name={ORDER_CONSTANTS.PAYMENT_TYPE}
                              label={ORDER_CONSTANTS.PAYMENT_TYPE_LABEL}
                              options={getPaymentTypeOptions}
                              onChangeHandler={(e, actions) => {
                                if (
                                  actions.action === GENERAL_CONSTANTS.CLEAR
                                ) {
                                  props.setFieldValue(
                                    ORDER_CONSTANTS.PAYMENT_TYPE,
                                    [
                                      {
                                        value: 0,
                                        label: ORDER_CONSTANTS.ALL_LABEL,
                                      },
                                    ]
                                  );
                                } else {
                                  props.setFieldValue(
                                    ORDER_CONSTANTS.PAYMENT_TYPE,
                                    [e]
                                  );
                                  dispatch(updatePageNo(1));
                                  e &&
                                    update_page(
                                      1,
                                      selectedStore,
                                      props.values.status[0].value,
                                      e.value,
                                      query,
                                      props.values.delivery_type[0].value,
                                      props.values.start_date,
                                      props.values.end_date
                                    );
                                }
                              }}
                            />
                          </div>
                          {props?.values?.payment_type?.[0]?.value === 5 ? (
                            <div className={`col`}>
                              <MultiSelect
                                name={ORDER_CONSTANTS.DELIVERY_TYPE}
                                label={ORDER_CONSTANTS.DELIVERY_TYPE_LABEL}
                                options={ORDER_CONSTANTS.ORDER_DELIVERY_OPTIONS}
                                onChangeHandler={(e, actions) => {
                                  if (
                                    actions.action === GENERAL_CONSTANTS.CLEAR
                                  ) {
                                    props.setFieldValue(
                                      ORDER_CONSTANTS.DELIVERY_TYPE,
                                      [
                                        {
                                          value: ORDER_CONSTANTS.ALL,
                                          label: ORDER_CONSTANTS.ALL_LABEL,
                                        },
                                      ]
                                    );
                                  } else {
                                    props.setFieldValue(
                                      ORDER_CONSTANTS.DELIVERY_TYPE,
                                      [e]
                                    );
                                    dispatch(updatePageNo(1));
                                    e &&
                                      e.value &&
                                      update_page(
                                        1,
                                        selectedStore,
                                        props.values.status[0].value,
                                        props.values.payment_type[0].value,
                                        query,
                                        e.value,
                                        props.values.start_date,
                                        props.values.end_date
                                      );
                                  }
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                        <div className="d-flex flex-row">
                          <div
                            className={`col-12 mt-${modal ? "4 pt-2" : "1"}`}
                          >
                            {modal ? (
                              <Search
                                placeholder="Search ..."
                                setQuery={setQuery}
                              />
                            ) : (
                              <div className="d-flex flex-row">
                                <Search
                                  placeholder="Search by Invoice Code / Order Amount / Customer Name"
                                  setQuery={setQuery}
                                />
                                {stores?.length > 1 && (
                                  <div className="d-inline mt-1">
                                    <Dropdown items={dropdownItems} />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </CardHeader>
              <CardBody>
                <Table>
                  <TableHeader cols={ORDER_CONSTANTS.TABLE_HEADER} />
                  <TableBody>
                    {orderStatus === STATUSES.LOADING ? (
                      <tr>
                        <td
                          colSpan={ORDER_CONSTANTS.TABLE_HEADER.length}
                          className="text-center"
                        >
                          <Loader />
                        </td>
                      </tr>
                    ) : orderData.length > 0 ? (
                      orderData.map((order: any, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {page_size * (current_page - 1) + index + 1}
                            </td>
                            <td>{order?.invoice_code}</td>
                            <td>{order?.customer_name}</td>
                            <td>
                              {POS_CONSTANTS.RUPEE_SIGN}
                              {displayValue(to2Decimal(order?.payable_amount))}
                            </td>
                            <td>
                              <h6>
                                <Badge
                                  badgeType={
                                    order?.status ===
                                    GENERAL_CONSTANTS.COMPLETED
                                      ? GENERAL_CONSTANTS.SUCCESS
                                      : order?.status ===
                                        GENERAL_CONSTANTS.PENDING
                                      ? GENERAL_CONSTANTS.WARNING
                                      : GENERAL_CONSTANTS.DANGER
                                  }
                                  badgeText={order?.status?.toLocaleUpperCase()}
                                />
                              </h6>
                            </td>
                            <td>
                              {formatDateTime(order?.created_at?.toString())}
                            </td>
                            <td>
                              <Button
                                text={<FontIcon icon={faEye} />}
                                isDisabled={!ordersPermission?.perm_edit}
                                type={BUTTON_CONSTANTS.BUTTON}
                                btnClassNames={"btn btn-success btn-sm mr-2"}
                                onClickHandler={() => {
                                  localStorage.setItem(
                                    ORDER_CONSTANTS.ORDER_FILTER,
                                    JSON.stringify(PropsRef.current?.values)
                                  );
                                  navigate(`/admin/sales/orders/${order.id}`);
                                  // dispatch(
                                  //   FetchCurrentOrderDetails(Number(order?.id))
                                  // );
                                  // dispatch(setCurrentOrder(order));
                                }}
                              />
                              <Button
                                text={
                                  commonStatus.type ===
                                    POS_CART_CONSTANTS.PRINT_LAST_BILL_LOADING_STATE &&
                                  print === index ? (
                                    <FontIcon
                                      icon={faCircleNotch}
                                      iconClassNames={
                                        "fa-solid fa-circle-notch fa-spin"
                                      }
                                    />
                                  ) : (
                                    <FontIcon icon={faPrint} />
                                  )
                                }
                                type={BUTTON_CONSTANTS.BUTTON}
                                isDisabled={
                                  commonStatus.type ===
                                  POS_CART_CONSTANTS.PRINT_LAST_BILL_LOADING_STATE
                                }
                                btnClassNames="btn btn-info btn-sm ml-2"
                                onClickHandler={() => {
                                  setPrint(index);
                                  dispatch(
                                    PrintLastBill({ bill_id: Number(order.id) })
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoData len={ORDER_CONSTANTS.TABLE_HEADER.length} />
                    )}
                  </TableBody>
                </Table>
              </CardBody>
              <CardFooter>
                <Pagination
                  loading={orderStatus === STATUSES.LOADING}
                  pageSize={page_size}
                  totalItems={total_items}
                  totalPages={total_pages}
                  currentPage={current_page}
                  prevClickHandler={prevClickHandler}
                  nextClickHandler={nextClickHandler}
                  pageChangeHandler={pageChangeHandler}
                />
              </CardFooter>
            </Card>
          </Container>
        </div>
      </div>
    </div>
  );
};

export { OrdersList };
