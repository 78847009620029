import React from "react";

import { Search } from "./Search";
import { Breadcrumb } from "../core-components/Breadcrumb";

interface ContentHeaderProps {
  pageHeader: any;
  noBread?: boolean;
  headerClassName?: string;
  editValue?: any;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({
  pageHeader,
  noBread,
  headerClassName,
  editValue,
}) => {
  return (
    <section className="content-header">
      <div className="container-fluid">
        <div className="row">
          <div className={`col-sm-4 align-self-end ${headerClassName}`}>
            <h1 className="m-0 ">{pageHeader}</h1>
          </div>
          {!noBread ? (
            <div className="col-sm-8">
              <Breadcrumb editValue={editValue} />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export { ContentHeader };
