import {
  Card,
  CardBody,
  CardHeader,
  Loader,
  Table,
  TableBody,
} from "components";
import { FormikProps } from "formik";
import React from "react";
import { STATUSES, useAppSelector } from "utils";

const ERPProductDetails = ({ props }: { props: FormikProps<any> }) => {
  const {
    root: {
      common: { status: commonStatus },
    },
  } = useAppSelector((state) => state);

  return (
    <Card>
      <CardHeader>
        <div className="d-flex justify-content-between">
          <h4 className="card-title">General Details</h4>
        </div>
      </CardHeader>
      <CardBody>
        {commonStatus.state === STATUSES.LOADING &&
        commonStatus.type === "addProduct" ? (
          <div className="row">
            <div className="col-12 text-center">
              <Loader />
            </div>
          </div>
        ) : (
          props.values?.product_code.length > 0 && (
            <Table tableClassNames="table-borderless">
              <TableBody>
                <tr>
                  <th>Product Code :</th>
                  <td>{`${props.values?.product_code}`}</td>
                </tr>
                <tr>
                  <th>Shelf Life :</th>
                  <td>{`${props.values?.expiration_days} days`}</td>
                </tr>
                <tr>
                  <th>Net Weight :</th>
                  <td>{`${Number(props.values?.net_weight)}${
                    props.values?.uom?.[0]?.code
                  }`}</td>
                </tr>
                <tr>
                  <th>Category :</th>
                  <td>{`${props.values?.category?.[0]?.label}`}</td>
                </tr>
                <tr>
                  <th>Sub-Category :</th>
                  <td>{`${props.values?.sub_category?.[0]?.label}`}</td>
                </tr>
                <tr>
                  <th>Tax Rate :</th>
                  <td>{props.values?.tax.map((tax) => `${tax?.rate}%`)}</td>
                </tr>
                <tr>
                  <th>HSN Code :</th>
                  <td>{`${props.values?.hsn_code?.[0]?.label}`}</td>
                </tr>
              </TableBody>
            </Table>
          )
        )}
      </CardBody>
    </Card>
  );
};

export { ERPProductDetails };
