import React from "react";

import { CustomCheckbox } from "components/group-components/CustomCheckbox";
import { FORM_CONSTANTS, ROLE_CONSTANTS } from "utils";

const RoleHeader = ({ checkBulkColumn, props }) => {
  return (
    <thead>
      <tr>
        <th className="align-middle">#</th>
        <th className="align-middle">Module Name</th>
        <th className="align-middle">
          <CustomCheckbox
            label={ROLE_CONSTANTS.VIEW_LABEL}
            type={FORM_CONSTANTS.CHECKBOX}
            name={ROLE_CONSTANTS.BULK_VIEW}
            // checked={false}
            onClickHandler={(e) => {
              checkBulkColumn(props, ROLE_CONSTANTS.VIEW, e);
            }}
          />
        </th>
        <th className="align-middle">
          <CustomCheckbox
            label={ROLE_CONSTANTS.ADD_LABEL}
            type={FORM_CONSTANTS.CHECKBOX}
            name={ROLE_CONSTANTS.BULK_ADD}
            // checked={false}
            onClickHandler={(e) => {
              checkBulkColumn(props, ROLE_CONSTANTS.ADD, e);
            }}
          />
        </th>
        <th className="align-middle">
          <CustomCheckbox
            label={ROLE_CONSTANTS.EDIT_LABEL}
            type={FORM_CONSTANTS.CHECKBOX}
            name={ROLE_CONSTANTS.BULK_EDIT}
            // checked={false}
            onClickHandler={(e) => {
              checkBulkColumn(props, ROLE_CONSTANTS.EDIT, e);
            }}
          />
        </th>
        <th className="align-middle">
          <CustomCheckbox
            label={ROLE_CONSTANTS.DELETE_LABEL}
            type={FORM_CONSTANTS.CHECKBOX}
            name={ROLE_CONSTANTS.BULK_DELETE}
            // checked={false}
            onClickHandler={(e) => {
              checkBulkColumn(props, ROLE_CONSTANTS.DELETE, e);
            }}
          />
        </th>
        <th className="align-middle">
          <CustomCheckbox
            label={ROLE_CONSTANTS.AUTHORIZE_LABEL}
            type={FORM_CONSTANTS.CHECKBOX}
            name={ROLE_CONSTANTS.BULK_AUTHORIZE}
            // checked={false}
            onClickHandler={(e) => {
              checkBulkColumn(props, ROLE_CONSTANTS.AUTHORIZE, e);
            }}
          />
        </th>
      </tr>
    </thead>
  );
};

export { RoleHeader };
