import React from "react";
import { Accordion, MultiSelect } from "../core-components";
import { CustomInput } from "./CustomInput";
import { ErrorMessage } from "formik";
import {
  CUSTOMERS_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  STATUSES,
  useAppDispatch,
  useAppSelector,
} from "utils";
import {
  fetchCity,
  fetchState,
  getCity,
  getCountry,
  getState,
} from "store/locationSlice";

interface IProps {
  props: any;
  title: string;
  addressFilled: boolean;
  setAddressFilled: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddressAccordion = ({
  props,
  title,
  addressFilled,
  setAddressFilled,
}: IProps) => {
  const dispatch = useAppDispatch();
  const {
    location: { cities, states, countries, status: locationStatus },
  } = useAppSelector((state) => state.root);

  return (
    <Accordion
      accordionItems={[
        {
          id: 1,
          title: title,
          body: (
            <div className="row">
              <div className="col-12">
                <CustomInput
                  label={CUSTOMERS_CONSTANTS.ADDRESS_LINE_1_LABEL}
                  name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.ADDRESS_LINE_1}`}
                  type={FORM_CONSTANTS.TEXT}
                  placeholder={CUSTOMERS_CONSTANTS.ADDRESS_LINE_1_PLACEHOLDER}
                  value={props.values.address?.address_line_1}
                  onChangeHandler={(e) => {
                    props.setFieldValue(
                      `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.ADDRESS_LINE_1}`,
                      e.target.value
                    );
                    setAddressFilled(
                      props.values.address?.address_line_1 !==
                        GENERAL_CONSTANTS.EMPTY_STRING &&
                        props.values.address?.address_line_2 !==
                          GENERAL_CONSTANTS.EMPTY_STRING
                    );
                  }}
                />
                <ErrorMessage
                  name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.ADDRESS_LINE_1}`}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-12">
                <CustomInput
                  label={CUSTOMERS_CONSTANTS.ADDRESS_LINE_2_LABEL}
                  name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.ADDRESS_LINE_2}`}
                  type={FORM_CONSTANTS.TEXT}
                  value={props.values.address?.address_line_2}
                  placeholder={CUSTOMERS_CONSTANTS.ADDRESS_LINE_2_PLACEHOLDER}
                  onChangeHandler={(e) => {
                    props.setFieldValue(
                      `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.ADDRESS_LINE_2}`,
                      e.target.value
                    );
                    setAddressFilled(
                      props.values.address?.address_line_1 !==
                        GENERAL_CONSTANTS.EMPTY_STRING ||
                        props.values.address?.address_line_2 !==
                          GENERAL_CONSTANTS.EMPTY_STRING
                    );
                  }}
                />
                <ErrorMessage
                  name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.ADDRESS_LINE_2}`}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-md-6">
                <MultiSelect
                  label={CUSTOMERS_CONSTANTS.COUNTRY_LABEL}
                  // isRequired={GENERAL_CONSTANTS.TRUE}
                  name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.COUNTRY}`}
                  value={props.values.address?.country}
                  // disabled={!addressFilled}
                  isLoading={locationStatus === STATUSES.LOADING}
                  onFocusHandler={() => {
                    countries.length === 0 && dispatch(getCountry());
                  }}
                  options={countries?.map((country) => ({
                    value: country.id,
                    label: country.country,
                  }))}
                  onChangeHandler={(e) => {
                    e ? dispatch(getState(e.value)) : dispatch(fetchState([]));
                    props.setFieldValue(
                      `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.COUNTRY}`,
                      [e]
                    );
                  }}
                />
                <ErrorMessage
                  name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.COUNTRY}`}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-md-6">
                <MultiSelect
                  label={CUSTOMERS_CONSTANTS.STATE_LABEL}
                  // isRequired={GENERAL_CONSTANTS.TRUE}
                  name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.STATE}`}
                  placeholder={CUSTOMERS_CONSTANTS.STATE_PLACEHOLDER}
                  value={props.values.address?.state}
                  // disabled={!addressFilled}
                  isLoading={locationStatus === STATUSES.LOADING}
                  options={states.map((state) => ({
                    value: state.id,
                    label: state.state,
                  }))}
                  onChangeHandler={(e) => {
                    e ? dispatch(getCity(e.value)) : dispatch(fetchCity([]));
                    props.setFieldValue(
                      `${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.STATE}`,
                      [e]
                    );
                  }}
                />
                <ErrorMessage
                  name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.STATE}`}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-md-6">
                <MultiSelect
                  label={CUSTOMERS_CONSTANTS.CITY_LABEL}
                  // isRequired={GENERAL_CONSTANTS.TRUE}
                  name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.CITY}`}
                  placeholder={CUSTOMERS_CONSTANTS.CITY_PLACEHOLDER}
                  value={props.values.address?.city}
                  // disabled={!addressFilled}
                  isLoading={locationStatus === STATUSES.LOADING}
                  options={cities.map((city) => ({
                    value: city.id,
                    label: city.city,
                  }))}
                />
                <ErrorMessage
                  name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.CITY}`}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-md-6">
                <CustomInput
                  label={CUSTOMERS_CONSTANTS.PINCODE_LABEL}
                  value={props.values.address?.pincode}
                  // isRequired={GENERAL_CONSTANTS.TRUE}
                  // isDisabled={!addressFilled}
                  name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.PINCODE}`}
                  type={FORM_CONSTANTS.NUMBER}
                  placeholder={CUSTOMERS_CONSTANTS.PINCODE_PLACEHOLDER}
                />
                <ErrorMessage
                  name={`${CUSTOMERS_CONSTANTS.CUSTOMER_ADDRESS}.${CUSTOMERS_CONSTANTS.PINCODE}`}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </div>
          ),
          key: 1,
        },
      ]}
      activeItem={1}
    />
  );
};

export { AddressAccordion };
