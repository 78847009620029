import React from "react";
import {
  IconDefinition,
  faAdd,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "../core-components";
import { Search } from "./Search";

interface TableToolsProps {
  path: string;
  label: string;
  permission?: any;
  setQuery?: React.Dispatch<React.SetStateAction<string>> | any;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
}
type DropdownItem = {
  path: string;
  label: string;

  dropdownClickHandler?: any;
  icon: IconDefinition;
};

const TableTools = ({
  path,
  label,
  setQuery,
  permission = true,
  setShow,
  setEdit,
}: TableToolsProps) => {
  const dropdownItems: DropdownItem[] = permission
    ? [
        { path: `/admin/${path}/add`, icon: faAdd, label: `Add ${label}` },
        // {
        //   path: "#",
        //   icon: faFileExport,
        //   label: "Export",
        // },
      ]
    : [
        // {
        //   path: "#",
        //   icon: faFileExport,
        //   label: "Export",
        // },
      ];

  const singleDropdownItems: DropdownItem[] = permission
    ? [
        {
          path: "",
          icon: faAdd,
          label: `Add ${label}`,
          dropdownClickHandler: () => {
            setShow(true);
            setEdit(false);
          },
        },
        // {
        //   path: "#",
        //   icon: faFileExport,
        //   label: "Export",
        // },
      ]
    : [
        // {
        //   path: "#",
        //   icon: faFileExport,
        //   label: "Export",
        // },
      ];

  return (
    <div className="row">
      <div className="d-flex flex-row col-md-6 offset-md-6 px-0">
        <Search placeholder="Search ..." setQuery={setQuery} />
        {permission ? (
          !setShow ? (
            <Dropdown items={dropdownItems} />
          ) : (
            <Dropdown items={singleDropdownItems} />
          )
        ) : null}
      </div>
    </div>
  );
};

export { TableTools };
