import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  delImageFromS3,
  generateMasterPasswordOTP,
  uploadImageToS3,
  verifyMasterPasswordOTP,
} from "apis/restApis";
import { FormikProps } from "formik";
import {
  createPreviewImg,
  jsonDataToFormData,
  makeApiRequest,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATUSES,
  Toast,
  TOAST_CONSTANTS,
  TVerifyMasterPasswordOTP,
} from "utils";

export interface CommonState {
  current_page: number;
  total_pages: number;
  total_items: number;
  page_size: number;
  portrait: boolean;
  error: any;
  status: { state: string; type: string };
  navigate: any;
  controller: any;
  screenSize: any;
  image_setup: {
    url: string;
    fields: any;
  };
  master_password_verified: boolean;
}

const initialState: CommonState = {
  current_page: 1,
  total_pages: 1,
  total_items: 10,
  page_size: 10,
  portrait: false,
  error: "",
  status: { state: "idle", type: "" },
  navigate: null,
  controller: null,
  screenSize: null,
  image_setup: {
    url: "",
    fields: {},
  },
  master_password_verified: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    updatePageNo: (state, action) => {
      state.current_page = action.payload;
    },
    setTotalItems: (state, action) => {
      state.total_items = action.payload;
    },
    setTotalPage: (state, action) => {
      state.total_pages = action.payload;
    },
    setPageSize: (state, action) => {
      state.page_size = action.payload;
    },
    setcommonError: (state, action) => {
      state.error = action.payload;
    },
    setScreenOrientation: (state, action) => {
      state.portrait = action.payload;
    },

    resetMasterPasswordVerifed: (state) => {
      state.master_password_verified = false;
    },

    setScreenSize: (state, action) => {
      state.screenSize = action.payload;
    },

    setControllerState: (state, action) => {
      state.controller = action.payload;
    },

    setCommonStatus: (
      state,
      action: {
        payload: { state: string; type: string };
        type: string;
      }
    ) => {
      state.status = action.payload;
    },

    setNavigation: (state, action) => {
      state.navigate = action.payload;
    },

    setImageSetup: (state, action) => {
      state.image_setup = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(verifyOtpForMasterPassword.fulfilled, (state, action) => {
      state.master_password_verified = true;
    });
    builder.addMatcher(
      isAnyOf(
        generateOtpForMasterPassword.pending,
        verifyOtpForMasterPassword.pending
      ),
      (state, action) => {
        state.status = {
          state: STATUSES.LOADING,
          type: action.meta.arg.type,
        };
      }
    );
    builder.addMatcher(
      isAnyOf(
        generateOtpForMasterPassword.rejected,
        verifyOtpForMasterPassword.rejected
      ),
      (state, action) => {
        state.status = {
          state: STATUSES.ERROR,
          type: action.meta.arg.type,
        };
      }
    );
    // builder.addMatcher(
    //   isAnyOf(
    //     generateOtpForMasterPassword.fulfilled,
    //     verifyOtpForMasterPassword.fulfilled
    //   ),
    //   (state, action) => {
    //     state.status = {
    //       state: STATUSES.IDLE,
    //       type: action.meta.arg.type,
    //     };
    //     if (action.meta.arg.onSuccess) {
    //       action.meta.arg.onSuccess();
    //     }
    //   }
    // );
  },
});

export const {
  updatePageNo,
  setTotalItems,
  setTotalPage,
  setPageSize,

  setScreenOrientation,

  resetMasterPasswordVerifed,

  setcommonError,
  setCommonStatus,

  setScreenSize,

  setControllerState,

  setNavigation,

  setImageSetup,
} = commonSlice.actions;

export default commonSlice.reducer;

export const generateOtpForMasterPassword = createAsyncThunk(
  "common/generateOtpForMasterPassword",
  async (
    {
      params,
      onSuccess,
      type,
    }: {
      params: {
        employee_id: any;
        module: number;
        sub_module: number;
        extra_id: number;
      };
      onSuccess?: () => void;
      type?: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response: any = await generateMasterPasswordOTP(params);

      dispatch(updateValues(response.result));

      if (onSuccess) {
        onSuccess();
      }

      return response.result.results;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const verifyOtpForMasterPassword = createAsyncThunk(
  "common/verifyOtpForMasterPassword",
  async (
    {
      // verifyParams,
      otp,
      onSuccess,
      type,
    }: {
      // verifyParams: TVerifyMasterPasswordOTP;
      otp: number;
      onSuccess?: () => void;
      type?: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response: any = await verifyMasterPasswordOTP(otp);

      dispatch(updateValues(response.result));

      if (onSuccess) {
        onSuccess();
      }

      return response.result.results;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export function updateValues(response) {
  return async function updateValuesThunk(dispatch) {
    if (response && response.result) {
      dispatch(setPageSize(10));
      dispatch(setTotalItems(response.result.count));
      dispatch(setTotalPage(Math.ceil(response.result.count / 10)));
    }
  };
}

export function updatePages(pageNo) {
  return async function updatePageThunk(dispatch) {
    dispatch(updatePageNo(pageNo));
  };
}

export function defaultState() {
  return async function defaultStateThunk(dispatch) {
    dispatch(updatePageNo(1));
    dispatch(setTotalItems(0));
    dispatch(setTotalPage(0));
    dispatch(setPageSize(0));
    // dispatch(setTotalPage(1));
    // dispatch(setPageSize(0));
    // dispatch(setTotalItems(0));
  };
}

export function uploadImage({
  params,
  updatePropsValues,
  setUrl,
}: {
  params: {
    folder_name: string;
    file_name: string;
    file?: File;
  };
  updatePropsValues?: (value) => void;
  setUrl?: React.Dispatch<React.SetStateAction<string>>;
}) {
  return async function uploadImageThunk(dispatch, getState) {
    setCommonStatus({
      state: STATUSES.LOADING,
      type: "upload-image",
    });
    setUrl && setUrl("");
    try {
      const response: any = await uploadImageToS3({
        folder_name: params.folder_name,
        file_name: params.file_name,
      });
      dispatch(setImageSetup(response));
      setUrl && setUrl(response.url);
      if (params.file) {
        const formData: any = jsonDataToFormData(
          Object.assign({ ...response.fields }, { file: params.file })
        );

        makeApiRequest(
          response.url,
          REQUEST_METHOD.POST,
          formData,
          null,
          "json",
          REQUEST_CONTENT_TYPE.FORM_DATA
        )
          .then(() => {
            updatePropsValues &&
              updatePropsValues(
                encodeURI(
                  `${response.url}${params.folder_name}/${params.file_name}`
                )
              );
          })
          .catch((error) => {
            updatePropsValues && updatePropsValues("");
          })
          .finally(() => {
            setCommonStatus({
              state: STATUSES.IDLE,
              type: "upload-image",
            });
          });
      }
    } catch (error) {
      setCommonStatus({
        state: STATUSES.ERROR,
        type: "upload-image",
      });
    }
  };
}

export function deleteImage(url: string) {
  return async function deleteImageThunk(dispatch, getState) {
    setCommonStatus({
      state: STATUSES.LOADING,
      type: "delete-image",
    });
    try {
      const response: any = await delImageFromS3({ file_name: url });
      setCommonStatus({
        state: STATUSES.IDLE,
        type: "delete-image",
      });
      Toast("Image deleted successfully", TOAST_CONSTANTS.SUCCESS);
    } catch (error) {
      setCommonStatus({
        state: STATUSES.ERROR,
        type: "delete-image",
      });
    }
  };
}
