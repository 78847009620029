import {
  faAdd,
  faBan,
  faCircleNotch,
  faEye,
  faPenToSquare,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  ContentHeader,
  CustomInput,
  Dropdown,
  FontIcon,
  Loader,
  MultiSelect,
  NoData,
  Pagination,
  Search,
  Status,
  Table,
  TableBody,
  TableHeader,
} from "components";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updatePageNo } from "store/commonSlice";
import {
  fetchEcommerceOrders,
  printEcommerceOrder,
  resetOrderState,
  setOrderToEdit,
} from "store/Ecommerce";
import { setSelectedStore } from "store/ManageBranches/branchSlice";
import {
  ACCESS_CONSTANTS,
  BUTTON_CONSTANTS,
  displayValue,
  ECOMMERCE_ORDER_CONSTANTS,
  FORM_CONSTANTS,
  formatDateTime,
  GENERAL_CONSTANTS,
  ORDER_CONSTANTS,
  PERMISSION_CONSTANTS,
  POS_CONSTANTS,
  renderTheme,
  ROUTES,
  ROUTES_CONSTANTS,
  ScrollToTop,
  STATUSES,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

const Orders = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    root: {
      auth: { permissions: permissionData },
      common: {
        current_page,
        total_items,
        total_pages,
        page_size,
        status: commonStatus,
      },
    },
    ecommerce: {
      order: { orders, status: orderStatus },
    },
  } = useAppSelector((state) => state);

  const PropsRef = useRef<FormikProps<any>>(null);

  const [print, setPrint] = useState<number>(0);

  const [orderQuery, setOrderQuery] = useState("");

  const EcommerceOrdersPermission = permissionData.find(
    (p) => p.module_name === "Manage Ecommerce Orders"
  );

  const searchFilterInitialValues = {
    start_date: moment().startOf("month").format("YYYY-MM-DD"),
    //  moment().subtract(1, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    order_status: ECOMMERCE_ORDER_CONSTANTS.ORDER_STATUS_OPTIONS[0],
    payment_status: ECOMMERCE_ORDER_CONSTANTS.PAYMENT_STATUS_OPTIONS[0],
    international: ECOMMERCE_ORDER_CONSTANTS.INTERNATIONAL_DOMESTIC_OPTIONS[0],
  };

  const update_page = useCallback(
    (
      pageNo: number,
      query: string = orderQuery,
      start_date: string = PropsRef.current?.values?.start_date,
      end_date: string = PropsRef.current?.values?.end_date,
      order_status: string = PropsRef.current?.values?.order_status?.[0]?.value,
      payment_status: string = PropsRef.current?.values?.payment_status?.[0]
        ?.value,
      international: string = PropsRef.current?.values?.international?.[0]
        ?.value
    ) => {
      const filterOptions = {
        active: false,
        pageNo,
        query,
        edit: {},
      };

      if (start_date) {
        filterOptions.edit[ORDER_CONSTANTS.START_DATE] = start_date;
      }

      if (end_date) {
        filterOptions.edit[ORDER_CONSTANTS.END_DATE] = end_date;
      }

      if (order_status && order_status !== GENERAL_CONSTANTS.ALL) {
        filterOptions.edit["order_status"] = order_status;
      }

      if (payment_status && payment_status !== GENERAL_CONSTANTS.ALL) {
        filterOptions.edit["payment_status"] = payment_status;
      }

      if (international && international !== GENERAL_CONSTANTS.ALL) {
        filterOptions.edit["international"] =
          international === "international" ? 1 : 0;
      }

      dispatch(fetchEcommerceOrders(filterOptions));
    },
    [PropsRef.current?.values]
  );

  const renderStatus = (status) => {
    return (
      <Badge
        badgeSize="md mt-1"
        badgeType={renderTheme(status)}
        badgeText={status?.toLocaleUpperCase()}
      />
    );
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query: orderQuery.trim(),
  });

  useEffect(() => {
    ScrollToTop();
    return () => {
      resetState();
      dispatch(setOrderToEdit(null));
      dispatch(resetOrderState());
    };
  }, [dispatch]);

  // const dropdownItems = [
  //   {
  //     path: `#`,
  //     icon: faAdd,
  //     label: ECOMMERCE_ORDER_CONSTANTS.ADD_HEADER,
  //     dropdownClickHandler: () => {
  //       dispatch(setSelectedStore(ACCESS_CONSTANTS.ECOMMERCE_STORE));
  //       navigate(ROUTES.SALES + "/" + ROUTES.POS + "/");
  //       // navigate("/admin/ecommerce/orders/add/");
  //     },
  //     reverse: false,
  //   },
  // ];

  return (
    <div className="content-wrapper">
      <div className="col-12">
        <div className="row">
          <Container>
            <ContentHeader pageHeader={ECOMMERCE_ORDER_CONSTANTS.HEADER} />
            <Card cardClassNames="p-0">
              <CardHeader>
                <Formik
                  initialValues={searchFilterInitialValues}
                  onSubmit={() => {}}
                  enableReinitialize={true}
                >
                  {(props) => {
                    PropsRef.current = props;
                    return (
                      <Form>
                        <div className="row px-0 mb-2">
                          <div className="col">
                            <CustomInput
                              label={ORDER_CONSTANTS.START_DATE_LABEL}
                              name={ORDER_CONSTANTS.START_DATE}
                              type={FORM_CONSTANTS.DATE}
                              max={
                                props?.values?.end_date ||
                                new Date().toISOString().split("T")[0]
                              }
                              onChangeHandler={(e) => {
                                props.setFieldValue(
                                  ORDER_CONSTANTS.START_DATE,
                                  e.target.value
                                );
                                update_page(
                                  1,
                                  orderQuery,
                                  e.target.value,
                                  props.values.end_date,
                                  props.values.order_status?.[0]?.value,
                                  props.values.payment_status?.[0]?.value,
                                  props.values.international?.[0]?.value
                                );
                              }}
                            />
                          </div>
                          <div className="col">
                            <CustomInput
                              label={ORDER_CONSTANTS.END_DATE_LABEL}
                              name={ORDER_CONSTANTS.END_DATE}
                              type={FORM_CONSTANTS.DATE}
                              min={props?.values?.start_date}
                              max={new Date().toISOString().split("T")[0]}
                              onChangeHandler={(e) => {
                                props.setFieldValue(
                                  ORDER_CONSTANTS.END_DATE,
                                  e.target.value
                                );
                                update_page(
                                  1,
                                  orderQuery,
                                  props.values.start_date,
                                  e.target.value,
                                  props.values.order_status?.[0]?.value,
                                  props.values.payment_status?.[0]?.value,
                                  props.values.international?.[0]?.value
                                );
                              }}
                            />
                          </div>
                          <div className="col">
                            <MultiSelect
                              select={false}
                              name="order_status"
                              label="Order Status"
                              options={
                                ECOMMERCE_ORDER_CONSTANTS.ORDER_STATUS_OPTIONS
                              }
                              onChangeHandler={(e, actions) => {
                                if (
                                  actions.action === GENERAL_CONSTANTS.CLEAR
                                ) {
                                  props.setFieldValue("order_status", [
                                    {
                                      value: ORDER_CONSTANTS.ALL,
                                      label: ORDER_CONSTANTS.ALL_LABEL,
                                    },
                                  ]);
                                } else {
                                  props.setFieldValue("order_status", [e]);
                                  dispatch(updatePageNo(1));
                                  e &&
                                    e.value &&
                                    update_page(
                                      1,
                                      orderQuery,
                                      props.values.start_date,
                                      props.values.end_date,
                                      e.value,
                                      props.values.payment_status?.[0]?.value,
                                      props.values.international?.[0]?.value
                                    );
                                }
                              }}
                            />
                          </div>
                          <div className="col">
                            <MultiSelect
                              select={false}
                              name="payment_status"
                              label="Payment Status"
                              options={
                                ECOMMERCE_ORDER_CONSTANTS.PAYMENT_STATUS_OPTIONS
                              }
                              onChangeHandler={(e, actions) => {
                                if (
                                  actions.action === GENERAL_CONSTANTS.CLEAR
                                ) {
                                  props.setFieldValue("payment_status", [
                                    {
                                      value: ORDER_CONSTANTS.ALL,
                                      label: ORDER_CONSTANTS.ALL_LABEL,
                                    },
                                  ]);
                                } else {
                                  props.setFieldValue("payment_status", [e]);
                                  dispatch(updatePageNo(1));
                                  e &&
                                    e.value &&
                                    update_page(
                                      1,
                                      orderQuery,
                                      props.values.start_date,
                                      props.values.end_date,
                                      props.values.order_status?.[0]?.value,
                                      e.value,
                                      props.values.international?.[0]?.value
                                    );
                                }
                              }}
                            />
                          </div>
                          <div className="col">
                            <MultiSelect
                              select={false}
                              name="international"
                              label="International / Domestic"
                              options={
                                ECOMMERCE_ORDER_CONSTANTS.INTERNATIONAL_DOMESTIC_OPTIONS
                              }
                              onChangeHandler={(e, actions) => {
                                if (
                                  actions.action === GENERAL_CONSTANTS.CLEAR
                                ) {
                                  props.setFieldValue("international", [
                                    {
                                      value: ORDER_CONSTANTS.ALL,
                                      label: ORDER_CONSTANTS.ALL_LABEL,
                                    },
                                  ]);
                                } else {
                                  props.setFieldValue("international", [e]);
                                  dispatch(updatePageNo(1));
                                  e &&
                                    e.value &&
                                    update_page(
                                      1,
                                      orderQuery,
                                      props.values.start_date,
                                      props.values.end_date,
                                      props.values.order_status?.[0]?.value,
                                      props.values.payment_status?.[0]?.value,
                                      e.value
                                    );
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                <div className="d-flex flex-row">
                  <Search placeholder="Search ..." setQuery={setOrderQuery} />
                  {/* <div className="d-inline mt-1">
                    <Dropdown items={dropdownItems} />
                  </div> */}
                </div>
              </CardHeader>
              <CardBody>
                <Table>
                  <TableHeader cols={ECOMMERCE_ORDER_CONSTANTS.COLS} />
                  <TableBody>
                    {orderStatus === STATUSES.LOADING ? (
                      <tr>
                        <td
                          colSpan={ECOMMERCE_ORDER_CONSTANTS.COLS.length}
                          className="text-center"
                        >
                          <Loader />
                        </td>
                      </tr>
                    ) : orders && orders.length > 0 ? (
                      orders.map((order, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {page_size * (current_page - 1) + index + 1}
                            </td>
                            <td>{order?.invoice_code || "N/A"}</td>
                            <td>{order?.customer?.name}</td>
                            <td>
                              {formatDateTime(order?.created_at?.toString())}
                            </td>
                            <td>
                              {POS_CONSTANTS.RUPEE_SIGN}
                              {displayValue(to2Decimal(order?.payable_amount))}
                            </td>
                            <td>
                              <h6>{renderStatus(order?.order_status)}</h6>
                            </td>
                            <td>
                              <h6>{renderStatus(order?.payment_status)}</h6>
                            </td>
                            <td>
                              <Button
                                text={<FontIcon icon={faEye} />}
                                isDisabled={
                                  !EcommerceOrdersPermission?.perm_edit
                                }
                                type={BUTTON_CONSTANTS.BUTTON}
                                btnClassNames={"btn btn-success btn-sm mr-2"}
                                onClickHandler={() => {
                                  navigate(
                                    `/admin/ecommerce/orders/${order.id}`
                                  );
                                }}
                              />
                              <Button
                                text={
                                  commonStatus.state === STATUSES.LOADING &&
                                  commonStatus.type === "print-bill" &&
                                  print === index ? (
                                    <FontIcon
                                      icon={faCircleNotch}
                                      iconClassNames={
                                        "fa-solid fa-circle-notch fa-spin"
                                      }
                                    />
                                  ) : (
                                    <FontIcon icon={faPrint} />
                                  )
                                }
                                type={BUTTON_CONSTANTS.BUTTON}
                                isDisabled={
                                  !order.invoice_code ||
                                  (commonStatus.state === STATUSES.LOADING &&
                                    commonStatus.type === "print-bill")
                                }
                                btnClassNames="btn btn-info btn-sm ml-2"
                                onClickHandler={() => {
                                  setPrint(index);
                                  dispatch(
                                    printEcommerceOrder({
                                      bill_id: Number(order.id),
                                    })
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoData len={ECOMMERCE_ORDER_CONSTANTS.COLS.length} />
                    )}
                  </TableBody>
                </Table>
              </CardBody>
              <CardFooter>
                <Pagination
                  loading={orderStatus === STATUSES.LOADING}
                  pageSize={page_size}
                  totalItems={total_items}
                  totalPages={total_pages}
                  currentPage={current_page}
                  prevClickHandler={prevClickHandler}
                  nextClickHandler={nextClickHandler}
                  pageChangeHandler={pageChangeHandler}
                />
              </CardFooter>
            </Card>
          </Container>
        </div>
      </div>
    </div>
  );
};

export { Orders as Ecommerce_Orders };
