import React, { memo, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  MultiSelect,
} from "../core-components";

import { Form, Formik } from "formik";

interface StoreModalProps {
  modalState: boolean;
  setModalState: (modalState: boolean) => void;
  currentStore: any;
  filledStores: any;
  allStores: any[];
  product: any;
  setFinalValue: any;
}

const StoreModal = ({
  modalState,
  setModalState,
  currentStore,
  allStores,
  filledStores,
  product,
  setFinalValue,
}: StoreModalProps) => {
  const storesToDisplay = [];

  product = {
    ...product,
    new: true,
  };

  const handleSubmit = (values, resetForm) => {
    values.map((option) => {
      let foundStore = filledStores.find(
        (item) => item.store[0].value === option.value
      );

      if (foundStore) {
        filledStores = filledStores.filter(
          (item) => item.store[0].value !== option.value
        );
        filledStores.push({
          ...foundStore,
          products: [...foundStore.products, product],
        });
      } else {
        filledStores.push({
          store: [option],
          products: [product],
        });
      }
    });

    setFinalValue("stores", filledStores);
    setModalState(false);
    storesToDisplay.length = 0;
    resetForm();
  };

  useEffect(() => {
    currentStore?.store
      ? allStores.forEach((store) => {
          if (currentStore.store[0].value !== store.value) {
            storesToDisplay.push(store);
          }
        })
      : null;
    storesToDisplay.map((item, index) => {
      //item {value:id,label:Store Name}
      filledStores.forEach((store) => {
        //store {store:[{value:id,label:Store Name}],products:[productItem1,productItem2]}
        if (item.value === store.store[0].value) {
          store.products &&
            store.products.length > 0 &&
            store.products.map((productItem) => {
              if (
                productItem?.product[0]?.value === product?.product[0]?.value
              ) {
                storesToDisplay.splice(index, 1);
              }
            });
        }
      });
    });
    return () => {
      storesToDisplay.length = 0;
    };
  }, [currentStore, filledStores, allStores, product]);

  return (
    <Modal
      modalClassNames="mt-5"
      showModal={modalState}
      onClickHandler={() => {
        setModalState(false);
      }}
    >
      <div className="row">
        <Formik
          initialValues={{
            stores: [],
          }}
          onSubmit={() => {}}
        >
          {(props) => (
            <Form>
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-md-12">
                      <MultiSelect
                        select={true}
                        label="Select Stores To copy"
                        name="stores"
                        options={storesToDisplay}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <Button
                      btnClassNames="btn btn-primary"
                      text="test"
                      type="button"
                      onClickHandler={() => {
                        handleSubmit(props.values.stores, props.resetForm);
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(StoreModal);
