import React, { useState } from "react";
import Select, { InputActionMeta, NoticeProps, components } from "react-select";
import makeAnimated from "react-select/animated";
import { FontIcon } from "./FontIcon";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { GENERAL_CONSTANTS } from "utils";

interface MultiSelectProps {
  tabIndex?: number;
  toggle?: boolean;
  selectRef?: any;
  select?: boolean;
  disabled?: boolean;
  label?: string;
  isRequired?: boolean;
  designation?: boolean;
  selectClassName?: string;
  options: { value: string; label: string }[] | any[];
  onChangeHandler?: (value: any, actions: any) => void;
  onBlurHandler?: (event: React.FocusEvent) => void;
  onInputChangeHandler?: (inputValue: any) => void;
  onEndReached?: (props: any) => void;
  value?: any;
  inputValue?: string;
  setValue?: (value: any) => void;
  placeholder?: string;
  defaultValue?: any;
  addNewValue?: boolean;
  addNewComponent?: React.ReactNode;
  menuCloseHandler?: () => void;
  autoFocus?: boolean;
  loadingMessage?: any;
  isLoading?: boolean;
  valueToShow?: any;
  name?: string;
  small?: boolean;
  inputAppendTxt?: string;
  onFocusHandler?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDownHandler?: React.KeyboardEventHandler<HTMLDivElement>;
}

const CustomMultiSelect = ({
  tabIndex,
  name,
  toggle,
  selectRef,
  disabled,
  autoFocus = false,
  select,
  label,
  options,
  selectClassName,
  onChangeHandler,
  onBlurHandler,
  onInputChangeHandler,
  designation,
  onEndReached,
  value,
  inputValue: controlledInputValue,
  setValue,
  defaultValue,
  placeholder,
  addNewValue,
  addNewComponent,
  menuCloseHandler,
  loadingMessage,
  isLoading,
  valueToShow,
  small,
  inputAppendTxt = "",
  onFocusHandler,
  onKeyDownHandler,
}: MultiSelectProps) => {
  const [isSearchable, setIsSearchable] = useState(true);
  const [isClearable, setIsClearable] = useState(true);

  const handleChange = (selectedOption: any) => {
    const selectedOptions = Array.isArray(selectedOption)
      ? selectedOption
      : [selectedOption];
    selectedOption !== null && setValue(selectedOptions);
  };

  const selectedOption = options.filter((item) => item.value === valueToShow);

  const handleInputChange = (inputText: string, meta: InputActionMeta) => {
    if (meta.action !== "input-blur" && meta.action !== "menu-close") {
      onInputChangeHandler && onInputChangeHandler(inputText);
    }
  };

  const NoOptionsMessage = (props: NoticeProps) => {
    return addNewValue &&
      (controlledInputValue !== "" || options.length === 0) ? (
      <components.NoOptionsMessage {...props}>
        {addNewComponent}
      </components.NoOptionsMessage>
    ) : null;
  };

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {inputAppendTxt ? (
            <h6 className="text-dark mb-0">{inputAppendTxt}</h6>
          ) : (
            <FontIcon icon={faSearch} />
          )}
        </components.DropdownIndicator>
      )
    );
  };

  const customStyles = {
    control: (base: any) =>
      inputAppendTxt
        ? {
            ...base,
            flexDirection: "row-reverse",
          }
        : base,
  };

  return (
    <div
      className={`${
        selectClassName ? selectClassName : ""
      } input-group d-flex flex-column w-100 ${small ? "text-xs" : ""}`}
    >
      <div className="form-group">
        <label>{label}</label>
        <Select
          tabIndex={tabIndex}
          ref={selectRef}
          styles={customStyles}
          placeholder={placeholder}
          className={`${select ? "basic-multi-select" : "basic-single"} ${
            designation ? "" : ""
          }`}
          defaultValue={valueToShow ? selectedOption : defaultValue}
          onMenuScrollToBottom={onEndReached}
          classNamePrefix="select"
          closeMenuOnSelect={!select}
          components={
            options.length === 0
              ? {
                  Menu: () => null,
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }
              : { NoOptionsMessage }
          }
          isMulti={select}
          options={options}
          menuShouldScrollIntoView={true}
          menuPosition="fixed"
          value={value}
          inputValue={controlledInputValue}
          onBlur={onBlurHandler}
          onChange={onChangeHandler ? onChangeHandler : handleChange}
          onInputChange={handleInputChange}
          isSearchable={isSearchable}
          isClearable={isClearable}
          isDisabled={disabled ? true : false}
          onMenuClose={menuCloseHandler}
          isLoading={isLoading}
          loadingMessage={loadingMessage}
          onFocus={onFocusHandler}
          onKeyDown={onKeyDownHandler}
          id={
            name === "select-store" || name === "product-search"
              ? name
              : undefined
          }
        />
      </div>
    </div>
  );
};

export { CustomMultiSelect };
