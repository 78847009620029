import { CustomInput } from "components/group-components/CustomInput";
import { Button, Modal, MultiSelect } from "components/core-components";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { FilterCreditNotes, setCreditNote } from "store/POS";
import {
  BUTTON_CONSTANTS,
  GENERAL_CONSTANTS,
  POS_CART_CONSTANTS,
  POS_MODAL_CONSTANTS,
  TOAST_CONSTANTS,
  RedeemCreditNoteSchema,
  Toast,
  calculateAllValues,
  debounce,
  displayValue,
  formatDate,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
  POS_CONSTANTS,
} from "utils";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const RedeemCreditNoteModal = ({ modal, setModal }: IProps) => {
  const dispatch = useAppDispatch();

  const {
    branch: { selectedStore },
    cart: { cart, advance },
  } = useAppSelector((state) => state.root);

  const { final_amount_to_pay } = calculateAllValues(cart);

  const [query, setQuery] = useState(GENERAL_CONSTANTS.EMPTY_STRING);

  const handleSubmit = (values) => {
    dispatch(setCreditNote(values));
    setModal(false);
  };

  const setDebouncedQuery = (value) => {
    dispatch(
      FilterCreditNotes({
        store: selectedStore,
        code: value,
        setQuery: setQuery,
      })
    );
  };

  const optimizedSetDebouncedQuery = useCallback(
    debounce(setDebouncedQuery),
    []
  );

  return (
    <Modal
      modalClassNames="w-100"
      title={POS_MODAL_CONSTANTS.REDEEN_CREDIT_NOTE}
      showModal={modal}
      onClickHandler={() => {
        setModal(false);
      }}
    >
      <div className="d-flex flex-row justify-content-between align-items-center text-center px-2 m-1">
        <h6>{`${POS_CART_CONSTANTS.BILL_AMOUNT}: ${
          POS_CONSTANTS.RUPEE_SIGN
        }${Math.round(final_amount_to_pay)}`}</h6>
        {cart?.credit_note?.applyCredit > 0 && (
          <Button
            type={BUTTON_CONSTANTS.BUTTON}
            text={POS_CART_CONSTANTS.CLEAR_CREDIT_NOTE}
            // isDisabled={advance}
            btnClassNames="btn btn-danger btn-sm"
            onClickHandler={() => {
              dispatch(
                setCreditNote({
                  creditNote: [
                    {
                      refund_amount: 0,
                      value: 0,
                      label: GENERAL_CONSTANTS.EMPTY_STRING,
                    },
                  ],
                  applyCredit: 0,
                })
              );
              // if (advance) {
              //   setClearCreditNoteModal(true);
              // }
            }}
          />
        )}
        {/* <ConfirmModal
          modal={clearCreditNoteModal}
          setModal={setClearCreditNoteModal}
          confirmClick={() => {
            // dispatch(ResetWholeCart());
            setClearCreditNoteModal(false);
          }}
          rejectClick={() => {
            setClearCreditNoteModal(false);
          }}
          title="Clear Credit Note"
          message="Are you sure you want to clear the credit note?"
        /> */}
      </div>
      <div className="row">
        <Formik
          enableReinitialize={true}
          initialValues={
            // if credit note is present then set the credit note value else set it to 0
            cart.credit_note.creditNote?.[0]?.refund_amount > 0
              ? {
                  creditNote: cart.credit_note.creditNote,
                  applyCredit: advance
                    ? cart.credit_note.creditNote[0].refund_amount
                    : cart.credit_note.creditNote[0].refund_amount >
                      final_amount_to_pay
                    ? final_amount_to_pay
                    : cart.credit_note.creditNote[0].refund_amount,
                }
              : {
                  creditNote: GENERAL_CONSTANTS.EMPTY_STRING,
                  applyCredit: 0,
                }
          }
          validationSchema={RedeemCreditNoteSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    {/* <h6 className="m-0 p-0">
                      Select Credit Note
                      <span className="text-danger">*</span>
                    </h6> */}
                    <MultiSelect
                      // disabled={advance}
                      select={false}
                      name={POS_CART_CONSTANTS.CREDIT_NOTE}
                      options={GENERAL_CONSTANTS.EMPTY_ARRAY}
                      // inputValue={
                      //   cart.credit_note.creditNote?.[0]?.refund_amount > 0
                      //     ? props.values?.creditNote?.[0]?.label
                      //     : null
                      // }
                      placeholder={POS_CART_CONSTANTS.CREDIT_NOTE_PLACEHOLDER}
                      onKeyDownHandler={(e) => {
                        if (e.key === GENERAL_CONSTANTS.ENTER) {
                          e.stopPropagation();
                          optimizedSetDebouncedQuery(query);
                        }
                      }}
                      onInputChangeHandler={(e) => {
                        setQuery(e);
                      }}
                      // onChangeHandler={(e, actions) => {
                      //   if (actions.action === "clear") {
                      //     dispatch(
                      //       setCreditNote({
                      //         creditNote: [
                      //           { refund_amount: 0, value: 0, label: GENERAL_CONSTANTS.EMPTY_STRING },
                      //         ],
                      //         applyCredit: 0,
                      //       })
                      //     );
                      //   }
                      // }}
                    />
                  </div>
                  {props.values?.creditNote?.[0]?.value > 0 && (
                    <div className="col-12 p-2">
                      <div className="row">
                        <div className="col-6 text-start">
                          <h6 className="text-muted">
                            {POS_CART_CONSTANTS.CREDIT_DATE}
                          </h6>
                        </div>
                        <div className="col-6 text-end">
                          <h6 className="text-muted">{formatDate()}</h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-start">
                          <h6 className="text-muted">
                            {POS_CART_CONSTANTS.CREDIT_AMOUNT}
                          </h6>
                        </div>
                        <div className="col-6 text-end">
                          <h6 className="text-muted">
                            {POS_CONSTANTS.RUPEE_SIGN}
                            {props.values?.creditNote?.[0]?.refund_amount}
                          </h6>
                        </div>
                      </div>
                      <div className="row d-flex flex-row align-items-center">
                        <div className="col-6">
                          <h6 className="text- mt-3">
                            {POS_CART_CONSTANTS.APPLY_CREDIT_LABEL}
                          </h6>
                        </div>
                        <div className="col-6">
                          <CustomInput
                            name={POS_CART_CONSTANTS.APPLY_CREDIT}
                            type="number"
                            step={0.001}
                            isDisabled={advance}
                            max={props.values?.creditNote?.[0]?.refund_amount}
                            placeholder={
                              POS_CART_CONSTANTS.APPLY_CREDIT_PLACEHOLDER
                            }
                            discountType="Flat Discount"
                            withDiscount={false}
                            onChangeHandler={(e) => {
                              // if the value is greater than the refund amount then show error
                              if (
                                e.target.value >
                                props.values?.creditNote?.[0]?.refund_amount
                              ) {
                                Toast(
                                  POS_CART_CONSTANTS.CREDIT_AMOUNT_MESSAGE,
                                  TOAST_CONSTANTS.ERROR
                                );
                              } else if (
                                Number(e.target.value) >
                                Math.round(final_amount_to_pay)
                              ) {
                                // if the value is greater than the total amount then show error
                                Toast(
                                  POS_CART_CONSTANTS.TOTAL_AMOUNT_MESSSAGE,
                                  TOAST_CONSTANTS.ERROR
                                );
                              } else {
                                // set the value in the formik
                                props.setFieldValue(
                                  POS_CART_CONSTANTS.APPLY_CREDIT,
                                  e.target.value
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="row d-flex flex-row align-items-center">
                        <div className="col-6">
                          <h6 className="text- mt-3">
                            {POS_CART_CONSTANTS.CREDIT_BALANCE}
                          </h6>
                        </div>
                        <div className="col-6 text-end">
                          <h6 className="mt-3">
                            {POS_CONSTANTS.RUPEE_SIGN}
                            {displayValue(
                              to2Decimal(
                                props.values.applyCredit
                                  ? props.values.creditNote[0].refund_amount -
                                      props.values.applyCredit
                                  : props.values.creditNote[0].refund_amount
                              )
                            )}
                          </h6>
                        </div>
                      </div>
                      <div className="row d-flex flex-row align-items-center">
                        <div className="col-6">
                          <h6 className="text- mt-3">
                            {POS_CART_CONSTANTS.AMOUNT_TO_PAY}
                          </h6>
                        </div>
                        <div className="col-6 text-end">
                          <h6 className="mt-3">
                            {POS_CONSTANTS.RUPEE_SIGN}
                            {displayValue(
                              to2Decimal(
                                props.values.applyCredit
                                  ? Math.round(final_amount_to_pay) -
                                      props.values.applyCredit
                                  : Math.round(final_amount_to_pay)
                              )
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {props.values?.creditNote?.[0]?.value > 0 && (
                <div className="col-12">
                  <Button
                    text={GENERAL_CONSTANTS.APPLY}
                    type={BUTTON_CONSTANTS.SUBMIT}
                    btnClassNames="btn btn-primary"
                    isDisabled={
                      props.values.applyCredit >
                        Math.round(final_amount_to_pay) &&
                      props.values.applyCredit >
                        props.values?.creditNote?.[0]?.refund_amount
                      // || advance
                    }
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export { RedeemCreditNoteModal };
